import { useState, useRef, useEffect } from "react";
import "./modals.css";
const ModalNote = (props) => {
  const { note, modalRef, type = "regular", onClose, ...data } = props;
  const copyValueRefs = useRef({});
  const [copiedStates, setCopiedStates] = useState({});
  const changeColor = (type) => {
    switch (type) {
      case "regular":
        return "#ffffff";
      case "success":
        return "#59a32f";
      case "error":
        return "#a7434f";
      default:
        return "#ffffff";
    }
  };

  const fallbackCopyText = (text, key) => {
    const tempTextArea = document.createElement("textarea");
    tempTextArea.style.position = "absolute";
    tempTextArea.style.left = "-9999px";
    tempTextArea.value = text;

    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    try {
      if (document.execCommand("copy")) {
        setCopiedStates((prevStates) => ({
          ...prevStates,
          [key]: true,
        }));
        // console.log(copyValueRefs)
        setTimeout(() => {
          setCopiedStates((prevStates) => ({ ...prevStates, [key]: false }));
        }, 2000);
        console.log("Text copied successfully!");
      } else {
        console.error("Copy failed using execCommand.");
      }
    } catch (err) {
      console.error("Copy command failed:", err);
    }
    document.body.removeChild(tempTextArea);
  };

  const handleCopyText = async (inputValue, key) => {
    // console.log('inputValue: ', inputValue, 'inputValue.value: ', inputValue.value)
    const copiedText = inputValue.value;
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(copiedText);
        setCopiedStates((prevStates) => ({
          ...prevStates,
          [key]: true,
        }));
        // console.log(copyValueRefs)
        setTimeout(() => {
          setCopiedStates((prevStates) => ({ ...prevStates, [key]: false }));
        }, 2000);
      } catch (error) {
        return;
        // console.log("failed triyng to copy text: ", error);
      }
    } else {
      fallbackCopyText(copiedText, key);
    }
  };

  useEffect(() => {
    const handleCloseFilterByEscape = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    document.body.addEventListener("keydown", handleCloseFilterByEscape);

    return () => {
      document.body.removeEventListener("keydown", handleCloseFilterByEscape);
    };
  }, [onClose]);

  return (
    <div className="modal-window modal-window_note" ref={modalRef}>
      <div className="modal-window__wrapper modal-window_note__wrapper container-flex--column-centering">
        <button
          className="modal-window__button-close"
          onClick={() => onClose()}
        />
        <p
          className="modal-window_note__note"
          style={{ color: changeColor(type) }}
        >
          {note}
        </p>
        <section className="modal-window_note__section">
          {Object.keys(data).map((key) => (
            <div className="modal-window_note__subsection">
              <label key={key} className="modal-window_note__label">
                <input 
                style={{width: data[key].length >= 20 ? data[key].length - 1 + "ch" : data[key].length + 1 + "ch"}}
                  ref={(el) => (copyValueRefs.current[key] = el)}
                  type="text"
                  readOnly={true}
                  value={data[key]}
                  className="modal-window_note__input"
                />
              </label>
              <div className="modal-window_note__copy-button-wrapper">
              <button
                onClick={() =>
                  handleCopyText(copyValueRefs.current[key], key)
                }
                className="modal-window__copy-button"
              />
                <div
                  className={`modal-window__copy-note ${
                    copiedStates[key] ? "copied" : ""
                  }`}
                  >
                  Скопировано!
                </div>
                </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default ModalNote;
