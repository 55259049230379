import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { CloseBtn } from "@widgets/buttons/index";
import { checkEpmtyValues, checkEmptyValue } from "@features/forms/index";
import { handleEscape } from "@shared";
import { useProjectFields } from "../index";
import { formatToLocalDateTime } from "@features/formatters/index";
import { StatusesList } from "@widgets/statuses-list/ui/StatusesList";
import { updateProject } from "../index";
import { convertToDate } from "@features/formatters/index"
import { DistrictsList } from "@entities/districts/index"

export const EditProjectForm = (props) => {
  const {
    projId,
    projName,
    projDirectionName="СБ",
    projStart,
    projEnd,
    projStatus,
    projDescription,
    projPosition,
    onClose,
    accessToken,
    refreshData,
    setProjectUpdatingStatus
  } = props;

  const { id, name, start, end, description, direction, status, position } =
    useProjectFields(
      projId,
      projName,
      convertToDate(projStart),
      convertToDate(projEnd),
      projDescription,
      projDirectionName,
      projStatus,
      projPosition,
    );

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const rootElement = document.querySelector(".body-wrapper");

  const checkIfEmpty = (value, field) => {
    let checkResult;
    if (typeof value !== "object" && !Array.isArray(value)) {
      checkResult = checkEmptyValue(value);
      // console.log(value, field, checkResult);
      setErrors((prev) => {
        return {
          ...prev,
          [field]: { text: "Поле не может быть пустым.", state: checkResult },
        };
      });
      return;
    }

    checkResult = checkEpmtyValues(value);

    if (Object.keys(value).length > 1) {
      setErrors((prev) => {
        let tmp = { ...prev };

        let newErrorsObj = checkEpmtyValues(Object.keys(tmp));

        for (let result in checkResult) {
          newErrorsObj[result] = checkResult[result];
          if (prev[result] !== result[result]) {
            newErrorsObj[result] = prev[result];
          }
        }
        return newErrorsObj;
      });
    }

    if (checkResult.length === 1 && checkResult.state) {
      setErrors((p) => {
        return { ...p, [field]: checkResult };
      });
    }
  };

  const onChangeFieldValue = (value, setter, field = null) => {
    setter(value);
    if (field !== null && field !== undefined) {
      checkIfEmpty(value, field);
    }
  };

  useEffect(() => {
    handleEscape(onClose);
  });

  const onSubmit = async () => {
    setIsSubmitting(true);
    setTimeout(
      checkIfEmpty({
        name: name.value,
        start: start.value,
        end: end.value,
        direction: direction.value,
      }),
      0,
    );
  };

  useEffect(() => {
    handleEscape(onClose);
  }, [onClose]);

  const sendData = async () => {
    const formattedStartDate = formatToLocalDateTime(start.value);
    const formattedEndDate = formatToLocalDateTime(end.value);
    const result = await updateProject({
      accessToken,
      id: id.value,
      name: name.value,
      start: formattedStartDate,
      end: formattedEndDate,
      description: description.value,
      direction: direction.value,
      status: status.value
    });

    if (result.ok) {
      onClose();
      refreshData();
      setProjectUpdatingStatus({
        message: `Проект ${name.value} успешно отредактирован`,
        hasError: false,
      });
    } else {
      setProjectUpdatingStatus({
        message: `Произошла ошибка с кодом ${
          result.status
        } про попытке отредактировать проект ${
          result.statusText && ` :${result.statusText}`
        }`,
        hasError: true,
      });
    }
    
  }

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length > 0) {
        setIsSubmitting(false);
        return;
      }

    sendData()
    }
  }, [errors]);

  useEffect(() => {
    if (direction.value) {
      checkIfEmpty(direction.value, "direction");
    }
  }, [direction.value]);

  return (
    <>
      {createPortal(
        <div className="edit-project-form modal-container">
          <form
            action="POST"
            className="edit-project-form-form modal-container__wrapper"
          >
            <div className="edit-project-form__header">
              <span className="edit-project-form__header-text">
                Редактирование
              </span>
              <CloseBtn
                blackOrWhite="black"
                style={{ position: "absolute", top: "25%", right: "3%" }}
                onClick={onClose}
              />
            </div>

            <div className="edit-project-form-main">

              <div className="edit-project-form-field edit-project-form-status">
                <label
                  htmlFor="name"
                  className="edit-project-form-field__label"
                >
                  Статус
                </label>
               <StatusesList style={{zIndex: '100'}} status={status.value} setStatus={status.set} />
              </div>
              
              <div className="edit-project-form-field edit-project-form-direction">
                <label
                  htmlFor="direction"
                  className="edit-project-form-field__label"
                >
                  Направление
                </label>
                <DistrictsList accessToken={accessToken} district={direction.value} setDistrict={direction.set} arePlacesRequired={false} />
                {errors.direction && errors.direction.state && (
              <span className="form__error-message">
                {errors.direction.text}
              </span>
            )}
              </div>

              <div className="edit-project-form-field">
                <label
                  htmlFor="name"
                  className="edit-project-form-field__label"
                >
                  Название
                </label>
                <input
                  className="edit-project-form-field__input"
                  id="name"
                  placeholder="Введите название объекта"
                  value={name.value}
                  onChange={(e) =>
                    onChangeFieldValue(e.target.value, name.set, "name")
                  }
                />
                {errors.name && errors.name.state && (
            <span className="form__error-message">{errors.name.text}</span>
          )}
              </div>

              <div className="edit-project-form-field">
                <label className="edit-project-form-field__label">
                  Период выполнения
                </label>
                <div className="new-task-form-row-container new-project-form-field_date-wrapper">
            <input
              className="new-task-form-field__input"
              type="date"
              id="date"
              placeholder="Дата начала"
              value={start.value}
              onChange={(e) =>
                onChangeFieldValue(e.target.value, start.set, "start")
              }
            />
            <input
              className="new-task-form-field__input"
              type="date"
              id="date"
              placeholder="Дата окончания"
              value={end.value}
              onChange={(e) =>
                onChangeFieldValue(e.target.value, end.set, "end")
              }
            />
          </div>
          <div className="new-task-form-row-container">
            <span className="form__error-message">
              {errors.start && errors.start.state && <>{errors.start.text}</>}
            </span>
            <span className="form__error-message">
              {errors.end && errors.end.state && <>{errors.end.text}</>}
            </span>
          </div>
              </div>

              <div className="edit-project-form-field edit-project-form-extra-info">
                <label className="edit-project-form-field__label">
                  Дополнительная информация
                </label>
                <textarea
                  className="edit-project-form-field__textarea edit-project-form-extra-info__textarea"
                  value={description.value}
                  onChange={(e) =>
                    onChangeFieldValue(
                      e.target.value,
                      description.set,
                    )
                  }
                  placeholder="Добавьте описание объекта"
                ></textarea>
              </div>

              <div className="edit-project-form__button-wrapper">
                <button
                  type="submit"
                  className="edit-project-form__create-project-button"
                  onClick={(e)=> {e.preventDefault(); onSubmit()}}
                >
                  Сохранить
                </button>
              </div>
            </div>
          </form>
        </div>,
        rootElement,
      )}
    </>
  );
}