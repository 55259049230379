import apiConfig from '../../../apiConfig.json'

export async function getChapter(id, accessToken) {
  try {
    const response = await fetch(
      `${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_CHAPTER}${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching user info:', error);
    throw error;
  }
}
