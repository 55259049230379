import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import GotoFAQButton from "../../../Components/GotoFAQButton"

export const SwitcherBetweenPages = (props) => {
  const {
    firstPage,
    secondPage,
    currentPage,
    setCurrentPage,
    firstLink,
    secondLink,
    faq = false
  } = props
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const onSwitchCurrent = (page) => {
    // console.log("не бывало ли у вас такое ", page)
    setCurrentPage(page)
  }

  return (
    <div
      style={{ position: "relative" }}
      className={
        isMobile
          ? "switcher-between-pages switcher-between-pages-mobile"
          : "switcher-between-pages"
      }
    >
      {firstLink ? (
        <Link
          className={`switcher-between-pages__link ${
            currentPage === firstPage && "switcher-between-pages__link--current"
          }`}
          to={firstLink}
        >
          <span className="switcher-between-buttons__text">{firstPage}</span>
        </Link>
      ) : (
        <button
          onClick={() => onSwitchCurrent(firstPage)}
          type="button"
          className={`switcher-between-pages__button ${
            currentPage === firstPage &&
            "switcher-between-pages__button--current"
          }`}
        >
          <span className="switcher-between-buttons__text">{firstPage}</span>
        </button>
      )}

      {secondLink ? (
        <Link
          className={`switcher-between-pages__link  ${
            currentPage === secondPage &&
            "switcher-between-pages__link--current"
          }`}
          to={secondLink}
        >
          <span className="switcher-between-buttons__text">{secondPage}</span>
        </Link>
      ) : (
        <button
          onClick={() => onSwitchCurrent(secondPage)}
          type="button"
          className={`switcher-between-pages__button ${
            currentPage === secondPage &&
            "switcher-between-pages__button--current"
          }`}
        >
          <span className="switcher-between-buttons__text">{secondPage}</span>
        </button>
      )}

      {faq === "true" && (
        <>
          {isMobile ? (
            <GotoFAQButton
              category="addition"
              top="-0.2rem"
              right="-3.3rem"
              width="35px"
              height="35px"
              fontSize="18px"
            />
          ) : (
            <GotoFAQButton category="addition" top="0.1rem" right="-5rem" />
          )}
        </>
      )}
    </div>
  )
}
