import "./service.css"
import { useEffect, useCallback, useState } from "react"
// import { Link, useNavigate } from 'react-router-dom';
// import logo from '../../Images/Menu/logo.svg';
import DirectionBlock from "./DirectionBlock"
import DirectionForm from "./DirectionForm"
import { NavigationMenu } from "@features/navigation-menu"
import apiConfig from "../../apiConfig.json"
import ModalDelete from "../../Components/ModalDelete"

const ServicePage = ({ accessToken }) => {
  // const navigate = useNavigate();
  const [isDirectionFormOpen, setIsDirectionFormOpen] = useState(false)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [directions, setDirections] = useState([{}])
  const colors = ["#9B69BA", "#708EC9", "#E5A162", "#F0D164"]
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleButtonClick = () => {
    setIsDirectionFormOpen(!isDirectionFormOpen)
    setIsFormVisible(false)
  }

  const handleTransitionEnd = () => {
    if (isDirectionFormOpen) {
      setIsFormVisible(true)
    }
  }

  const getDirections = useCallback(() => {
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_DIRECTION}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        const dirInfo = []
        data.forEach((dir) => {
          const directionId = dir.id
          const directionName = dir.name
          const areas = []
          dir.places.forEach((area) => {
            areas.push(area.name)
          })
          dirInfo.push({ directionId, directionName, areas })
        })

        setDirections(dirInfo)
      })
      .catch((error) => {
        console.error("Error fetching data: ", error)
      })
  }, [accessToken])

  useEffect(() => {
    getDirections()
  }, [])

  const handleFormSubmit = () => {
    getDirections()
  }

  return (
    <>
      <div className="body-wrapper">
        <div
          className={
            isMobile
              ? "page-flex-wrapper page-flex-wrapper-mobile"
              : "page-flex-wrapper"
          }
        >
          <NavigationMenu currentPage="service" accessToken={accessToken} />
          <main
            className={
              isMobile
                ? "main-index__wrapper-mobile service-main-index"
                : "service-main-index"
            }
          >
            <section className="service-container">
              <section className="directions-container">
                <h1>Направления</h1>
                <div
                  className={`directions-wrapper ${isDirectionFormOpen ? "collapsed" : ""}`}
                >
                  {directions.map((direction, index) => (
                    <DirectionBlock
                      key={index}
                      id={direction.directionId}
                      direction={direction.directionName}
                      areas={direction.areas}
                      color={colors[index % colors.length]}
                      onDeleteClick={handleOpenModal}
                    />
                  ))}
                </div>
              </section>
            </section>
            <button
              className={`button button-add-direction ${isDirectionFormOpen ? "open" : ""}`}
              onClick={handleButtonClick}
              onTransitionEnd={handleTransitionEnd}
            >
              +
            </button>

            {isFormVisible && (
              <>
                <button
                  className="button-close-form"
                  onClick={handleButtonClick}
                ></button>
                <DirectionForm
                  onFormSubmit={handleFormSubmit}
                  accessToken={accessToken}
                  onClose={handleButtonClick}
                />
              </>
            )}
            {isModalOpen && (
              <ModalDelete
                message="Удалить направление?"
                action="Удалить"
                onClose={handleCloseModal}
              />
            )}
          </main>
        </div>
      </div>
    </>
  )
}

export default ServicePage
