import config from "../../../apiConfig.json"

export const deleteProject = async (id) => {
  console.log('не бывало ли у вас такое...')
  const response = await fetch(`${config.BASE_URL}${config.CRM_ENDPOINTS.DELETE_PROJECT}${id}`, {
    method: "DELETE",
    headers: {
      'Authorization': `Bearer ${config.DEFAULT_TOKEN}`,
      'Content-Type': 'application/json',
    }
  })

  return { ok: response.ok, status: response.status, statusText: response.statusText }
}