import { MENU_PAGES } from "../model/menu-pages"
import { COLORS } from "../model/menu-pages"
export const getMenuIconImage = (page, color) => {
  switch (page) {
    case MENU_PAGES.objectsManagement:
      if (color === COLORS.white) {
        return require("../../../shared/assets/menu-icons/objects-management-white-icon.svg")
          .default
      }
      if (color === COLORS.black) {
        return require("../../../shared/assets/menu-icons/objects-management-black-icon.svg")
          .default
      }
      break

    case MENU_PAGES.resourceManagement:
      if (color === COLORS.white) {
        return require("../../../shared/assets/menu-icons/resources-management-white-icon.svg")
          .default
      }
      if (color === COLORS.black) {
        return require("../../../shared/assets/menu-icons/resources-management-black-icon.svg")
          .default
      }
      break

    case MENU_PAGES.downloadAPK:
      if (color === COLORS.white) {
        return require("../../../shared/assets/menu-icons/download-white-icon.svg")
          .default
      }
      if (color === COLORS.black) {
        return require("../../../shared/assets/menu-icons/download-black-icon.svg")
          .default
      }
      break

    case MENU_PAGES.logout:
      if (color === COLORS.white) {
        return require("../../../shared/assets/menu-icons/logout-white-icon.svg")
          .default
      }
      if (color === COLORS.black) {
        return require("../../../shared/assets/menu-icons/logout-black-icon.svg")
          .default
      }
      break

    default:
      break
  }
  // switch (page) {

  //   case MENU_PAGES.objectsManagement:
  //     return require("../../../shared/assets/menu-icons/objects-management-white-icon.svg").default
  //   case MENU_PAGES.resourceManagement:
  //     return require("../../../shared/assets/menu-icons/resources-management-white-icon.svg").default
  //   case MENU_PAGES.downloadAPK:
  //     return require("../../../shared/assets/menu-icons/download-white-icon.svg").default
  //   default:
  //   break
  // }
  // const images = {
  //   objectsManagement: require("../../../shared/assets/menu-icons/objects-management-white-icon.svg").default,
  //   resourceManagement:  require("../../../shared/assets/menu-icons/resources-management-white-icon.svg").default,
  //   downloadAPK: require("../../../shared/assets/menu-icons/download-white-icon.svg").default,
  // }
  // return images[page] || ""
}
