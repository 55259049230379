const checkEmptyValue = (value) => {
  console.log(value)
  const checkingResult = (value === null || value === undefined || !value.length )
  // if (field !== null && field !== undefined) {
  //   console.log({[field]: { text: 'Выберите хотя бы один объект.', state: checkingResult }})
  //   return {[field]: { text: 'Выберите хотя бы один объект.', state: checkingResult }};
  // }
  console.log(value === null || value === undefined || !value.length )
  console.log(checkingResult)
  return checkingResult
}

const checkEpmtyValues = (fields) => {
  let errors = {}
  for (let field in fields) {
    const value = fields[field]
    if ( (typeof value === 'string' && !value.trim()) ) {
      errors[field] = { text: 'Поле не может быть пустым.', state: true };
    }

    if (typeof value === 'object' && value !== null) {
      for (let subField in value) {
        // console.log('value: ', value, 'subfield: ', subField)
        if (!value[subField].trim() || value === null || value === undefined) {
          errors[field] = { text: 'Поле не может быть пустым.', state: true };
        }
      }
    }

    if (Array.isArray(value)) {
      value.forEach(subValue => {
        if (checkEmptyValue(subValue)) {
          errors[field] = { text: 'Выберите хотя бы один объект.', state: true };
        }
      })
    }
  }
  // console.log('errors: ', errors)
  return errors
  // let isEmpty = false;
  // if (!field.trim()) {
  //   isEmpty = true
  // }
  // return isEmpty
  // const {fullName, password, role, equipmentList} = props

  // let error = {}
  //   if (!field.trim()) {
  //     error = { text: 'Поле не может быть пустым.', state: true }
  //   }
  //   return error

  // fields.forEach((field) => {
    //   if (!field.trim()) {
    //     errors[field] = "Поле не может быть пустым."
    //   }
    // })
    // return errors
}

const checkEmptyArray = () => {
  
}

export { checkEpmtyValues, checkEmptyValue }


// if (!fields.username.trim()) {
//   errors.username = 'Имя пользователя не может быть пустым.';
// }
// if (!fields.password.trim()) {
//   errors.password = 'Пароль не может быть пустым.';
// }
// if (!fields.role.trim()) {
//   errors.role = 'Необходимо выбрать роль.';
// }
// if (fields.equipmentList.length === 0) {
//   errors.equipmentList = 'Выберите хотя бы одно оборудование.';
// }
// if (!fields.chosenDirPlace.id) {
//   errors.chosenDirPlace = 'Необходимо выбрать направление или участок.';
// }