import { useState } from "react"
import { createPortal } from "react-dom"
import { DeleteBtn, GotoArrowBtn, EditBtn } from "@widgets/buttons/index"
import { statusesColors } from "@entities/project/index"
import { getImageForTaskType } from "@entities/task/model/get-image-for-task-type"
import { EditProjectForm } from "@entities/project/index"
// import { useProjectFields } from "@entities/project/index"
import { statuses } from "../model/statuses"
import ModalDelete from "../../../Components/ModalDelete"

// import { useDispatch } from "react-redux";
// import { setProject } from "../../../store/slices/projectSlice";
// import { useNavigate } from "react-router-dom";

export const ProjectCard = (props) => {
  const {
    accessToken,
    projId,
    projName,
    projStart,
    projEnd,
    projDescription,
    onClose,
    projPosition = [{ x: 0, y: 0 }],
    projDirection,
    projStatus,
    refreshData,
    handleDeleteProject,
    setProjectUpdatingStatus
  } = props
  // console.log(props)

  const rootElement = document.querySelector(".body-wrapper")
  // const { id, name, start, end, description, district, position } = useProjectFields()
  const [isEditProjectFormOpened, setIsEditProjectFormOpened] = useState(false)
  const [isModalDeleteOpened, setIsModalDeleteOpened] = useState(false)
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const onClick = (gotoLink, projectData) => {
  //   dispatch(setProject(projectData)); // Сохраняем данные проекта в Redux
  //   navigate(gotoLink); // Переходим на другую страницу
  // };

  const onToggleEditProjectForm = () => {
    setIsEditProjectFormOpened((p) => !p)
  }

  function convertToDate(date) {
    if (!date) return ""
    //пример даты до обработки: 2025-01-09 09:51:03
    const onlyDate = date.split(" ", 1)[0].split("-") //исключение времени из date и разделение даты на [год, месяц, день]
    const year = onlyDate[0]
    const month = onlyDate[1]
    const day = onlyDate[2]
    return `${year}-${month}-${day}`
  }

  return (
    <div className="project-card">
      <section className="project-card__upper-section container-flex--space-between">
        <div className="container-flex--space-between">
          <p className="project-card__direction container-flex--centering box-shadow">
            {projDirection}
          </p>
          <span className="project-card__name container-flex--centering box-shadow">
            БАМ-2
          </span>
        </div>

        <div className="project-card__actions">
          {projStatus !== statuses.DONE && (
            <EditBtn
              onClick={onToggleEditProjectForm}
              style={{ marginRight: "0.9rem" }}
            />
          )}
          <DeleteBtn
            style={{ width: "27px", height: "27px" }}
            onClick={() => setIsModalDeleteOpened(true)}
          />
        </div>
      </section>

      <section className="project-card__middle-section">
        <div className="project-card__dates">
          {convertToDate(projStart)} - {convertToDate(projEnd)}
        </div>
        <p className="project-card__route">{projName}</p>
        <div
          style={{ backgroundColor: statusesColors(projStatus) }}
          className="project-card__status"
        >
          <img
            className="project-card__status-icon"
            src={getImageForTaskType(projStatus)}
            alt={projStatus}
            width={20}
            height={20}
          />
          <p className="">{statuses[projStatus]}</p>
        </div>
        <p className="project-card__description">{projDescription}</p>
      </section>

      <section className="project-card__bottom-section">
        <span className="project-card__tasks-statistic">
          Выполнено задач: —
        </span>
        <span className="project-card__completion-date">
          Дата завершения: "13.02.2025"
        </span>
        <GotoArrowBtn
          style={{ position: "absolute", right: "-0.5rem", bottom: "-0.5rem" }}
          rotation="rb"
          gotoLink="/task-tracker"
          state={projId}
          params={{ projId: projId, projName: projName }}
        />
      </section>

      {isEditProjectFormOpened && (
        <EditProjectForm
          accessToken={accessToken}
          projId={projId}
          projName={projName}
          projDirection={projDirection}
          projStart={projStart}
          projEnd={projEnd}
          projStatus={projStatus}
          projDescription={projDescription}
          projPosition={projPosition}
          refreshData={refreshData}
          setProjectUpdatingStatus={setProjectUpdatingStatus}
          onClose={onToggleEditProjectForm}
        />
      )}

      {isModalDeleteOpened && (
        <>
          {createPortal(
            <ModalDelete
              onClose={() => onClose(setIsModalDeleteOpened)}
              action={() => {
                handleDeleteProject(projId, projName)
                onClose(setIsModalDeleteOpened)
              }}
              message={`Вы уверены, что хотите удалить объект ${projName} ?`}
              actionText="Удалить"
            />,
            rootElement
          )}
        </>
      )}
    </div>
  )
}
