import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { NavigationMenu } from "@features/navigation-menu"
import "../Services/loadApkFile.css"

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const ApkDownload = ({ autoDownload }) => {
  const query = useQuery()
  const [downloadStatus, setDownloadStatus] = useState(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    const token = query.get("token")
    setDownloadStatus("downloading")

    fetch("http://mon-str.ru/api/apk/newest", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`
        // 'Content-Type' : 'application/vnd.android.package-archive'
      }
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            `Response status: ${response.status} - ${response.statusText}`
          )
          throw new Error("Network response was not ok")
        }
        return response.blob()
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "mon-str.apk")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setDownloadStatus("downloaded")
      })
      .catch((error) => {
        console.error("Error fetching APK file:", error)
        setDownloadStatus("error")
      })
  }, [])

  return (
    <>
      <div
        className={
          isMobile
            ? "page-flex-wrapper page-flex-wrapper-mobile"
            : "page-flex-wrapper"
        }
      >
        <NavigationMenu />
        <div className="message-wrapper">
          <div className="centered-container">
            {downloadStatus === "downloading" && (
              <p className="download-text download-loading-message">
                Файл скачивается
              </p>
            )}
            {downloadStatus === "downloaded" && (
              <p className="download-text download-successfull-message">
                Файл скачался успешно
              </p>
            )}
            {downloadStatus === "error" && (
              <p className="download-text download-error-message">
                Произошла ошибка при скачивании. Попробуйте еще раз.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ApkDownload
