import apiConfig from "../../../apiConfig.json"

export const getAllProjects = async (accessToken) => {
  try {
    const response = await fetch(
      `${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_ALL_PROJECTS}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiConfig.DEFAULT_TOKEN}`,
          "Content-Type": "application/json"
        }
      }
    )

    const data = await response.json()
    // console.log(data)

    return data.projects
  } catch (error) {
    console.log('error while fetching all projects: ', error)
    return []
  }
}
