import { useEffect, useState } from "react"
import apiConfig from "../apiConfig.json"
import logo from "../Images/login-logo.svg"
import { useDispatch, useSelector } from "react-redux"
import { login } from "../store/slices/authSlice"

const LogInAdmin = ({ onLogin }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loginError, setLoginError] = useState(false)
  const [loginErrorText, setLoginErrorText] = useState("")
  const [usernameError, setUsernameError] = useState("")
  const [passwordError, setPasswordError] = useState("")

  const dispatch = useDispatch()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const clearUsernameError = () => {
    setUsernameError("")
    setLoginError(false)
  }

  const clearPasswordError = () => {
    setPasswordError("")
    setLoginError(false)
  }

  const fetchUserData = async (accessToken) => {
    try {
      const response = await fetch(
        `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_LOGIN_INFO}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
          }
        }
      )

      if (!response.ok) {
        throw new Error("Failed to fetch user data")
      }

      return await response.json()
    } catch (error) {
      console.error("Error fetching user data:", error)
      throw error
    }
  }

  const handleLogin = (event) => {
    event.preventDefault()
    validateUsername()
    validatePassword()

    if (!usernameError && !passwordError) {
      fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.POST_LOGIN}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username,
          password
        })
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            if (response.status === 400) {
              setLoginErrorText("Введён неправильный логин или пароль")
            }
            throw new Error("Network response was not ok")
          }
        })
        .then((data) => {
          const accessToken = data.accessToken
          localStorage.setItem("accessToken", accessToken)
          // dispatch(login({ token: accessToken, user: null }));
          onLogin(accessToken)
          // setLoginError(false);
          // setUsernameError('');
          // setPasswordError('');
          return fetchUserData(accessToken)
        })
        .then((userData) => {
          // console.log(localStorage.getItem('accessToken'))
          dispatch(
            login({
              token: localStorage.getItem("accessToken"),
              user: userData
            })
          )
          // console.log(userData)
          setLoginError(false)
          setUsernameError("")
          setPasswordError("")
        })
        .catch((error) => {
          console.error("Error during login:", error)
          setLoginError(true)
        })
    } else {
      setLoginError(false)
    }
  }

  const handleUsernameChange = (value) => {
    setUsername(value)
    clearUsernameError()
  }

  const handlePasswordChange = (value) => {
    setPassword(value)
    clearPasswordError()
  }

  const validateUsername = () => {
    if (!username.match(/^[A-ZА-Я][a-zа-я]+[A-ZА-Я]{2}$/)) {
      setUsernameError("*Неправильный формат имени")
    } else {
      setUsernameError("")
    }
  }

  const validatePassword = () => {
    if (password.length < 6) {
      setPasswordError("*Пароль должен содержать минимум 6 символов")
    } else {
      setPasswordError("")
    }
  }

  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken")
    if (storedToken) {
      onLogin(storedToken)
    }
  }, [onLogin])

  return (
    <>
      <div className="login-wrapper">
        <div className="form-wrapper">
          <img className="form-wrapper__logo" src={logo} alt="logo" />
          <form
            autoComplete="on"
            onSubmit={(event) => handleLogin(event)}
            className={
              isMobile
                ? "login-form login-form-mobile"
                : " login-form login-form-desktop"
            }
          >
            <input
              type="text"
              autoComplete="username"
              placeholder="Имя"
              value={username}
              onChange={(e) => handleUsernameChange(e.target.value)}
              onTouchStart={(e) => e.target.focus()}
              className={usernameError ? "input-error" : "input-validated"}
            />
            {usernameError && <p className="error-message">{usernameError}</p>}
            <input
              type="password"
              autoComplete="current-password"
              placeholder="Пароль"
              value={password}
              onChange={(e) => handlePasswordChange(e.target.value)}
              onTouchStart={(e) => e.target.focus()}
              className={passwordError ? "input-error" : "input-validated"}
            />
            {passwordError && <p className="error-message">{passwordError}</p>}
            <button type="submit">Войти</button>
            {!usernameError && !passwordError && loginError && (
              <p className="error-visible">{loginErrorText}</p>
            )}
          </form>
        </div>
      </div>
    </>
  )
}
export default LogInAdmin
