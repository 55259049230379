import { Link } from "react-router-dom";
import logo from '../../../shared/assets/full-logo.svg';
import './_start-menu.scss';
import './_start-navigation.scss';
import './_projects-navigation.scss';
import './_tracker-navigation.scss';
import './_documentation-navigation.scss';
import './_management-navigation.scss';
import './_directions-navigation.scss';

const AdminMenu = (props) => {

  const {handleLogout, user} = props;

  return (
    <>
                <main className="start-menu">
                  <div className="start-menu__container">
                    <section className="start-menu__wrapper">
                        {/* <ul className={isMobile ? "admin-menu-list-mobile" : "admin-menu-list"}>
                          <li className="admin-menu-item admin-menu-table">
                            <Link to="/load-data-table" className="linked-to">Таблица рейсов</Link>
                          </li>
                          <li className="admin-menu-item admin-menu-tasks">
                            <Link to="/task-tracker" className="linked-to">Трекер задач</Link>
                          </li>
                          <li className="admin-menu-item admin-menu-service">
                            <Link to="/service" className="linked-to">Сервис</Link>
                          </li>
                        </ul> */}
                        {/* <div className="start-menu__logout">
                          <img src="#" alt="logo"/>
                          <button>logout</button>
                        </div> */}
                        <div className="start-menu__navigation start-navigation">
                          <div className="start-navigation__wrapper">
                            <div>
                              <img src={logo} alt="logo"/>
                            </div>
                            <section className="start-navigation__item start-navigation__item--organization start-navigation__item--delay-0">
                              {/* <img src={logo} alt="logo" width={50} height={50}/>
                              {/* <UserProvider accessToken={accessToken}>
                                <OrganizationInfo/>
                              </UserProvider> */}
                            </section>
                            <section className="start-navigation__item start-navigation__item--user start-navigation__item--delay-0">
                              {/* <UserProvider accessToken={accessToken}>
                                <UserInfo/>
                              </UserProvider> */}
                                  {/* <p>{user.name}</p>
                                  <p>Admin</p> */}
                              <button className="button button--primary" onClick={handleLogout} style={{marginTop: "auto"}}>Выйти</button>
                            </section>
                            <section className="start-navigation__item start-navigation__item--documentation start-navigation__item--delay-3">
                              <h2 className="start-navigation__item-title">Документация</h2>
                              <div className="start-navigation__documentation documentation-navigation">
                                <Link className="start-navigation__link" to="/documentation">
                                  <div className="documentation-navigation__item">ГПР</div>
                                </Link>
                                <Link className="start-navigation__link" to="/documentation-samples">
                                  <div className="documentation-navigation__item">КС-6</div>
                                </Link>
                              </div>
                            </section>
                            <section className="start-navigation__item start-navigation__item--projects start-navigation__item--delay-1">
                              <Link className="start-navigation__link" to="/objects"/>
                              <h2 className="start-navigation__item-title">Объекты</h2>
                              {/* <button>Новый проект</button> */}
                              <div className="start-navigation__projects projects-navigation">
                                <Link className="start-navigation__link" to="/objects-current">
                                  <div className="projects-navigation__item">
                                    <p className="projects-navigation__count">10</p>
                                    <p className="projects-navigation__description">Актуальных</p>
                                  </div>
                                </Link>
                                <Link className="start-navigation__link" to="/objects-finished">
                                  <div className="projects-navigation__item">
                                    <p className="projects-navigation__count">3</p>
                                    <p className="projects-navigation__description">Завершенных</p>
                                  </div>
                                </Link>
                              </div>
                            </section>
                            <section className="start-navigation__item start-navigation__item--tracker start-navigation__item--delay-2">
                              <Link className="start-navigation__link" to="/task-tracker">
                              <h2 className="start-navigation__item-title">Трекер задач</h2>
                              <div className="start-navigation__tracker tracker-navigation">
                                <div className="tracker-navigation__item tracker-navigation__item--planned">
                                  <p className="tracker-navigation__tasks-count"><strong>23</strong> задачи</p>
                                  <p className="tracker-navigation__item-title">Запланированно</p>
                                </div>
                                <div className="tracker-navigation__item tracker-navigation__item--in-process">
                                  <p className="tracker-navigation__tasks-count"><strong>35</strong> задач</p>
                                  <p className="tracker-navigation__item-title">В работе</p>
                                </div>
                                <div className="tracker-navigation__item tracker-navigation__item--review">
                                  <p className="tracker-navigation__tasks-count"><strong>4</strong> задачи</p>
                                  <p className="tracker-navigation__item-title">На проверке</p>
                                </div>
                                <div className="tracker-navigation__item tracker-navigation__item--in-editing">
                                  <p className="tracker-navigation__tasks-count"><strong>11</strong> задач</p>
                                  <p className="tracker-navigation__item-title">На доработке</p>
                                </div>
                                <div className="tracker-navigation__item tracker-navigation__item--done">
                                  <p className="tracker-navigation__tasks-count"><strong>22</strong> задачи</p>
                                  <p className="tracker-navigation__item-title">Выполнено</p>
                                </div>
                              </div>
                              </Link>
                            </section>
                            <section className="start-navigation__item start-navigation__item--shifts start-navigation__item--delay-4">
                              <Link className="start-navigation__link" to="/load-data-table">
                                <h2 className="start-navigation__item-title">Таблица смен</h2>
                                <div className="start-navigation__shift-table">
                                  <span className="visually-hidden">Карта</span>
                                </div>
                              </Link>
                            </section>
                            <section className="start-navigation__item start-navigation__item--management start-navigation__item--delay-5">
                              <h2 className="start-navigation__item-title">Сотрудники и оборудование</h2>
                              <div className="start-navigation__management management-navigation">
                                <Link className="start-navigation__link" to="/addition-user">
                                  <div className="management-navigation__item">
                                      <p className="management-navigation__item-title management-navigation__item-title--user">Сотрудники</p>
                                      <p className="management-navigation__item-count">86</p>
                                      <div>+</div>
                                  </div>
                                </Link>
                                <Link className="start-navigation__link" to="/addition-equip">
                                  <div className="management-navigation__item">
                                      <p className="management-navigation__item-title management-navigation__item-title--equip">Оборудование</p>
                                      <p className="management-navigation__item-count">149</p>
                                      <div>+</div>
                                  </div>
                                </Link>
                              </div>
                            </section>
                            <section className="start-navigation__item start-navigation__item--directions start-navigation__item--delay-6">
                              <h2 className="start-navigation__item-title">Управление
                              направлениями и участками</h2>
                              <div className="start-navigation__directions directions">
                                <Link className="start-navigation__link" to="/service">
                                  <div className="directions-navigation__item">
                                    <p className="directions-navigation__count">8</p>
                                    <p className="directions-navigation__item-title">Направлений</p>
                                  </div>
                                </Link>
                                <Link className="start-navigation__link" to="/service-areas">
                                  <div className="directions-navigation__item">
                                    <p className="directions-navigation__count">22</p>
                                    <p className="directions-navigation__item-title">Участка</p>
                                  </div>
                                </Link>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                  </main>
    </>
  )
};

export default AdminMenu;
