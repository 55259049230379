import { useState, useCallback, useEffect } from "react";
import apiConfig from "../../../apiConfig.json";
import { TaskCard } from "@entities/task/index.js";
import { getAllTasks } from "@entities/task/index";
import { useTaskFields } from "@entities/task/index";

export const TasksContainer = (props) => {
  const { accessToken, tasks, typeOfTasks, projectInfo } = props;
  // const { taskId, district, start, end, performers } = useTaskFields()

  // const [tasks, setTasks] = useState([{}]);

  return (
    <div className="tasks-container">
      {/* <div className="tasks-container__container"> */}

      <div className="tasks-container__header">
        <span className="tasks-container__status">{typeOfTasks}</span>
        <span className="tasks-container__amount">{tasks.length} задач</span>
      </div>
      <div className="task-cards">
        <div className="task-cards__container">
          {tasks.map((task) => {
            return (
              <TaskCard
                description={task.description}
                accessToken={accessToken}
                taskId={task.id}
                district={projectInfo.project.direction_name}
                start={task.start}
                end={task.end}
                performers={task.performers}
              />
            );
          })}
          <TaskCard accessToken={accessToken} />
          <TaskCard accessToken={accessToken} />
          <TaskCard accessToken={accessToken} />
          <TaskCard accessToken={accessToken} />
        </div>
        {/* <ul className="tasks-card__list">
        </ul> */}
      </div>
    </div>
    // </div>
  );
};
