import { useState, useCallback, useEffect, useRef } from "react"
import ModalNote from "../../Components/ModalNote.jsx"
import apiConfig from "../../apiConfig.json"
import ModalDelete from "../../Components/ModalDelete.jsx"
import "../../Components/modals.css"
import { CloseBtn } from "@widgets/buttons/index.js"

const UserForm = ({ accessToken, onFormSubmit }) => {
  const [username, setUsername] = useState("")
  const [fullName, setFullName] = useState("")
  const [password, setPassword] = useState("")
  // const [rolesList, setRolesList] = useState([]);
  const [role, setRole] = useState([])
  const [equipmentList, setEquipmentList] = useState([])

  const [directions, setDirections] = useState([])
  const [chosenDirPlace, setChosenDirPlace] = useState({ name: null, id: null })
  const [chosenIdType, setChosenIdType] = useState("")
  const [selectedItem, setSelectedItem] = useState("")
  const [isDirListOpen, setIsDirListOpen] = useState(false)
  const [isPlacesListOpen, setIsPlacesListOpen] = useState(false)
  const [placesListSates, setPlacesListSates] = useState({})
  const [wasUserCreated, setWasUserCreated] = useState(false)
  const [isLocalAdminCreated, setIsLocalAdminCreated] = useState(false)
  const [isFullnameBad, setIsFullNameBad] = useState(false)
  const [isPasswordBad, setIsPasswordBad] = useState(false)
  const [fullnameError, setFullnameError] = useState("Заполните это поле.")
  const [passwordError, setPasswordError] = useState("Заполните это поле.")
  const [rolesListError, setRolesListError] = useState({
    text: "Выберите роль.",
    state: false
  })
  const [equipListError, setEquipListError] = useState({
    text: "Выберите хотя бы один тип оборудования.",
    state: false
  })
  const [dirplacesError, setDirplacesError] = useState({
    text: "Выберите хотя бы одно направление или участок.",
    state: false
  })
  const [submitError, setSubmitError] = useState({
    text: "Заполните все поля, чтобы продолжить.",
    state: false
  })
  const [localAdminError, setLocalAdminError] = useState({
    text: "",
    state: false
  })
  const [isLoginEmptyError, setIsLoginEmptyError] = useState({
    text: "логин не может быть пустым.",
    state: false
  })
  // const [existingUserError, setExistingUserError] = useState({text: "Такой пользователь уже существует.", state: false})
  const [createModifiedUser, setCreateModifiedUser] = useState(false)
  const [isModalNoteOpened, setIsModalNoteOpened] = useState(false)
  const [makeNewUsernameModal, setMakeNewUsernameModal] = useState(false)
  const [newUsername, setNewUsername] = useState("")
  const [allUsers, setAllUsers] = useState([])
  const [isUsernameEditing, setIsUsernameEditing] = useState(false)
  const [customUsername, setCustomUsername] = useState("")
  const placesListRefs = useRef({})
  const dirPlacesInputRef = useRef(null)

  const [isPreparedLoginUsed, setIsPreparedLoginUsed] = useState(false)

  const ROLES = {
    hr: "HR",
    mechanic: "MECHA",
    user: "USER",
    analytic: "ANAL",
    dirAdmin: "LOCAL_ADMIN_DIR",
    placeAdmin: "LOCAL_ADMIN_PLACE"
  }

  const EQUIPS = [
    "EXCAVATOR",
    "BULLDOZER",
    "CRANE",
    "GRADER",
    "RINK",
    "TRUCK",
    "REFUELER",
    "AUTO",
    "POOKER",
    "TRACTOR",
    "MULE",
    "MIXER"
  ]

  // useEffect(() => {
  //   if(isTextCopied) {
  //     const timer = setTimeout(() => {
  //       setIsTextCopied(false);
  //     }, 2000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [isTextCopied]);

  const getUsers = useCallback(() => {
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_USERS}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      }
    })
      .then((response) => response.json())
      .then((data) => {
        const names = []
        // console.log(data)
        data.forEach((user) => {
          names.push(user.username)
        })
        setAllUsers(names)
        // console.log(names)
        // console.log(allUsers)
      })
      .catch((error) => {
        console.error("Error fetching data: ", error)
      })
  }, [accessToken])

  const getDirections = useCallback(() => {
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_DIRECTIONS}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        // console.log(data);

        const dirs = []

        data.forEach((dir) => {
          const places = []
          const name = dir.name
          const direction_id = dir.id

          if (dir.places.length > 0) {
            dir.places.forEach((place) => {
              const name = place.name
              const place_id = place.id
              places.push({ name, place_id })
            })
          } else {
          }

          dirs.push({ name, direction_id, places })
        })
        setDirections(dirs)
      })
      .catch((error) => {
        console.error("Error fetching data: ", error)
      })
  }, [accessToken])

  useEffect(() => {
    getDirections()
    getUsers()
  }, [accessToken])

  const handleCloseNoteModal = () => {
    setIsModalNoteOpened(false)
    if (wasUserCreated) {
      setFullName("")
      setPassword("")
      setRole([])
      setEquipmentList([])
      setUsername("")
      setChosenDirPlace({ name: null, id: null })
      setChosenIdType("")
    }
  }

  const onCloseModal = (setModal) => {
    setModal(false)
  }

  const toggleDirList = () => {
    // console.log(placesListSates)
    setIsDirListOpen(!isDirListOpen)
  }

  const togglePlacesList = (index) => {
    try {
      if (!placesListSates[index]) {
        setPlacesListSates((prevStates) => ({
          ...prevStates,
          [index]: true
        }))
      } else {
        setPlacesListSates((prevStates) => ({
          ...prevStates,
          [index]: false
        }))
      }
    } catch (error) {
      console.log("Oops! Failed to open places list: ", error)
    }
  }

  const handleFullNameChange = (event) => {
    // console.log(event.target)
    // console.log(event.target)
    const re = /\d/g
    setFullName(event.target.value)
    if (re.test(String(event.target.value))) {
      setFullnameError("В ФИО не может быть цифр.")
    } else if (!event.target.value) {
      setFullnameError("ФИО не может быть пустым.")
    } else {
      setFullnameError("")
    }
  }

  const handlePasswordChange = (value) => {
    const re = /\s/g
    setPassword(value)
    if (re.test(String(value))) {
      setPasswordError("В пароле не может быть пробелов.")
    } else if (!value) {
      setPasswordError("Пароль не может быть пустым.")
    } else {
      setPasswordError("")
    }
  }

  const handleRoleChange = (value) => {
    let newRole = []
    setRolesListError((p) => ({ ...p, state: false }))
    newRole.push(value)
    setRole(newRole)
  }

  const handleSwitchEquipment = (type) => {
    setEquipmentList((prevList) => {
      if (!prevList.includes(type)) {
        setEquipListError({ state: false })
        return [...prevList, type]
      } else {
        const newEquipmentList = prevList.filter((item) => item !== type)
        if (newEquipmentList.length === 0) {
          setEquipListError({
            text: "Выберите хотя бы один тип оборудования.",
            state: true
          })
          // console.log(equipListError.state);
        }
        return newEquipmentList
      }
    })
  }

  const handleSelectDirPlace = (index, name, id, type, dirname) => {
    setSelectedItem(index)
    if (dirname) {
      setChosenDirPlace({ name, id, dirname })
    } else {
      setChosenDirPlace({ name, id })
    }
    setChosenIdType(type)
    setIsDirListOpen(false)
    // console.log('сам placesListSates: ', placesListSates, 'индекс placesListSates: ', placesListSates[index]);

    setPlacesListSates((prevStates) => {
      const newStates = {}
      Object.keys(prevStates).forEach((key) => {
        newStates[key] = false
      })
      return newStates
    })
  }

  const handleBlur = (event) => {
    switch (event.target.name) {
      case "fullName":
        setIsFullNameBad(true)
        break
      case "password":
        setIsPasswordBad(true)
        break
      default:
        setIsFullNameBad(false)
        setIsPasswordBad(false)
    }
  }

  const handleSubmitLocalAdmin = () => {
    let url = ""
    // console.log('we were here')
    // const username = makeUsername(fullName)
    let formData = {}
    if (chosenIdType === "directionId") {
      formData = {
        username: username,
        directionName: chosenDirPlace.name
      }
    } else if (chosenIdType === "placeId") {
      formData = {
        username: username,
        directionName: chosenDirPlace.dirname,
        placeName: chosenDirPlace.name
      }
    }
    if (chosenIdType === "directionId") {
      // const directionId = chosenDirPlace.id;
      url = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.POST_DIR_ADMIN}`
      // formData = (username, directionId)
    } else if (chosenIdType === "placeId") {
      // const placeId = chosenDirPlace.id;
      url = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.POST_PLACE_ADMIN}`
      // formData = (username, placeId);
    }
    // console.log(formData)
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    })
      .then((response) => {
        // setDirplacesError(prev => ({...prev, state: false}));
        if (response.ok) {
          // onFormSubmit();
          setIsLocalAdminCreated(true)
          console.log("local admin was created!")
        }
      })
      .catch((error) => {
        console.log("error while creating local admin: ", error)
      })
  }

  const makeUsername = (name, additionalPart) => {
    if (/\d/g.test(name)) {
      setIsFullNameBad(true)
      setFullnameError("В ФИО не может быть цифр.")
      return
    }
    const nameParts = name.split(" ")
    if (nameParts.length !== 3) {
      setIsFullNameBad(true)
      setFullnameError("Некорректный формат ФИО.")
      return
    }
    const lastName = nameParts[0]
    const firstNameInitial = nameParts[1].charAt(0)
    const middleNameInitial = nameParts[2].charAt(0)
    if (additionalPart) {
      return `${lastName}${firstNameInitial}${middleNameInitial}${additionalPart}`
    } else {
      return `${lastName}${firstNameInitial}${middleNameInitial}`
    }
  }

  const doesUserExists = (name) => {
    return allUsers.includes(name)
  }

  const numberOfRepetitions = (name) => {
    // console.log('name при заходе в функцию: ', name)
    let i = 0
    allUsers.forEach((username) => {
      // console.log(username)
      // const lastSymbol = username.at(-1)
      // console.log(lastSymbol, typeof lastSymbol)
      // if (typeof lastSymbol === 'number') {
      if (/\d$/.test(username)) {
        // console.log('это цифра, да ладно (кстати вот у этого юзика): ', username)
        const tmp = username.slice(0, -1)
        // console.log('юзик после сплайса: ', tmp)
        if (tmp === name) {
          i++
        }
      } else {
        if (username === name) {
          // console.log('ну ты поняла')
          i++
        }
      }
    })
    return i
  }

  const handleCreatedUser = (name) => {
    if (!name.trim()) {
      setIsLoginEmptyError((p) => ({ ...p, state: true }))
    } else {
      setUsername(name)
      setIsUsernameEditing(false)
      setMakeNewUsernameModal(false)
      setCreateModifiedUser(true)
      handleSubmit()
    }
  }

  const handleSubmit = (event) => {
    let isUserAdmin = false
    // console.log(username)
    // console.log('пошли по сложному пути')
    let tmpRole = role
    if (event) {
      event.preventDefault()
    }
    if (
      !fullName.length ||
      !password.length ||
      !role.length ||
      !equipmentList.length ||
      !chosenDirPlace.id
    ) {
      if (!fullName.length) {
        setIsFullNameBad(true)
      }
      if (!password.length) {
        setIsPasswordBad(true)
      }
      if (!role.length) {
        setRolesListError({ text: "Выберите роль.", state: true })
      }
      if (!equipmentList.length) {
        setEquipListError((prev) => ({ ...prev, state: true }))
      }
      if (!chosenDirPlace.id) {
        setDirplacesError({
          text: "Выберите хотя бы одно направление или участок.",
          state: true
        })
      }
      setSubmitError({
        text: "Заполните все поля, чтобы продолжить.",
        state: true
      })
      return
    }
    setSubmitError({ state: false })
    if (role[0].includes("LOCAL_ADMIN")) {
      if (role[0] === "LOCAL_ADMIN_PLACE" && chosenIdType !== "placeId") {
        setLocalAdminError({
          text: "При роли Администратор участка можно выбрать только участок.",
          state: true
        })
        return
      } else if (
        role[0] === "LOCAL_ADMIN_DIR" &&
        chosenIdType !== "directionId"
      ) {
        setLocalAdminError({
          text: "При роли Администратор направления можно выбрать только направление.",
          state: true
        })
        return
      } else {
        setLocalAdminError({ text: "", state: false })
        handleRoleChange("LOCAL_ADMIN")
        tmpRole = ["USER"]
        isUserAdmin = true
      }
    }

    let uname = makeUsername(fullName)
    if (!isPreparedLoginUsed) {
      setUsername(uname)
      // console.log('((!customUsername.length || customUsername === undefined || customUsername === null): ', ((!customUsername.length || customUsername === undefined || customUsername === null)))
      // console.log('createModifiedUser: ',createModifiedUser)
      if (
        (!customUsername.length ||
          customUsername === undefined ||
          customUsername === null) &&
        createModifiedUser &&
        !isPreparedLoginUsed
      ) {
        // console.log('не понял')
        setIsLoginEmptyError((p) => {
          console.log({ ...p, state: true })
          return { ...p, state: true }
        })
        return
      }

      if (
        customUsername.length &&
        customUsername !== undefined &&
        customUsername !== null
      ) {
        uname = customUsername
      }
      // console.log(uname)

      if (doesUserExists(uname)) {
        // console.log('осознали что юзик существует')
        let newName
        if (customUsername) {
          // console.log('осознали что customUsername существует: ', customUsername)
          newName = customUsername
        } else {
          // console.log('осознали что customUsername НЕ существует')
          newName = makeUsername(fullName, numberOfRepetitions(uname))
        }
        setNewUsername(newName)
        setMakeNewUsernameModal(true)
        return
      } else {
        // console.log('осознали что юзик НЕ существует. И вот uname: ', uname)
        setUsername(uname)
      }
      if (createModifiedUser) {
        // console.log('зашли в createModifiedUser')
        if (
          !newUsername.length ||
          newUsername === undefined ||
          newUsername === null
        ) {
          // console.log('(!newUsername.length || newUsername === undefined || newUsername === null)')
          setMakeNewUsernameModal(false)
          setIsLoginEmptyError((p) => {
            console.log({ ...p, state: true })
            return { ...p, state: true }
          })
          return
        }
        setMakeNewUsernameModal(false)
        setIsUsernameEditing(false)
        setUsername(newUsername)
        uname = newUsername
      }
    } else {
      // console.log('типа используем подготовленный логин')
      uname = makeUsername(fullName, numberOfRepetitions(uname))
      setUsername(uname)
    }

    if (!uname) {
      return
    }

    let formData = {}
    if (chosenIdType === "directionId") {
      formData = {
        username: uname,
        password: password,
        fullName: fullName,
        specialisations: equipmentList,
        roles: tmpRole,
        directionName: chosenDirPlace.name
      }
    } else if (chosenIdType === "placeId") {
      formData = {
        username: uname,
        password: password,
        fullName: fullName,
        specialisations: equipmentList,
        roles: tmpRole,
        directionName: chosenDirPlace.dirname,
        placeName: chosenDirPlace.name
      }
    }
    console.log(JSON.stringify(formData))
    console.log(formData)
    if (!fullnameError && password.length >= 8) {
      fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.POST_USER}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      })
        .then((response) => {
          console.log("response: ", response)
          if (response.ok) {
            setWasUserCreated(true)
            setCreateModifiedUser(false)
            setIsFullNameBad(false)
            setIsPasswordBad(false)
            setEquipListError((prev) => ({ ...prev, state: false }))
            setRolesListError((prev) => ({ ...prev, state: false }))
            setDirplacesError((prev) => ({ ...prev, state: false }))
            setIsLocalAdminCreated(false)
            setIsPreparedLoginUsed(false)
            onFormSubmit()
            setIsModalNoteOpened(true)
            if (isUserAdmin) {
              console.log("условие сработало!")
              handleSubmitLocalAdmin()
            }
            // setIsFormSubmitted(true);
            console.log("new user post worked!")
          }
        })
        .catch((error) => {
          setWasUserCreated(false)
          console.log("error while fetching data: ", error)
          // console.log(error.message)
        })
    } else {
      console.log("format is wrong")
    }
  }

  const onGeneratePassword = () => {
    //буквы l и I удалены из-за их схожести
    const lower = "abcdefghijklmnopqrstuvwxyz"
    const upper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    const numbers = "0123456789"
    const symbols = "!@#$%^&*()_+[]{}|;:',.<>?"
    const length = 8
    let password = [
      lower[Math.floor(Math.random() * lower.length)],
      upper[Math.floor(Math.random() * upper.length)],
      numbers[Math.floor(Math.random() * numbers.length)],
      symbols[Math.floor(Math.random() * symbols.length)]
    ]
    const allCharacters = lower + upper + numbers + symbols
    for (let i = password.length; i < length; i++) {
      password.push(
        allCharacters[Math.floor(Math.random() * allCharacters.length)]
      )
    }
    const generatedPassword = password.sort(() => Math.random() - 0.5).join("")
    setPassword(generatedPassword)
    setIsPasswordBad(false)
  }

  return (
    <>
      <section className="addition-form-wrapper">
        <>
          <form className="addition-form" onSubmit={handleSubmit} method="post">
            <div className="addition-form__input-wrapper">
              <label htmlFor="full name">ФИО</label>
              <input
                id="full name"
                name="fullName"
                className="addition-form-input"
                placeholder="Введите ФИО"
                value={fullName}
                // ref={isFieldEmptyRef}
                onChange={(e) => handleFullNameChange(e)}
                onBlur={(e) => handleBlur(e)}
                style={
                  isFullnameBad && fullnameError
                    ? { border: "3px solid #923C46" }
                    : { border: "1px solid #211b27" }
                }
              />
              {isFullnameBad && fullnameError && (
                <p className="form__error-message">{fullnameError}</p>
              )}
            </div>

            <div className="addition-form__input-wrapper">
              <label htmlFor="password">Пароль</label>
              <div className="addition-form__password-input-wrapper">
                <input
                  id="password"
                  name="password"
                  type="text"
                  className="addition-form-input"
                  placeholder="Введите пароль"
                  value={password}
                  // ref={isFieldEmptyRef}
                  onBlur={(e) => handleBlur(e)}
                  onChange={(e) => handlePasswordChange(e.target.value)}
                  style={
                    isPasswordBad && passwordError
                      ? { border: "3px solid #923C46" }
                      : { border: "1px solid #211b27" }
                  }
                />
                <button
                  className="addition-form__bth-generate-password"
                  onClick={onGeneratePassword}
                  type="button"
                >
                  {" "}
                  Сгенерировать
                </button>
              </div>
              {isPasswordBad && passwordError && (
                <p className="form__error-message">{passwordError}</p>
              )}
            </div>

            <div className="addition-form__input-wrapper">
              <label htmlFor="role">Должность</label>
              <div className="addition-button-type-list">
                {/* {Object.values(ROLES).map(value =>
                <button id='role' type='button' className={`addition-button button-add-member-employee-with-workers ${role.includes(value) ? 'selected' : ''}`}
                // onClick={(e) => handleSwitchRole(e.target.value)}
                onClick={(e) => handleRoleChange(e.target.value)}
                value={value}
                >
                Работник с персоналом
              </button>
              )
              } */}
                <button
                  id="role"
                  type="button"
                  className={`addition-button button-add-member-employee-with-workers ${
                    role.includes(ROLES.hr) ? "selected" : ""
                  }`}
                  // onClick={(e) => handleSwitchRole(e.target.value)}
                  onClick={(e) => handleRoleChange(e.target.value)}
                  value={ROLES.hr}
                >
                  Работник с персоналом
                </button>
                <button
                  id="role"
                  type="button"
                  className={`addition-button button-add-member-mechanic ${
                    role.includes(ROLES.mechanic) ? "selected" : ""
                  }`}
                  // onClick={(e) => handleSwitchRole(e.target.value)}
                  onClick={(e) => handleRoleChange(e.target.value)}
                  value={ROLES.mechanic}
                >
                  Механик
                </button>
                <button
                  id="role"
                  type="button"
                  className={`addition-button button-add-member-worker ${
                    role.includes(ROLES.user) ? "selected" : ""
                  }`}
                  // onClick={(e) => {handleSwitchRole(e.target.value)}}
                  onClick={(e) => handleRoleChange(e.target.value)}
                  value={ROLES.user}
                >
                  Рабочий
                </button>
                <button
                  id="role"
                  type="button"
                  className={`addition-button button-add-member-analytic ${
                    role.includes(ROLES.analytic) ? "selected" : ""
                  }`}
                  // onClick={(e) => handleSwitchRole(e.target.value)}
                  onClick={(e) => handleRoleChange(e.target.value)}
                  value={ROLES.analytic}
                >
                  Аналитик
                </button>
                <button
                  id="role"
                  type="button"
                  className={`addition-button button-add-member-analytic ${
                    role.includes(ROLES.dirAdmin) ? "selected" : ""
                  }`}
                  // onClick={(e) => handleSwitchRole(e.target.value)}
                  onClick={(e) => handleRoleChange(e.target.value)}
                  value={ROLES.dirAdmin}
                >
                  Администратор направления
                </button>
                <button
                  id="role"
                  type="button"
                  className={`addition-button button-add-member-analytic ${
                    role.includes(ROLES.placeAdmin) ? "selected" : ""
                  }`}
                  // onClick={(e) => handleSwitchRole(e.target.value)}
                  onClick={(e) => handleRoleChange(e.target.value)}
                  value={ROLES.placeAdmin}
                >
                  Администратор участка
                </button>
              </div>
              {rolesListError.state && (
                <p className="form__error-message">{rolesListError.text}</p>
              )}
              {localAdminError.state && (
                <p className="form__error-message">{localAdminError.text}</p>
              )}
            </div>

            <div className="addition-form__input-wrapper">
              <div className="dirplaces-list-container">
                <label>Направления и участки</label>
                <div className="addition-form-input dirplaces-input analytics-item">
                  {chosenDirPlace.name ? (
                    <>
                      <p>
                        {chosenIdType === "directionId"
                          ? "Выбранное направление"
                          : "Выбранный участок"}
                        :{" "}
                      </p>
                      <p className="chosen-dirplace-text">
                        {chosenDirPlace.name}
                      </p>
                    </>
                  ) : dirplacesError.state ? (
                    <p className="form__error-message">{dirplacesError.text}</p>
                  ) : (
                    <p>Выберите направление либо участок</p>
                  )}
                  <button
                    type="button"
                    className={
                      "button" && isDirListOpen
                        ? "button-close-analytics"
                        : "button-open-analytics"
                    }
                    onClick={toggleDirList}
                  ></button>
                </div>

                <div className={`dirplaces ${isDirListOpen ? "open" : ""}`}>
                  {isDirListOpen && (
                    <div
                      className="dirplaces-list-wrapper"
                      ref={dirPlacesInputRef}
                    >
                      <ul className="dirplaces-list">
                        {directions.map((dir, index) => (
                          <div
                            key={index}
                            className={`dirplaces-list__wrapper `}
                          >
                            <li
                              key={index}
                              className={`dirplaces-list-item ${
                                selectedItem === index &&
                                chosenIdType !== "placeId"
                                  ? "selected-item"
                                  : ""
                              } ${
                                isPlacesListOpen || placesListSates[index]
                                  ? "opened-list"
                                  : ""
                              }`}
                              onClick={() =>
                                handleSelectDirPlace(
                                  index,
                                  dir.name,
                                  dir.direction_id,
                                  "directionId"
                                )
                              }
                            >
                              <span>{dir.name}</span>
                            </li>
                            <button
                              ref={(list) =>
                                (placesListRefs.current[index] = list)
                              }
                              type="button"
                              className={`places-list__button ${
                                isPlacesListOpen || placesListSates[index]
                                  ? "button-close-places opened-list"
                                  : "button-open-places"
                              }`}
                              onClick={() => togglePlacesList(index)}
                            ></button>
                            <div
                              ref={(list) =>
                                (placesListRefs.current[index] = list)
                              }
                              className={`places ${
                                isPlacesListOpen || placesListSates[index]
                                  ? "open"
                                  : ""
                              }`}
                            >
                              {(isPlacesListOpen || placesListSates[index]) && (
                                <div className="places-list-wrapper">
                                  {/* <p>{dir.name}</p> */}
                                  <ul className="dirplaces-list dirplaces-list-places">
                                    {dir.places.length > 0 ? (
                                      <>
                                        {dir.places.map((place, index) => (
                                          <li
                                            key={index}
                                            className={`dirplaces-list-item ${
                                              selectedItem === index &&
                                              chosenIdType !== "directionId"
                                                ? "selected-item"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              handleSelectDirPlace(
                                                index,
                                                place.name,
                                                place.place_id,
                                                "placeId",
                                                dir.name
                                              )
                                            }
                                          >
                                            <span>{place.name}</span>
                                          </li>
                                        ))}
                                      </>
                                    ) : (
                                      <p className="dirplaces-empty-list-note">
                                        У этого направления нет участков.
                                      </p>
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="addition-form__input-wrapper">
              <label>Тип оборудования</label>
              <div className="button-type-list addition-button-type-list addition-user-equipment-button-list">
                {EQUIPS.map((equip, index) => {
                  return (
                    <button
                      key={index}
                      type="button"
                      className={`addition-button-add-equipment modal-change-equipment technic-type-${
                        equipmentList.includes(equip) ? "white" : "black"
                      }-${equip.toLowerCase()} ${
                        equipmentList.includes(equip) ? "selected" : ""
                      }`}
                      onClick={(e) => {
                        handleSwitchEquipment(e.target.value)
                      }}
                      value={equip}
                    />
                  )
                })}
              </div>
              {equipListError.state && (
                <p className="form__error-message">{equipListError.text}</p>
              )}
            </div>

            <div className="addition-submit-__wrapper">
              {submitError.state && (
                <p className="form__error-message">{submitError.text}</p>
              )}
              <button className="button-submit-direction" type="submit">
                Добавить пользователя
              </button>
            </div>
          </form>
        </>
      </section>
      {isModalNoteOpened && (
        <ModalNote
          note="Пользователь добавлен! Можете скопировать данные для входа:"
          login={username}
          password={password}
          // {...(isLocalAdminCreated &&
          // {localAdminCreated='Локальный админ создан!'}
          // )}
          onClose={handleCloseNoteModal}
        />
      )}
      {makeNewUsernameModal && (
        <div className="modal-window modal-window_delete">
          <div className="modal-window__wrapper modal-window_delete__wrapper">
            <CloseBtn
              blackOrWhite="white"
              style={{ position: "absolute", right: "1rem", top: "1rem" }}
              onClick={() => {
                setIsUsernameEditing(false)
                setMakeNewUsernameModal(false)
              }}
            />
            <p>{`Пользователь с логином ${username} уже существует. Хотите создать нового пользователя с готовым логином ${newUsername}?`}</p>
            <section className="modal-window__buttons-block modal-window_delete__buttons-block">
              <button
                className="modal-window__button_delete button_reverse_deletion"
                onClick={() => {
                  setIsPreparedLoginUsed(true)
                  handleCreatedUser(newUsername)
                  // setCreateModifiedUser(true);
                  // handleSubmit();
                }}
              >
                {" "}
                Да, создать с готовым логином{" "}
              </button>
              <button
                className="modal-window__button_delete button_deletion"
                onClick={() => {
                  setIsUsernameEditing(true)
                  setMakeNewUsernameModal(false)
                }}
              >
                Написать свой логин для пользователя
              </button>
            </section>
          </div>
        </div>
      )}
      {isUsernameEditing && (
        <div className="modal-window">
          <div className="modal-window__wrapper modal-window__editing-modal-window">
            <p>Введите новый логин для пользователя:</p>
            <input
              type="text"
              className="modal-window__input"
              style={
                isLoginEmptyError.state
                  ? { border: "3px solid #923C46" }
                  : { border: "1px solid #211b27" }
              }
              onChange={(e) => setCustomUsername(e.target.value)}
            />
            {isLoginEmptyError.state && (
              <p id="form__error-message">{isLoginEmptyError.text}</p>
            )}
            <section className="modal-window__buttons-block modal-window_delete__buttons-block">
              <button
                className="modal-window__button_delete button_reverse_deletion"
                onClick={() => {
                  setIsUsernameEditing(false)
                  setMakeNewUsernameModal(true)
                }}
              >
                Вернуться назад
              </button>

              <button
                className="modal-window__button_delete button_deletion"
                onClick={() => {
                  handleCreatedUser(customUsername)
                  // setCreateModifiedUser(true)
                  // handleSubmit()
                }}
              >
                Создать
              </button>
            </section>
          </div>
        </div>
      )}
    </>
  )
}

export default UserForm
