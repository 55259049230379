
export const handleEscape = (onClose) => {
  // useEffect(() => {
      const handleCloseFilterByEscape = (e) => {
        if (e.key === 'Escape') {
          onClose()
        }
      };
      document.body.addEventListener('keydown', handleCloseFilterByEscape);
  
      return () => {
        document.body.removeEventListener('keydown', handleCloseFilterByEscape);
      };
    // }, [onClose]);  
}
