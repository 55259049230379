import { useState, useEffect, useCallback } from "react";
import { GotoArrowBtn, CloseBtn } from "@widgets/buttons";
import { getAllStaff } from "@entities/staff/api/get-all-staff";

export const AddStaffList_New = (props) => {
  const {
    accessToken,
    style,
    areEvaluators = false,
    arePerformers = false,
    selectedStaffArray,
    setSelectedStaffArray
  } = props;

  const [isListOpened, setIsListOpened] = useState(false);
  // const [selectedItemId, setSelectedItemId] = useState();
  const [staffList, setStaffList] = useState([]);
  // const [selectedStaffArray, setSelectedStaffArray] = useState([]);
  const [filter, setFilter] = useState("");

  const onToggleList = () => {
    setIsListOpened((p) => !p);
  };

  const onSelect = (username) => {
    setSelectedStaffArray((prevArray) => {
      const array = prevArray || [];
      if (!array.includes(username)) {
        // setStaff(array)
        return [...array, username];
      } else {
        return array;
      }
    });
    // setSelectedItemId(index);
    // onToggleList();
  };

  const fetchStaff = useCallback(async () => {
    try {
      const allStaff = await getAllStaff(accessToken);
      let staffArray = [];
      // console.log(allStaff);

      if (areEvaluators) {
        allStaff.forEach((staff) => {
          let staffObj = {};
          // console.log(staff.roles)
          if (staff.roles.includes("MECHA")) {
            staffObj = {
              username: staff.username,
              fullname: staff.fullName,
              roles: staff.roles,
            };
            staffArray.push(staffObj);
          }
        });
      } else if (arePerformers) {
        allStaff.forEach((staff) => {
          let staffObj = {};
          // console.log(staff.roles)
          if (
            staff.fullName &&
            staff.username &&
            (staff.roles.includes("ADMIN") ||
              staff.roles.includes("LOCAL_ADMIN") ||
              staff.roles.includes("USER"))
          ) {
            staffObj = {
              username: staff.username,
              fullname: staff.fullName,
              roles: staff.roles,
            };
            staffArray.push(staffObj);
          }
        });
      }
      // console.log(staffArray);
      setStaffList(staffArray);
    } catch (error) {
      console.error("Ошибка при получении сотрудников:", error);
      setStaffList([]);
    }
  }, [accessToken, arePerformers, areEvaluators]);

  useEffect(() => {
    fetchStaff();
  }, [fetchStaff]);

  const filteredStaff = staffList.filter((staff) => {
    // console.log(staff.fullname)
    return staff.fullname.toLowerCase().includes(filter.toLowerCase());
  });

  const onDeleteEquipment = (staff) => {
    // console.log('selectedStaffArray', selectedStaffArray, 'id: ', staff)
    setSelectedStaffArray((prevArray) => {
      // console.log('prevArray: ', prevArray)
      // const array = prevArray || [];
      const array = prevArray.filter((item) => item !== staff);
      // console.log('new array: ', array)
      return array;
    });
  };

  // console.log('selectedStaffArray ', selectedStaffArray)

  return (
    <>
      <div className="dropdown-list add-staff-list" style={style}>
        <div className="dropdown-list__field">
          <p className="districts__chosen-district">
            {isListOpened ? (
              <>
                <input
                  type="text"
                  className="equipments-list__search-input"
                  placeholder="Введите ФИО сотрудника"
                  onChange={(e) => setFilter(e.target.value)}
                />
              </>
            ) : (
              "Сотрудники"
            )}
            {/* {selectedItem ? <>{selectedItem}</> : <>{heading}</>} */}
          </p>

          {!isListOpened ? (
            <GotoArrowBtn onClick={onToggleList} rotation="rb" />
          ) : (
            <CloseBtn
              blackOrWhite="white"
              bg="dark"
              onClick={onToggleList}
              style={{ marginLeft: "0.6rem" }}
            />
          )}
        </div>

        <div
          className={`dropdown-list__list ${
            isListOpened && "dropdown-list__list_opened"
          } `}
        >
          {staffList.length > 0 ? (
            <ul className="dropdown-list__list-list">
              {filteredStaff.map((staff) => {
                return (
                  <>
                    <div className="dropdown-list__item-container">
                      <li
                        key={staff.username}
                        className={`dropdown-list__item add-staff-list__item ${
                          selectedStaffArray.includes(staff.username)
                            ? "chosen"
                            : ""
                        } `}
                        onClick={() => onSelect(staff.username)}
                      >
                        <span className="dropdown-list__value">
                          {staff.fullname}
                        </span>
                      </li>
                      <div className="dropdown-list__item-button">
                        {selectedStaffArray.includes(staff.username) && (
                          <CloseBtn
                            style={{
                              // zIndex: "1000",
                              // position: "absolute",
                              // top: "13%",
                              // right: "2%",
                              width: "2.7rem",
                              height: "2.7rem",
                            }}
                            blackOrWhite="white"
                            onClick={() => onDeleteEquipment(staff.username)}
                          />
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </ul>
          ) : (
            <div className="dropdown-list__empty-message">
              Не удалось вывести список сотрудников или нет ни одного
              сотрудника, подходящего по критериям.
            </div>
          )}
        </div>
      </div>
    </>
  );
};
