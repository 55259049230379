export const MENU_PAGES = {
  objectsManagement: "Управление объектами",
  objects: "Объекты",
  tracker: "Трекер задач",
  documentation: "Документация",
  resourceManagement: "Управление ресурсами",
  shifts: "Таблица смен",
  staffAndEquipments: "Сотрудн. и оборудован.",
  downloadAPK: "Скачать приложение",
  logout: "Выход из сервиса"
}

export const MENU_LINKS = {
  objects: "/objects",
  tracker: "/task-tracker",
  documentation: "/documentation",
  shifts: "/load-data-table",
  staffAndEquipments: "/addition-user",
  downloadAPK: "/apk-download"
  // logout: 'Выход из сервиса'
}

export const COLORS = {
  white: "white",
  black: "black"
}
