import apiConfig from "../../../apiConfig.json";
export const postTask = (props) => {
  const {
    accessToken,
    start,
    end,
    qualifier,
    qualifierId,
    header,
    work_number,
    description,
    estimated_volume,
    monthly_volume,
    volume_metrics,
    metric_cost,
    total_cost,
    equipments,
    performers,
    evaluators,
    additional_info,
  } = props;

  const data = {
    qualifier: qualifier,
    qualfier_id: qualifierId,
    start: start,
    end: end,
    header: header,
    work_number: work_number,
    description: description,
    estimated_volume: estimated_volume,
    monthly_volume: monthly_volume,
    volume_metrics: volume_metrics,
    metric_cost: metric_cost,
    total_cost: total_cost,
    equipments: equipments,
    performers: performers,
    evaluators: evaluators,
    additional_info: additional_info,
  };
  // const data = {
  //   start: start,
  //   end: end,
  //   project_id: project_id,
  //   chapter_id: chapter_id,
  //   section_id: section_id,
  //   header: header,
  //   work_number: work_number,
  //   description: description,
  //   estimated_volume: estimated_volume,
  //   monthly_volume: monthly_volume,
  //   volume_vemtrics: volume_metrics,
  //   metric_cost: metric_cost,
  //   total_cost: total_cost,
  //   equipments: equipments,
  //   performers: performers,
  //   evaluators: evaluators
  // }

  console.log(data);
  const url = `${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.POST_TASK}`;
  console.log(url);
  fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.POST_TASK}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiConfig.DEFAULT_TOKEN}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        console.log("task was created succesfully!");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

// {
//   "start": "2025-01-16T15:55:16.733Z",
//   "end": "2025-01-16T15:55:16.733Z",
//   "project_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//   "chapter_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//   "section_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//   "header": "string",
//   "work_number": "string",
//   "description": "string",
//   "estimated_volume": 0,
//   "monthly_volume": 0,
//   "volume_metrics": "string",
//   "metric_cost": 0,
//   "total_cost": 0,
//   "equipments": [
//     0
//   ],
//   "performers": [
//     "string"
//   ],
//   "evaluators": [
//     "string"
//   ],
//   "additional_info": {
//     "additionalProp1": "string",
//     "additionalProp2": "string",
//     "additionalProp3": "string"
//   }
// }
