import { useState, useCallback, useEffect } from "react"
import { NavigationMenu } from "@features/navigation-menu"
import { SwitcherBetweenPages } from "@widgets/SwitcherBetweenPages/index"
import { Filters } from "@features/filters/index"
import { SearchField } from "@widgets/search-field/index"
import { ProjectCard } from "../index"
import { getAllProjects } from "@entities/project/index"
import { NewProjectForm } from "@entities/project/index"
import ModalNote from "../../../Components/ModalNote"
import { deleteProject } from "@entities/project/api/delete-project"
import { createPortal } from "react-dom"
import { statuses } from "../index"

export const ProjectsMainPage = (props) => {
  const PAGES = {
    runningObjects: "Актуальные",
    doneObjects: "Завершённые"
  }

  const { accessToken, page = PAGES.runningObjects } = props
  const rootElement = document.querySelector(".body-wrapper")
  const [isNewProjectFormOpened, setIsNewProjectFormOpened] = useState(false)
  const [projects, setProjects] = useState([])
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [modalMessage, setModalMessage] = useState("")
  const [errors, setErrors] = useState({})
  const [currentPage, setCurrentPage] = useState(page)

  const [projectDeletionStatus, setProjectDeletionStatus] = useState({
    message: "",
    hasError: false
  })
  const [projectCreationStatus, setProjectCreationStatus] = useState({
    message: "",
    hasError: false
  })

  const [projectUpdatingStatus, setProjectUpdatingStatus] = useState({
    message: "",
    hasError: false
  })

  const fetchProjects = useCallback(async () => {
    try {
      const allProjects = await getAllProjects(accessToken)
      let relevantProjects = []
      if (currentPage === PAGES.doneObjects) {
        allProjects.forEach((project) => {
          if (project.status === statuses.DONE) {
            relevantProjects.push(project)
          }
        })
      }

      if (currentPage === PAGES.runningObjects) {
        allProjects.forEach((project) => {
          if (project.status !== statuses.DONE) {
            relevantProjects.push(project)
          }
        })
      }

      setProjects(relevantProjects)
    } catch (error) {
      console.error("Ошибка при получении объектов:", error)
      setProjects([])
    }
  }, [accessToken, currentPage])

  useEffect(() => {
    fetchProjects()
  }, [fetchProjects])

  const onToggleModal = (setter) => {
    setter((p) => !p)
  }

  const handleDeleteProject = async (id, name) => {
    const result = await deleteProject(id)
    if (!result.ok) {
      setProjectDeletionStatus({
        message: `Произошла ошибка ${result.status} про попытке удалить проект${
          result.statusText && ` :${result.statusText}`
        }`,
        hasError: true
      })
    } else {
      setProjectDeletionStatus({
        message: `Объект ${name} успешно удалён`,
        hasError: false
      })
      fetchProjects()
    }
  }
  useEffect(() => {
    if (projectCreationStatus.message) {
      setModalMessage(projectCreationStatus.message)
      setIsModalOpened(true)
    } else if (projectDeletionStatus.message) {
      setModalMessage(projectDeletionStatus.message)
      setIsModalOpened(true)
    } else if (projectUpdatingStatus.message) {
      setModalMessage(projectUpdatingStatus.message)
      setIsModalOpened(true)
    }
  }, [projectCreationStatus, projectDeletionStatus, projectUpdatingStatus])

  return (
    <div className="body-wrapper">
      <div className="projects body-wrapper--flex">
        <NavigationMenu currentPage="/objects" accessToken={accessToken} />
        <main className="projects-main addition-main-index">
          <div className="page-container--dark-bg projects__page-container--dark-bg">
            <h1 className="projects__header">Объекты</h1>

            <div className="projects-main__upper">
              <SwitcherBetweenPages
                currentPage={currentPage}
                firstPage="Завершённые"
                secondPage="Актуальные"
                setCurrentPage={setCurrentPage}
                // firstLink="/objects-finished"
                // secondLink="/objects-current"
              />

              <SearchField
                initialArray={projects}
                setInitialArray={setProjects}
              />
              <div className="projects__button-wrapper">
                <button
                  onClick={() => onToggleModal(setIsNewProjectFormOpened)}
                  type="button"
                  className="projects__create-task-button container-flex--space-between"
                >
                  <span>+</span> Новый объект
                </button>
              </div>
            </div>

            <div className="projects-main__main">
              <Filters />
              <div className="projects-cards-container">
                {Array.isArray(projects) &&
                  projects.map((project) => (
                    <ProjectCard
                      key={project.id}
                      accessToken={accessToken}
                      refreshData={fetchProjects}
                      projId={project.id}
                      projName={project.name}
                      projStart={project.start}
                      projEnd={project.end}
                      projDescription={project.description}
                      projPosition={project.position}
                      projDirection={project.direction_name}
                      projStatus={project.status}
                      setProjectUpdatingStatus={setProjectUpdatingStatus}
                      handleDeleteProject={handleDeleteProject}
                      onClose={onToggleModal}
                    />
                  ))}
              </div>
            </div>
          </div>
        </main>

        {isNewProjectFormOpened && (
          <NewProjectForm
            refreshData={fetchProjects}
            accessToken={accessToken}
            setProjectCreationStatus={setProjectCreationStatus}
            onClose={() => onToggleModal(setIsNewProjectFormOpened)}
          />
        )}

        {isModalOpened && (
          <>
            {createPortal(
              <ModalNote
                onClose={() => {
                  onToggleModal(setIsModalOpened)
                  setModalMessage("")
                }}
                note={modalMessage}
              />,
              rootElement
            )}
          </>
        )}
      </div>
    </div>
  )
}
