import { NavigationMenu } from "@features/navigation-menu"
import { SwitcherBetweenPages } from "@widgets/SwitcherBetweenPages/index"
// import { SearchField } from "@widgets/search-field/ui/SearchField"
import { SearchField } from "@widgets/search-field/index"
import "../../Documentation/ui/_documentation-page.scss"
import "@shared/styles/_all.scss"
import { useState, useEffect, useCallback } from "react"
import { useSearchParams } from "react-router-dom"
import apiConfig from "../../../apiConfig.json"
import ModalDelete from "../../../Components/ModalDelete"

const DocumentationSamplePage = ({ accessToken }) => {
  const [searchParams] = useSearchParams()
  const [projectId, setProjectId] = useState("")
  const [projectName, setProjectName] = useState("")

  //
  const [projectsExpanded, setProjectsExpanded] = useState(false)
  const [tasksState, setTasksState] = useState({
    loading: false,
    projectInfo: null
  })
  const [allProjectState, setAllProjectState] = useState({
    loading: true,
    allProjectInfo: { projects: [] }
  })
  const [currentProjectId, setCurrentProjectId] = useState("")
  const [addTaskInputOpen, setAddTaskInputOpen] = useState(false)
  const [newChapterName, setNewChapterName] = useState("")
  const [newSectionName, setNewSectionName] = useState("")
  const initialTaskState = {
    start_time: "2025-01-27T10:11:15.118Z",
    end_time: "2025-01-27T10:11:15.118Z",
    work_name: "",
    description: "",
    work_number: 0,
    volume_metrics: "",
    metric_cost: "",
    equipment_types: ["string"],
    additional_info: {
      additionalProp1: "string",
      additionalProp2: "string",
      additionalProp3: "string"
    },
    direction_name: "string"
  }
  const [newTask, setNewTask] = useState(initialTaskState)
  const [addSectionInputOpen, setAddSectionInputOpen] = useState(null)
  const [addTaskOpen, setAddTaskOpen] = useState(null)
  const [deleteTaskModalOpen, setDeleteTaskModalOpen] = useState(false)
  const [currentTask, setCurrentTask] = useState("")
  const [currentTaskId, setCurrentTaskId] = useState("")

  const TABLEHEADER = [
    "Дата начала",
    "Дата конца",
    "Наименование работ",
    "Номер работ из реестра",
    "Описание работы",
    "Ед. изм.",
    "Стоимость единицы"
  ]

  // const onProjectsExpanded = () => {
  //   setProjectsExpanded(!projectsExpanded);
  // }

  // const handleProjectSelect = (project) => {
  //   setCurrentProjectId(project.id);
  //   setProjectName(project.name)
  //   refreshProject(project.id);
  //   setProjectsExpanded(false);
  // };

  const handleOpenDeleteTaskModal = (currentTask, currentTaskId) => {
    setCurrentTaskId(currentTaskId)
    setCurrentTask(currentTask)
    setDeleteTaskModalOpen(!deleteTaskModalOpen)
  }

  const onAddTaskClick = () => {
    setAddTaskInputOpen(true)
    console.log(addTaskInputOpen)
  }

  const handleAddTask = async () => {
    setAddTaskOpen(null)
    setNewTask(initialTaskState)
    const taskData = {
      ...newTask
    }
    console.log(JSON.stringify(taskData))
    try {
      const response = await fetch(
        `${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.POST_TASK_KC6}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(taskData)
        }
      )

      if (!response.ok) {
        throw new Error("Failed to add task")
      }

      const contentType = response.headers.get("Content-Type")

      let responseData
      if (contentType && contentType.includes("application/json")) {
        responseData = await response.json()
      } else {
        responseData = await response.text()
      }

      // console.log('Response data:', responseData);

      // Если ответ является JSON
      if (typeof responseData === "object") {
        const createdTask = responseData
        setTasksState((prevState) => {
          // const updatedChapters = [...prevState.projectInfo.chapters];
          // updatedChapters[chapterIndex].sections[sectionIndex].tasks = [
          //   ...updatedChapters[chapterIndex].sections[sectionIndex].tasks,
          //   createdTask,
          // ];

          return {
            ...prevState,
            projectInfo: {
              ...prevState.projectInfo
              // chapters: updatedChapters,
            }
          }
        })
      } else {
        console.warn("Non-JSON response:", responseData)
      }
    } catch (error) {
      console.error("Error adding task to section:", error)
    }
    setAddTaskInputOpen(false)
    refreshProject(currentProjectId)
  }

  // console.log(currentProjectId)
  useEffect(() => {
    fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_ALL_KC6_TASKS}`, {
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE",
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then((data) => {
        console.log(data)
        setTasksState({
          loading: false,
          projectInfo: data
        })
      })
      .catch((error) => {
        console.error("Error fetchinge:", error)
        setTasksState({
          loading: false,
          projectInfo: null
        })
      })
  }, [projectId])

  const refreshProject = useCallback((projectId) => {
    // console.log(projectId)
    fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_ALL_KC6_TASKS}`, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE",
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then((data) => {
        console.log(data)
        setTasksState({
          loading: false,
          projectInfo: data
        })
      })
      .catch((error) => {
        console.error("Error fetchinge:", error)
        setTasksState({
          loading: false,
          projectInfo: null
        })
      })
  }, [])

  // useEffect(() => {
  //   if (currentProjectId) {
  //     refreshProject(currentProjectId);
  //   }
  // }, [currentProjectId]);

  const handleDeleteTask = (taskIndex) => {
    fetch(
      `${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.DELETE_TASK_KC6}${taskIndex}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
          "Content-Type": "application/json"
        }
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log("task was deleted successfully!")
          setDeleteTaskModalOpen(false)
          refreshProject()
        }
      })
      .catch((error) => {
        console.log("error deleting user: ", error)
      })
  }

  // console.log(allProjectState.allProjectInfo)
  // Построение структуры
  // console.log(tasksState.projectInfo.chapters)

  return (
    <>
      {deleteTaskModalOpen && (
        <ModalDelete
          message={`Удалить ${currentTask}?`}
          actionText="Удалить"
          action={() => handleDeleteTask(currentTaskId)}
          onClose={handleOpenDeleteTaskModal}
        />
      )}
      <div className="body-wrapper">
        <div className="body-wrapper--flex">
          <NavigationMenu
            currentPage="/documentation"
            accessToken={accessToken}
          />
          <main className="addition-main-index">
            <div className="documentation page-container--dark-bg">
              <div className="documentation__header">
                <h1 className="documentation__title">Документация</h1>
                <div className="documentation__projects-list-container"></div>
              </div>
              <div className="documentation__nav-section">
                <SwitcherBetweenPages
                  currentPage="КС-6"
                  firstPage="ГПР"
                  secondPage="КС-6"
                  firstLink="/documentation"
                  secondLink="/documentation-samples"
                  faq="false"
                />
                <SearchField />
                <div className="documentation__button-wrapper">
                  <button className="documentation__button button button--primary">
                    Экспорт
                  </button>
                  <button className="documentation__button button button--primary">
                    Импорт
                  </button>
                </div>
              </div>
              <div className="documentation__table-container">
                <div className="documentation__table-header-container">
                  <ul className="documentation__table-header-list">
                    {TABLEHEADER.map((thead, index) => {
                      return (
                        <li
                          key={index}
                          className="documentation__table-header-item"
                        >
                          {thead}
                        </li>
                      )
                    })}
                  </ul>
                  <button className="documentation__button button button--primary">
                    Дополнит. информация
                  </button>
                </div>
                <div className="documentation__table-body-container">
                  {tasksState.projectInfo !== null && (
                    <>
                      <ul className="documnetation__task-list-kc6 task-list-kc6">
                        {tasksState.projectInfo.map((task, taskIndex) => {
                          console.log(task)
                          return (
                            <li className="task-list-kc6__item" key={task.id}>
                              <p>{task.start_time}</p>
                              <p>{task.end_time}</p>
                              <p>{task.work_name}</p>
                              <p>{task.work_number}</p>
                              <p>{task.description}</p>
                              <p>{task.volume_metrics}</p>
                              <p>{task.metric_cost}</p>
                              <button
                                className="task-list-kc6__button task-list-kc6__button--delete button button--secondary button--delete"
                                onClick={() =>
                                  handleOpenDeleteTaskModal(
                                    task.work_name,
                                    task.id
                                  )
                                }
                              ></button>
                            </li>
                          )
                        })}
                      </ul>
                    </>
                  )}
                  {addTaskInputOpen && (
                    <>
                      <div className="documentation__task-kc6 task-kc6 task-kc6--add">
                        <div className="task-kc6__input-wrapper task-kc6__input-wrapper--task">
                          <input
                            type="datetime-local"
                            placeholder="Дата начала"
                            value={newTask.start_time}
                            onChange={(e) =>
                              setNewTask({
                                ...newTask,
                                start_time: e.target.value
                              })
                            }
                          />
                          <input
                            type="datetime-local"
                            placeholder="Дата конца"
                            value={newTask.end_time}
                            onChange={(e) =>
                              setNewTask({
                                ...newTask,
                                end_time: e.target.value
                              })
                            }
                          />
                          <input
                            placeholder="Введите данные"
                            value={newTask.work_name}
                            onChange={(e) =>
                              setNewTask({
                                ...newTask,
                                work_name: e.target.value
                              })
                            }
                          />
                          <input
                            placeholder="Введите данные"
                            value={newTask.work_number}
                            onChange={(e) =>
                              setNewTask({
                                ...newTask,
                                work_number: e.target.value
                              })
                            }
                          />
                          <input
                            placeholder="Введите данные"
                            value={newTask.description}
                            onChange={(e) =>
                              setNewTask({
                                ...newTask,
                                description: e.target.value
                              })
                            }
                          />
                          <input
                            placeholder="Введите данные"
                            value={newTask.volume_metrics}
                            onChange={(e) =>
                              setNewTask({
                                ...newTask,
                                volume_metrics: e.target.value
                              })
                            }
                          />
                          <input
                            placeholder="Введите данные"
                            type="number"
                            value={newTask.metric_cost}
                            onChange={(e) =>
                              setNewTask({
                                ...newTask,
                                metric_cost: parseFloat(e.target.value)
                              })
                            }
                          />
                          <button
                            className="task-kc6__button task-kc6__button--add button button--secondary button--add"
                            onClick={() => handleAddTask(newChapterName)}
                          ></button>
                          <button
                            className="task-kc6__button button button--transparent button--close"
                            onClick={() => setAddTaskInputOpen(false)}
                          ></button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <button
                className="documentation__button documentation__button--add-chapter button button--primary"
                onClick={onAddTaskClick}
              >
                Добавить работу
              </button>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
export default DocumentationSamplePage
