import config from "../../../apiConfig.json";

export const postNewProject = async (props) => {
  const { accessToken, name, start, end, description, direction } = props;
  // console.log(props)
  const formData = {
    name: name,
    start: start,
    end: end,
    description: description,
    direction_name: direction.name,
  };

  // const formData = {
  //   name: "string",
  //   start: "2025-01-14T13:38:01.983Z",
  //   end: "2025-01-14T13:38:01.983Z",
  //   description: "string",
  //   position: [
  //     {
  //       "x": 0,
  //       "y": 0
  //     }
  //   ],
  //   "direction_name": "string"
  // }
// console.log('данные перед отправкой: ', formData, 'данные перед отправкой в формате json: ', JSON.stringify(formData))
  const response = await fetch(`${config.BASE_URL}${config.CRM_ENDPOINTS.POST_PROJECT}`, {
    method: "POST",
    headers: {
      'Authorization': `Bearer ${config.DEFAULT_TOKEN}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData),
  });
  // console.log(response);
  return { ok: response.ok, status: response.status, statusText: response.statusText }
    // } else {
  //   return response;
  // }

}
