import { useState, useCallback, useEffect, useRef } from "react"
import { NavigationMenu } from "@features/navigation-menu"
import UserForm from "./UserForm.jsx"
import SwitcherButtons from "./SwitcherButtons"
import TableHead from "./TableHead"
import TableRow from "./TableRow"
import apiConfig from "../../apiConfig.json"
import "./additionPage.css"
import getImageForEquipmentType from "../../shared/functions/getImageForEquipmentType.js"
// import Filter from './Filter';

const AdditionUserPage = ({ accessToken }) => {
  const [activeColumn, setActiveColumn] = useState(0)
  const totalColumns = 3
  // const [filterColor, setFilterColor] = useState(null);
  const [isFilterButtonClicked, setIsFilterButtonClicked] = useState(false)
  const [categoryOpen, setCategoryOpen] = useState(false)
  const [category, setCategory] = useState("")
  // const [filterLogic, setFilterLogic] = useState('OR');
  // const [filtersList, setFiltersList] = useState([]);
  const [filterValue, setFilterValue] = useState("")
  // const [filteredValue, setFilteredValue] = useState('');
  const [isUserFormOpen, setIsUserFormOpen] = useState(false)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const categoriesWrapperRef = useRef(null)
  const buttonRef = useRef(null)
  const [usersState, setUsersState] = useState({
    loading: false,
    users: null
  })
  const [filters, setFilters] = useState({
    ФИО: [],
    Направление: [],
    Участок: [],
    Должность: [],
    Транспорт: []
  })
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const colorsUsers = {
    ФИО: "#E5A162",
    Должность: "#716BC5",
    Транспорт: "#F0D164",
    Направление: "#558FA1",
    Участок: "#708EC9"
  }

  const handlePreviousColumn = () => {
    if (activeColumn > 0) {
      setActiveColumn(activeColumn - 1)
    }
  }

  const handleNextColumn = () => {
    if (activeColumn < totalColumns - 1) {
      setActiveColumn(activeColumn + 1)
    }
  }

  // console.log(activeColumn)

  const handleButtonClick = () => {
    setIsUserFormOpen(!isUserFormOpen)
    setIsFormVisible(false)
  }

  const handleFiltersButtonClick = () => {
    setIsFilterButtonClicked(!isFilterButtonClicked)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        categoriesWrapperRef.current &&
        !categoriesWrapperRef.current.contains(event.target) &&
        event.target !== buttonRef.current
      ) {
        setIsFilterButtonClicked(false)
      }
    }

    document.body.addEventListener("click", handleClickOutside)

    return () => {
      document.body.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const openInput = (category) => {
    setCategory(category)
    setCategoryOpen(true)
    setIsFilterButtonClicked(false)
  }

  const handleInputChange = (event) => {
    setFilterValue(event.target.value)
  }

  const handleApplyFilter = () => {
    console.log(filterValue)
    setFilters((prevFilters) => ({
      ...prevFilters,
      [category]: [...prevFilters[category], filterValue]
    }))
    console.log(filters)
    setFilterValue("")
    setCategoryOpen(false)
  }

  // useEffect(() => {
  //   const usersData = filteredUsersData();
  // }, [filters]);

  const handleFilterDelete = (category, valueToRemove) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [category]: prevFilters[category].filter(
        (value) => value !== valueToRemove
      )
    }))
  }

  const handleTransitionEnd = () => {
    if (isUserFormOpen) {
      setIsFormVisible(true)
    }
  }

  const fetchUsersData = useCallback(() => {
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_USERS}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.json()
      })
      .then((data) => {
        // console.log(data)
        setUsersState({
          loading: false,
          users: data
        })
      })
      .catch((error) => {
        console.error("Error fetching Excel file:", error)
        setUsersState({
          loading: false,
          users: null
        })
      })
  }, [accessToken])

  useEffect(() => {
    fetchUsersData()
  }, [fetchUsersData])

  // const getImageForEquipmentType = (equipmentType) => {
  //   switch (equipmentType) {
  //     case 'EXCAVATOR':
  //       return require('../../Images/Equipment/excavator_32.png');
  //     case 'BULLDOZER':
  //       return require('../../Images/Equipment/bulldozer_32.png');
  //     case 'CRANE':
  //       return require('../../Images/Equipment/crane_32.png');
  //     case 'GRADER':
  //       return require('../../Images/Equipment/grader_32.png');
  //     case 'RINC':
  //       return require('../../Images/Equipment/rinc_32.png');
  //     case 'RINK':
  //       return require('../../Images/Equipment/rink_32.png');
  //     case 'TRUCK':
  //       return require('../../Images/Equipment/truck_32.png');
  //     case 'POOKER':
  //       return require('../../Images/Equipment/camera_10.png');
  //     case 'AUTO':
  //       return require('../../Images/Equipment/auto_32.png');
  //     case 'REFUELER':
  //       return require('../../Images/Equipment/refueler_512.png');
  //     case 'BUS':
  //       return require('../../Images/Equipment/bus_512.png');
  //     default:
  //       return require('../../Images/Equipment/excavator_32.png');
  //   }
  // };

  const getUserValue = (user, category) => {
    switch (category) {
      case "ФИО":
        return user.fullName || ""
      case "Должность":
        return user.roles ? user.roles.map((role) => role).join(", ") : ""
      case "Транспорт":
        return user.specializations
          ? user.specializations.map((spec) => spec).join(", ")
          : ""
      case "Направление":
        return user.directionName || ""
      case "Участок":
        return user.placeName || ""
      default:
        return ""
    }
  }

  const filteredUsersData = () => {
    if (!usersState.users) return []

    return usersState.users.filter((user) => {
      return Object.keys(filters).every((category) => {
        if (filters[category].length === 0) return true

        const userValue = getUserValue(user, category).toLowerCase()

        return filters[category].some((filterValue) =>
          userValue.includes(filterValue.toLowerCase())
        )
      })
    })
  }

  const usersData = filteredUsersData()

  return (
    <>
      <div className="body-wrapper">
        <div className={isMobile ? "body-wrapper-mobile" : ""}>
          <div
            className={
              isMobile
                ? "page-flex-wrapper page-flex-wrapper-mobile"
                : "page-flex-wrapper"
            }
          >
            <NavigationMenu
              currentPage="/addition-user"
              accessToken={accessToken}
            />
            <main
              className={
                isMobile
                  ? "addition-main-index addition-main-index-mobile"
                  : "addition-main-index"
              }
            >
              <section className="addition-container">
                <section className="addition-inner-container">
                  <div
                    className={
                      isMobile
                        ? "addition-inner-header-wrapper addition-inner-header-wrapper-mobile"
                        : "addition-inner-header-wrapper"
                    }
                  >
                    <h1>Сотрудники</h1>
                    <div className="switcher-container">
                      <SwitcherButtons currentPage="users" />
                    </div>
                    {/* <button className="goto-faq-btn" style={{top: '0.2rem', right: '-3rem' }} >
                  <Link
                  onClick={() => onSetCategory('addition')}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 'auto',
                    color: 'black',
                    textDecoration: 'none',
                    width: '35px',
                    height: '35px',
                    borderRadius:'30px'
                    }}
                    to="/faq"
                    state={{ category: "addition" }}
                    >
                    ?
                  </Link>
                </button> */}
                  </div>
                  <div
                    className={`addition-wrapper addition-users-wrapper ${
                      isUserFormOpen ? "collapsed" : ""
                    }`}
                  >
                    <TableHead
                      cells={[
                        "ФИО",
                        "Направление/Участок",
                        "Должность",
                        "Оборудование"
                      ]}
                      page="users"
                      activeColumn={activeColumn}
                    />
                    <div
                      className={
                        isMobile
                          ? "addition-wrapper__button-wrapper"
                          : "inactive"
                      }
                    >
                      <button
                        className="addition-wrapper__button-prev"
                        onClick={handlePreviousColumn}
                      ></button>
                      <button
                        className="addition-wrapper__button-next"
                        onClick={handleNextColumn}
                      ></button>
                    </div>
                    <div className="filter-container">
                      <button
                        className="filter-container__button"
                        ref={buttonRef}
                        onClick={handleFiltersButtonClick}
                      ></button>
                      {isFilterButtonClicked && (
                        <div
                          ref={categoriesWrapperRef}
                          className="filter-container__categories-wrapper categories"
                        >
                          <ul className="categories__list">
                            <li className="categories__items">
                              <button onClick={() => openInput("ФИО")}>
                                ФИО
                              </button>
                            </li>
                            <li className="categories__items">
                              <button onClick={() => openInput("Направление")}>
                                Направление
                              </button>
                            </li>
                            <li className="categories__items">
                              <button onClick={() => openInput("Участок")}>
                                Участок
                              </button>
                            </li>
                            <li className="categories__items">
                              <button onClick={() => openInput("Должность")}>
                                Должность
                              </button>
                            </li>
                            <li className="categories__items">
                              <button onClick={() => openInput("Транспорт")}>
                                Транспорт
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}
                      {categoryOpen && category !== "Транспорт" && (
                        <div className="filter-component-wrapper">
                          <div
                            className={
                              isMobile
                                ? "filter-category-wrapper filter-category-wrapper-mobile"
                                : "filter-category-wrapper"
                            }
                            style={{ backgroundColor: colorsUsers[category] }}
                          >
                            <button
                              className="filter-category-wrapper__close-button"
                              type="button"
                              onClick={() => setCategoryOpen(false)}
                            ></button>
                            <input
                              className="filter-category-wrapper__input"
                              placeholder={`Введите ${category}`}
                              value={filterValue}
                              onChange={handleInputChange}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  handleApplyFilter()
                                }
                              }}
                            />
                            <button
                              className="filter-category-wrapper__button"
                              onClick={() => handleApplyFilter()}
                            >
                              Применить
                            </button>
                          </div>
                        </div>
                      )}
                      {categoryOpen && category === "Транспорт" && (
                        <div className="filter-component-wrapper">
                          <div
                            className="filter-category-wrapper"
                            style={{ backgroundColor: colorsUsers[category] }}
                          >
                            <div className="filter-category-wrapper__type-list">
                              <button
                                type="button"
                                className="button-apply-equipment technic-type-white-excavator"
                                onClick={() => setFilterValue("EXCAVATOR")}
                              ></button>
                              <button
                                type="button"
                                className="button-apply-equipment technic-type-white-bulldozer"
                                onClick={() => setFilterValue("BULLDOZER")}
                              ></button>
                              <button
                                type="button"
                                className="button-apply-equipment technic-type-white-crane"
                                onClick={() => setFilterValue("CRANE")}
                              ></button>
                              <button
                                type="button"
                                className="button-apply-equipment technic-type-white-grader"
                                onClick={() => setFilterValue("GRADER")}
                              ></button>
                              <button
                                type="button"
                                className="button-apply-equipment technic-type-white-rink"
                                onClick={() => setFilterValue("RINK")}
                              ></button>
                              <button
                                type="button"
                                className="button-apply-equipment technic-type-white-truck"
                                onClick={() => setFilterValue("TRUCK")}
                              ></button>
                              <button
                                type="button"
                                className="button-apply-equipment technic-type-white-auto"
                                onClick={() => setFilterValue("AUTO")}
                              ></button>
                              <button
                                type="button"
                                className="button-apply-equipment technic-type-white-pooker"
                                onClick={() => setFilterValue("POOKER")}
                              ></button>
                              <button
                                type="button"
                                className="button-apply-equipment technic-type-white-refueler"
                                onClick={() => setFilterValue("REFUELER")}
                              ></button>
                              <button
                                type="button"
                                className="button-apply-equipment technic-type-white-bus"
                                onClick={() => setFilterValue("BUS")}
                              ></button>
                              <button
                                type="button"
                                className="button-apply-equipment technic-type-white-tractor"
                                onClick={() => setFilterValue("TRACTOR")}
                              ></button>
                              <button
                                type="button"
                                className="button-apply-equipment technic-type-white-mixer"
                                onClick={() => setFilterValue("MIXER")}
                              ></button>
                              <button
                                type="button"
                                className="button-apply-equipment technic-type-white-mule"
                                onClick={() => setFilterValue("MULE")}
                              ></button>
                            </div>
                            <button
                              className="filter-category-wrapper__button"
                              onClick={() => handleApplyFilter()}
                            >
                              Применить
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="filter-container__applied-filter-wrapper">
                        {Object.keys(filters).map(
                          (category) =>
                            filters[category].length > 0 && (
                              <div
                                key={category}
                                className="filter-container__applied-filter-category"
                              >
                                {filters[category].map((value, index) => (
                                  <div
                                    className="filter-container__applied-filter"
                                    key={index}
                                    style={{
                                      backgroundColor: colorsUsers[category]
                                    }}
                                  >
                                    {category === "Транспорт" && (
                                      <img
                                        src={getImageForEquipmentType(value)}
                                        alt={value}
                                        width={25}
                                        height={25}
                                      />
                                    )}
                                    {category !== "Транспорт" && value}
                                    <button
                                      className="filter-container__button-delete-filter"
                                      type="button"
                                      onClick={() =>
                                        handleFilterDelete(category, value)
                                      }
                                    ></button>
                                  </div>
                                ))}
                              </div>
                            )
                        )}
                      </div>
                    </div>
                    <div className="table-row-wrapper">
                      {Array.isArray(usersData) && usersData.length > 0 ? (
                        usersData
                          .filter((user) => user !== null)
                          .sort((a, b) => {
                            if (a.fullName && b.fullName) {
                              return a.fullName.localeCompare(b.fullName)
                            }
                            return 0
                          })
                          .map((user, index) => {
                            const roles = user.roles.map((role) => role)
                            // const equipment = user.specializations.map(spec => spec.type);
                            const imei = user.imei
                            // console.log(user.specializations)
                            return (
                              <TableRow
                                key={index}
                                data={[
                                  `${user.fullName || "-"}`,
                                  `${user.directionName || "-"}/${
                                    user.placeName || "-"
                                  }`,
                                  roles.join(", "),
                                  user.specializations,
                                  user.username,
                                  user.id
                                ]}
                                page="users"
                                user={user.username}
                                imei={imei}
                                activeColumn={activeColumn}
                                accessToken={accessToken}
                                onFormSubmit={fetchUsersData}
                              />
                            )
                          })
                      ) : (
                        <div className="loading-data-block">
                          <p>Users data loading</p>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </section>
              <button
                className={`button button-add ${isUserFormOpen ? "open" : ""}`}
                onClick={handleButtonClick}
                onTransitionEnd={handleTransitionEnd}
              >
                +
              </button>

              {isFormVisible && (
                <>
                  <button
                    className="button-close-form"
                    onClick={handleButtonClick}
                  ></button>
                  <UserForm
                    onFormSubmit={fetchUsersData}
                    accessToken={accessToken}
                    onClose={handleButtonClick}
                  />
                </>
              )}
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
export default AdditionUserPage
