import { useEffect, useState, useRef } from "react";
import { createPortal } from 'react-dom';
// import TaskForm from "../TaskForm.jsx";
import { NewTaskForm } from "@entities/task/index";
import "../taskTracker.css";

  // const PeriodSwitcher = ({accessToken, onChangeDates, qualifier, qualifierId, qualifierName }) => {
  const PeriodSwitcher = ({ accessToken, onChangeDates, projectId, projectName }) => {
  const [isTaskFormOpen, setIsTaskFormOpen] = useState(false);
// console.log(qualifier, qualifierId, qualifierName)
  const placeModalElem = document.querySelector('.body-wrapper');

  const handleButtonClick = () => {
    setIsTaskFormOpen(!isTaskFormOpen);
  };

  const initialStartDate = new Date();
  const initialEndDate = new Date();
  
  useEffect (() => {
    initialEndDate.setDate(initialStartDate.getDate() + 7);
  }, []);

const [startDate, setStartDate] = useState(initialStartDate);
const [endDate, setEndDate] = useState(initialEndDate);
const [dateRange, setDateRange] = useState('');
const [errorVisible, setErrorVisible] = useState(false);
const errorMessageRef = useRef(null);


useEffect(() => {
  if (errorVisible) {
    const timer = setTimeout(() => {
      setErrorVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }
}, [errorVisible]);

function resetTime(date) {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

function calculateDateRange(date1, setDateFunc, date2, isStart) {
  const resetDate1 = resetTime(date1)
  const resetDate2 = resetTime(date2)
  if(resetDate2 < resetDate1) {
    setErrorVisible(true);
    if(isStart === false) 
      { setDateFunc(resetDate1); 
        resetDate2 = resetDate1;
      }
    else if(isStart === true)
      { setDateFunc(resetDate2); 
        resetDate1 = resetDate2;
      }
    return;
  } else {
    const msPerDay = 24 * 60 * 60 * 1000;
    return Math.abs(Math.round((resetDate2 - resetDate1) / msPerDay));
  }
}

function addDays(date, days) {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
}

function subtractDays(date, days) {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() - days);
  return newDate;
}

function parseDate(dateString) {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
}

function convertToInputDate(date) {
  if (!date) return '';
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const handleDateChange = (e, setDateFunc, isStart) => {
  const newDate = parseDate(e.target.value);
  setDateFunc(newDate);
  if (isStart) {
    setDateRange(calculateDateRange(newDate, setDateFunc, endDate, isStart));
  } else if (!isStart) {
    setDateRange(calculateDateRange(startDate, setDateFunc, newDate, isStart));
  }
};

const handlePrevious = () => {
  setDateRange(calculateDateRange(startDate, setStartDate, endDate, true));
  const newStartDate = subtractDays(startDate, dateRange);
  const newEndDate = subtractDays(endDate, dateRange);
  setStartDate(newStartDate);
  setEndDate(newEndDate);
  onChangeDates(newStartDate, newEndDate);
};

const handleNext = () => {
  setDateRange(calculateDateRange(startDate, setEndDate, endDate, false));
  const newStartDate = addDays(startDate, dateRange);
  const newEndDate = addDays(endDate, dateRange);
  setStartDate(newStartDate);
  setEndDate(newEndDate);
  onChangeDates(newStartDate, newEndDate);
};

return (
  <div className="switcher__container">
    <div className="switcher__switcher">
      
        <div className="switcher-interval__wrapper">
          <button className="goto-button prev-week__goto-button"
          onClick={handlePrevious}
          ></button>

          <label>
            <input className="switcher-interval__date switcher-interval__start"
            type="date"
            name="calendar-start"
            id="start date"
            value={convertToInputDate(startDate)}
            onChange={(e) => handleDateChange(e, setStartDate, true)}  
            />
          </label>

          <span className="switcher-interval-dash"></span>
          <label>

          <input className="switcher-interval__date
          switcher-interval__end"
          type="date"
          name="calendar-end"
          value={convertToInputDate(endDate)}
          onChange={(e) => handleDateChange(e, setEndDate, false)}  
          />
          </label>

          <button className="goto-button next-week__goto-button"
          onClick={handleNext}
          ></button>

        </div>
      <div className={`switcher-error-message__popup ${errorVisible ? 'visible' : ''}`} ref={errorMessageRef} >Конечная дата не может быть меньше начальной!</div>
    </div>
    <button className={`switcher__new-task__button ${isTaskFormOpen ? 'open' : ''}`} onClick={handleButtonClick}>
      Новая задача
    </button>
          {/* <NewTaskForm formStatus={`${isTaskFormOpen ? 'open' : ''}`} accessToken={accessToken}/> */}
      {isTaskFormOpen &&
      createPortal(
        <NewTaskForm
          accessToken={accessToken}
          onClose={handleButtonClick}
          initialProjectId={projectId}
          initialProjectName={projectName}
          // qualifier={qualifier}
          // qualifierId={qualifierId}
          // qualifierName={qualifierName}
        />,
        placeModalElem
      )
      }
  </div>
)
};

export default PeriodSwitcher;