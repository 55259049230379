import config from "../../../apiConfig.json"

export const updateProject = async (props) => {
  const { accessToken, id, name, start, end, description, direction, status } =
    props
  // console.log(props)
  const formData = {
    id: id,
    name: name,
    start: start,
    end: end,
    description: description,
    direction_name: direction.name,
    status: status
  }

  // шаблон данных из сваггера
  //  {
  //   "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //   "name": "string",
  //   "start": "2025-02-07T16:41:32.551Z",
  //   "end": "2025-02-07T16:41:32.551Z",
  //   "description": "string",
  //   "position": [
  //     {
  //       "x": 0,
  //       "y": 0
  //     }
  //   ],
  //   "direction_name": "string",
  //   "status": "PLANNED"
  // }

  console.log(
    "данные перед отправкой: ",
    formData,
    "данные перед отправкой в формате json: ",
    JSON.stringify(formData)
  )
  const response = await fetch(
    `${config.BASE_URL}${config.CRM_ENDPOINTS.UPDATE_PROJECT}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${config.DEFAULT_TOKEN}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    }
  )
  console.log(response)
  return {
    ok: response.ok,
    status: response.status,
    statusText: response.statusText
  }
}
