import { SwitcherBetweenPages } from "@widgets/SwitcherBetweenPages/index"
import { SearchField } from "@widgets/search-field/ui/SearchField"
import { NavigationMenu } from "@features/navigation-menu"
import "./_documentation-page.scss"
import "./_chapter.scss"
import { useState, useCallback, useEffect } from "react"
import { ProjectProvider } from "../../../entities/project/model/projectModel"
import { ProjectInfo } from "../../../entities/project/ui/projectInfo"
import { useSelector } from "react-redux"

import { useSearchParams } from "react-router-dom"
import apiConfig from "../../../apiConfig.json"
import ModalDelete from "../../../Components/ModalDelete"

const DocumentationPage = ({ accessToken }) => {
  // const project = useSelector((state) => state.project.currentProject)

  const [searchParams] = useSearchParams();
  const [trackerObjectId, setTrackerObjectId] = useState('');
  const [projectName, setProjectName] = useState('');

  const [editingMode, setEditingMode] = useState(false);
  const [deleteChapterModalOpen, setDeleteChapterModalOpen] = useState(false);
  const [deleteSectionModalOpen, setDeleteSectionModalOpen] = useState(false);
  const [deleteTaskModalOpen, setDeleteTaskModalOpen] = useState(false);
  const [currentChapter, setCurrentChapter] = useState('');
  const [currentChapterId, setCurrentChapterId] = useState('');
  const [currentSection, setCurrentSection] = useState('');
  const [currentSectionId, setCurrentSectionId] = useState('');
  const [currentTask, setCurrentTask] = useState('');
  const [currentTaskId, setCurrentTaskId] = useState('');
  const [projectsExpanded, setProjectsExpanded] = useState(false);
  const [projectState, setProjectState] = useState({
    loading: false,
    projectInfo: null
  })
  const [allProjectState, setAllProjectState] = useState({
    loading: true,
    allProjectInfo: { trackerObjects: [] },
  });
  const [currentProjectId, setCurrentProjectId] = useState('');
  const [addChapterInputOpen, setAddChapterInputOpen] = useState(false);
  const [newChapterName, setNewChapterName] = useState('');
  const [newSectionName, setNewSectionName] = useState('');
  const [additionalInfoMode, setAdditionalInfoMode] = useState(false);
  const [additionalInfoInputOpen, setAdditionalInfoInputOpen] = useState(false);
  const [additionalInfoExisting, setAdditionalInfoExisting] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState({});
  const [newHeader, setNewHeader] = useState("");

  useEffect(() => {
    if (projectState.projectInfo?.tasks?.length > 0) {
      setAdditionalInfo(projectState.projectInfo.tasks[0].additionalInfo || {});
    } else {
      setAdditionalInfo({});
    }
  }, [projectState]);

  const initialTaskState = {
      start: "2025-01-16T16:42:12.502Z",
      end: "2025-01-16T16:42:12.502Z",
      qualifier: "TRACKER_OBJECT",
      qualifierId: `${currentProjectId}`,
      header: "",
      workNumber: "",
      description: "",
      estimatedVolume: 0,
      monthlyVolume: 0,
      volumeMetrics: "",
      metricCost: 0,
      totalCost: 0,
      equipments: [
        0
      ],
      performers: [
        "string"
      ],
      evaluators: [
        "string"
      ],
      additionalInfo: additionalInfo
  };
  const [newTask, setNewTask] = useState(initialTaskState);
  const [addSectionInputOpen, setAddSectionInputOpen] = useState(null);
  const [addTaskOpen, setAddTaskOpen] = useState(null);
  const [editedData, setEditedData] = useState({
    chapters: [],
    sections: [],
    tasks: []
  })

  const TABLEHEADER = [
    "Дата начала",
    "Дата конца",
    "Наименование работ",
    "Номер работ из реестра",
    "Описание работы",
    "Полный объем",
    "Выполненный объем",
    // 'Проверенный объем',
    "Ед. изм.",
    "Стоимость единицы",
    "Общая стоимость"
    // 'Оборудование'
  ]

  useEffect(() => {
    // const { id } = location
    const id = searchParams.get("projId")

    setCurrentProjectId(id)
    setProjectName(searchParams.get("projName"))

    // if (id) {
    // const projectId = localStorage.getItem("projectId") || ''
    // fetchProject(id)
    // }
    // fetchProject()
  }, [searchParams])

  const onProjectsExpanded = () => {
    setProjectsExpanded(!projectsExpanded)
  }

  const handleProjectSelect = (project) => {
    setCurrentProjectId(project.id)
    setProjectName(project.name)
    refreshProject(project.id);
    setProjectsExpanded(false);
    setAdditionalInfoMode(false);
    setAdditionalInfoInputOpen(false);
  };

  const onAddChapterClick = () => {
    setAddChapterInputOpen(true)
  }

  const handleOpenDeleteChapterModal = (currentChapter, currentChapterId) => {
    setCurrentChapterId(currentChapterId)
    setCurrentChapter(currentChapter)
    setDeleteChapterModalOpen(!deleteChapterModalOpen)
  }

  const handleOpenDeleteSectionModal = (currentSection, currentSectionId) => {
    setCurrentSectionId(currentSectionId)
    setCurrentSection(currentSection)
    setDeleteSectionModalOpen(!deleteSectionModalOpen)
  }

  const handleOpenDeleteTaskModal = (currentTask, currentTaskId) => {
    setCurrentTaskId(currentTaskId)
    setCurrentTask(currentTask)
    setDeleteTaskModalOpen(!deleteTaskModalOpen)
  }

  const handleEditingMode = () => {
    setEditingMode(!editingMode)
  }

  const handleAdditionalInfoMode = () => {
    setAdditionalInfoMode(!additionalInfoMode)
  }

  const onAddAdditionalInfoClick = () => {
    setAdditionalInfoInputOpen(true);
  }

  const saveAddAdditionalInfo = () => {
    handleSaveChanges();
    refreshProject(currentProjectId);
    setAdditionalInfoInputOpen(false);
  }

  const handleValueChange = (key, value) => {
    setAdditionalInfo((prev) => ({ ...prev, [key]: value }));
  };

  const updateChapter = (id, changes) => {
    setEditedData((prevState) => ({
      ...prevState,
      chapters: prevState.chapters.map((chapter) =>
        chapter.id === id ? { ...chapter, ...changes } : chapter
      )
    }))
  }

  const updateSection = (id, changes) => {
    setEditedData((prevState) => ({
      ...prevState,
      sections: prevState.sections.map((section) =>
        section.id === id ? { ...section, ...changes } : section
      )
    }))
  }

  const updateTask = (id, changes) => {
    setEditedData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.map((task) =>
        task.id === id ? { ...task, ...changes } : task
      )
    }))
  }

  const updateTasks = (newAdditionalInfo) => {
    setEditedData((prevState) => ({
      ...prevState,
      tasks: prevState.tasks.map((task) => ({
        ...task,
        additionalInfo: additionalInfo,
      })),
    }));
  };


  const handleAddChapter = async (chapterName) => {
    if (chapterName.length === 0) {
      return
    }

    setNewChapterName("")
    setAddChapterInputOpen(false)

    try {
      const response = await fetch(
        `${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.POST_CHAPTER}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ name: chapterName, trackerObjectId: currentProjectId }),
        });

      if (!response.ok) {
        throw new Error("Failed to add chapter")
      }

      const responseText = await response.text()

      let newChapter
      try {
        newChapter = JSON.parse(responseText)
      } catch (parseError) {
        throw new Error("Failed to parse JSON response")
      }

      setProjectState((prevState) => ({
        ...prevState,
        projectInfo: {
          ...prevState.projectInfo,
          chapters: [...prevState.projectInfo.chapters, newChapter]
        }
      }))

      setNewChapterName("")
      setAddChapterInputOpen(false)
    } catch (error) {
      console.error("Error adding chapter:", error)
    }
    refreshProject(currentProjectId)
  }

  const handleAddSection = async (chapterIndex, sectionName) => {
    setAddSectionInputOpen(null)
    setNewSectionName("")

    try {
      const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.POST_SECTION}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: sectionName, chapterId: chapterIndex }),
      });

      if (!response.ok) {
        throw new Error("Failed to add section")
      }

      const responseText = await response.text()

      let newChapter
      try {
        newChapter = JSON.parse(responseText)
      } catch (parseError) {
        throw new Error("Failed to parse JSON response")
      }

      setProjectState((prevState) => ({
        ...prevState,
        projectInfo: {
          ...prevState.projectInfo,
          chapters: [...prevState.projectInfo.chapters, newChapter]
        }
      }))

      setNewChapterName("")
      setAddChapterInputOpen(false)
    } catch (error) {
      console.error("Error adding chapter:", error)
    }
    refreshProject(currentProjectId)
  }

  const handleAddTaskToSection = async (chapterIndex, sectionIndex) => {
    setAddTaskOpen(null)
    setNewTask(initialTaskState)
    const taskData = {
      ...newTask,
      qualifier: "SECTION",
      qualifierId: `${sectionIndex}`,
    };
    // console.log(JSON.stringify(taskData))
    try {
      const response = await fetch(
        `${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.POST_TASK}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(taskData)
        }
      )

      if (!response.ok) {
        throw new Error("Failed to add task")
      }

      const contentType = response.headers.get("Content-Type")

      let responseData
      if (contentType && contentType.includes("application/json")) {
        responseData = await response.json()
      } else {
        responseData = await response.text()
      }

      if (typeof responseData === 'object') {
        const createdTask = responseData;
        setProjectState((prevState) => {
          const updatedChapters = [...prevState.projectInfo.chapters]
          updatedChapters[chapterIndex].sections[sectionIndex].tasks = [
            ...updatedChapters[chapterIndex].sections[sectionIndex].tasks,
            createdTask
          ]

          return {
            ...prevState,
            projectInfo: {
              ...prevState.projectInfo,
              chapters: updatedChapters
            }
          }
        })
      } else {
        console.warn("Non-JSON response:", responseData)
      }
    } catch (error) {
      console.error("Error adding task to section:", error)
    }
    refreshProject(currentProjectId)
  }

  const handleAddTaskToChapter = async(chapterIndex) => {
    setAddTaskOpen(null);
    setNewTask(initialTaskState);
    const taskData = {
      ...newTask,
      qualifier: "CHAPTER",
      qualifierId: `${chapterIndex}`,
    };

    try {
      const response = await fetch(
        `${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.POST_TASK}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(taskData)
        }
      )

      if (!response.ok) {
        throw new Error("Failed to add task")
      }

      const contentType = response.headers.get("Content-Type")
      let createdTask

      if (contentType && contentType.includes("application/json")) {
        createdTask = await response.json()
      } else {
        const responseText = await response.text()
        console.warn("Non-JSON response:", responseText)
        createdTask = { message: responseText }
      }

      setProjectState((prevState) => {
        const updatedChapters = [...prevState.projectInfo.chapters]

        if (!updatedChapters[chapterIndex]) {
          updatedChapters[chapterIndex] = { tasks: [] }
        } else if (!updatedChapters[chapterIndex].tasks) {
          updatedChapters[chapterIndex].tasks = []
        }

        updatedChapters[chapterIndex].tasks = [
          ...updatedChapters[chapterIndex].tasks,
          createdTask
        ]

        return {
          ...prevState,
          projectInfo: {
            ...prevState.projectInfo,
            chapters: updatedChapters
          }
        }
      })
      setNewTask(initialTaskState)
      setAddTaskOpen(null)
    } catch (error) {
      console.error("Error adding task to chapter:", error)
    }
    refreshProject(currentProjectId)
  }

  useEffect(() => {
    fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_ALL_PROJECTS}`, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE",
        "Content-Type": "application/json"
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      return response.json()
    })
    .then(data => {
      // console.log(data)
      setAllProjectState({
        loading: false,
        allProjectInfo: data,
      });
    })
    .catch(error => {
      console.error('Error fetchinge:', error);
      setAllProjectState({
        loading: false,
        allProjectInfo: { trackerObjects: [] },
      });
    });
  }, []);

  // console.log(currentProjectId)
  useEffect(() => {
    if(!currentProjectId) return;
    fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_PROJECT}?id=${currentProjectId}`, {
      headers:{
        'Authorization' : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE',
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // console.log(data)
      setProjectState({
          loading: false,
          projectInfo: data
        })
      })
      .catch((error) => {
        console.error("Error fetching:", error)
        setProjectState({
          loading: false,
          projectInfo: null
        })
      });
  }, [currentProjectId]);

  const refreshProject = useCallback((trackerObjectId) => {
    // console.log(projectId)
    fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_PROJECT}?id=${trackerObjectId}`, {
      method: 'GET',
      headers:{
        'Authorization' : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE',
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      // console.log(data)
      setProjectState({
          loading: false,
          projectInfo: data
        })
      })
      .catch((error) => {
        console.error("Error fetchinge:", error)
        setProjectState({
          loading: false,
          projectInfo: null
        })
      })
  }, [])

  useEffect(() => {
    if (currentProjectId) {
      refreshProject(currentProjectId)
    }
  }, [currentProjectId])

  const handleDeleteChapter = (chapterIndex) => {
    fetch(
      `${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.DELETE_CHAPTER}${chapterIndex}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
          "Content-Type": "application/json"
        }
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log("chapter was deleted successfully!")
          setDeleteChapterModalOpen(false)
          refreshProject(currentProjectId)
        }
      })
      .catch((error) => {
        console.log("error deleting user: ", error)
      })
  }

  const handleDeleteSection = (sectionIndex) => {
    fetch(
      `${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.DELETE_SECTION}${sectionIndex}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
          "Content-Type": "application/json"
        }
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log("section was deleted successfully!")
          setDeleteSectionModalOpen(false)
          refreshProject(currentProjectId)
        }
      })
      .catch((error) => {
        console.log("error deleting user: ", error)
      })
  }

  const handleDeleteTask = (taskIndex) => {
    fetch(
      `${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.DELETE_TASK}${taskIndex}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
          "Content-Type": "application/json"
        }
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log("task was deleted successfully!")
          setDeleteTaskModalOpen(false)
          refreshProject(currentProjectId)
        }
      })
      .catch((error) => {
        console.log("error deleting user: ", error)
      })
  }

  useEffect(() => {
    if (editingMode) {
      setEditedData({
        chapters: projectState.projectInfo.chapters || [],
        sections: projectState.projectInfo.sections || [],
        tasks: projectState.projectInfo.tasks || [],
      });
    }
    console.log("new data",editedData)
  }, [editingMode]);

  useEffect(() => {
    if (additionalInfoMode && projectState.projectInfo !== null) {
      setEditedData({
        chapters: projectState.projectInfo.chapters || [],
        sections: projectState.projectInfo.sections || [],
        tasks: projectState.projectInfo.tasks?.length > 0 ? projectState.projectInfo.tasks : [],
      });
    }
    console.log("Обновленные данные:", editedData);
  }, [additionalInfoMode]);


  useEffect(() => {
    setEditedData((prevData) => ({
      ...prevData,
      tasks: prevData.tasks.map((task) => ({
        ...task,
        additionalInfo: additionalInfo,
      })),
    }));
    console.log(additionalInfo)
    // console.log("Обновленные данные:", editedData);
  }, [additionalInfo]);

  const handleAddAdditionalInfo = () => {
    if (newHeader.trim() && !additionalInfo[newHeader]) {
      const updatedInfo = { ...additionalInfo, [newHeader]: "" };

      setAdditionalInfo(updatedInfo);
      // setEditedData((prevData) => ({
      //   ...prevData,
      //   tasks: prevData.tasks.map((task) => ({
      //     ...task,
      //     additionalInfo: updatedInfo,
      //   })),
      // }));

      setNewHeader("");
      setAdditionalInfoExisting(true);
      // console.log(editedData)
    }
  };
  console.log("new data",editedData)

  const handleSaveChanges = async () => {
    const requestBody = {
      trackerObject: {
        id: currentProjectId,
        projectId: projectState.projectInfo.trackerObject.projectId,
        name: projectName,
        start: projectState.projectInfo.trackerObject.start,
        end: projectState.projectInfo.trackerObject.end,
        description: projectState.projectInfo.trackerObject.description,
        position: [],
        directionName: projectState.projectInfo.trackerObject.direction_name,
        status: projectState.projectInfo.trackerObject.status
      },
      chapters: editedData.chapters,
      sections: editedData.sections,
      tasks: editedData.tasks,
    };
    console.log(requestBody)
    setEditingMode(false)
    try {
      const response = await fetch(
        `${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.UPDATE_PROJECT}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`
          },
          body: JSON.stringify(requestBody)
        }
      )

      if (response.ok) {
        console.log("Изменения сохранены!")
        setEditingMode(false)
        refreshProject(currentProjectId)
      }
    } catch (error) {
      console.error("Ошибка сохранения:", error)
    }
  }

  return (
    <>
      {deleteChapterModalOpen && (
        <ModalDelete
          message={`Удалить ${currentChapter}?`}
          actionText="Удалить"
          action={() => handleDeleteChapter(currentChapterId)}
          onClose={handleOpenDeleteChapterModal}
        />
      )}
      {deleteSectionModalOpen && (
        <ModalDelete
          message={`Удалить ${currentSection}?`}
          actionText="Удалить"
          action={() => handleDeleteSection(currentSectionId)}
          onClose={handleOpenDeleteSectionModal}
        />
      )}
      {deleteTaskModalOpen && (
        <ModalDelete
          message={`Удалить ${currentTask}?`}
          actionText="Удалить"
          action={() => handleDeleteTask(currentTaskId)}
          onClose={handleOpenDeleteTaskModal}
        />
      )}
      <div className="body-wrapper">
        <div className="body-wrapper--flex">
          <NavigationMenu
            currentPage="documentation"
            accessToken={accessToken}
          />
          <main className="addition-main-index">
            <div className="documentation page-container--dark-bg">
              <div className="documentation__header">
                <h1 className="documentation__title">Документация</h1>

                <div className="documentation__projects-list-container">
                  <div
                    className="documentation__list-header documentation__list-header--projects"
                    onClick={onProjectsExpanded}
                  >
                    <p>
                      {currentProjectId ? `${projectName}` : "Выберите объект"}
                    </p>
                    {/* <p>Амган - Шугара</p> */}
                  </div>
                  {projectsExpanded && (
                    <div className="documentation__projects-list">
                      {allProjectState.allProjectInfo?.trackerObjects?.filter((project) => project.status !== "DELETED")
                      .map((project) => {
                        console.log(project)
                        return (
                        <div
                          key={project.id}
                          className="documentation__projects-item"
                          onClick={() => handleProjectSelect(project)}
                        >
                          {project.name}
                        </div>
                        )
                      })}
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="documentation__nav-section">
                <SwitcherBetweenPages
                  currentPage="ГПР"
                  firstPage="ГПР"
                  secondPage="КС-6"
                  firstLink="/documentation"
                  secondLink="/documentation-samples"
                  faq="false"
                />
                <SearchField />
                <div className="documentation__button-wrapper">
                  <button className="documentation__button button button--primary">
                    Экспорт
                  </button>
                  <button className="documentation__button button button--primary">
                    Импорт
                  </button>
                </div>
              </div>
              <div className="documentation__table-container">
                <div className="documentation__table-header-container">
                  {
                    additionalInfoMode ? (
                      <>
                        <button className="documentation__button button button--tertiary" onClick={() => handleAdditionalInfoMode()}>Основная. информация</button>
                          {
                            additionalInfoInputOpen ? (
                              <div className="documentation__table-header-additional documentation__table-header-additional-list">
                                  {
                                    Object.entries(additionalInfo).sort().map(([key, value]) => (
                                      <p className="documentation__table-header-additional-item">{key}</p>
                                  ))}
                                  <input
                                  className="documentation__table-header-additional-input"
                                  type="text"
                                  placeholder="Введите название"
                                  value={newHeader}
                                  onChange={(e) => setNewHeader(e.target.value)}
                                />
                                <button className="documentation__button button button--secondary button--add" onClick={() => handleAddAdditionalInfo()}></button>
                                <button className="documentation__button documentation__button--tick button button--secondary button--tick" onClick={() => saveAddAdditionalInfo()}></button>
                              </div>
                            ) : (
                              <div className="documentation__table-header-additional">
                                {
                                  Object.keys(additionalInfo).length !== 0 ? (
                                    <>
                                      {Object.entries(additionalInfo).map(([key, value]) => (
                                        <p className="documentation__table-header-additional-item">{key}</p>
                                      ))}
                                      <button className="documentation__button documentation__button--edit button button--secondary button--edit" onClick={() => onAddAdditionalInfoClick()}></button>
                                    </>
                                  ) : projectState.projectInfo && projectState.projectInfo.tasks.length > 0 &&
                                      projectState.projectInfo.tasks.some(task => Object.keys(task.additionalInfo || {}).length > 0) ? (
                                      <>
                                    {
                                      Object.entries(projectState.projectInfo.tasks[0]?.additionalInfo || {}).map(([key]) => (
                                        <p className="documentation__table-header-additional-item" key={`${key}`}>{key}</p>
                                      ))
                                    }
                                    <button className="documentation__button documentation__button--edit button button--secondary button--edit" onClick={() => onAddAdditionalInfoClick()}></button>
                                  </>
                                  ) : (
                                    <>
                                      <p className="documentation__table-header-additional-item">Нет информации</p>
                                      <button className="documentation__button button button--secondary" onClick={() => onAddAdditionalInfoClick()}>Заполнить</button>
                                    </>
                                  )
                                }
                              </div>
                            )
                          }
                      </>
                    ) : (
                      <>
                        <ul className="documentation__table-header-list">
                          {
                            TABLEHEADER.map((thead, index) => {
                              return(
                                <li key={index} className="documentation__table-header-item">
                                  {thead}
                                </li>
                              )
                            })
                          }
                        </ul>
                        <button className="documentation__button button button--primary" onClick={() => handleAdditionalInfoMode()}>Дополнит. информация</button>
                      </>
                    )
                  }
                </div>
                {/* <ProjectProvider accessToken={accessToken}>
                  <ProjectInfo/>
                </ProjectProvider> */}
                <div className="documentation__table-body-container">
                  {projectState.projectInfo?.chapters && (
                    <>
                      <div className="documentation__chapter chapter">
                        <ul className="chapter__list">
                          {projectState.projectInfo.chapters.map(
                            (chapter, chapterIndex) => (
                              <li className="chapter__item" key={chapter.id}>
                                {editingMode ? (
                                  <div className="chapter__input-wrapper-editing">
                                    <input
                                      className="chapter__input-name"
                                      type="text"
                                      placeholder={chapter.name}
                                      onChange={(e) =>
                                        updateChapter(chapter.id, {
                                          name: e.target.value
                                        })
                                      }
                                    />
                                  </div>
                                ) : (
                                  <h2 className="chapter__title">
                                    {chapter.name}
                                  </h2>
                                )
                                }
                                {editingMode ? (
                                  <button
                                    className="chapter__button chapter__button--edit button button--secondary button--return"
                                    onClick={handleEditingMode}
                                  ></button>
                                ) : (
                                  <button className="chapter__button chapter__button--edit button button--secondary button--edit" onClick={handleEditingMode}></button>
                                )
                              }
                              <button className="chapter__button chapter__button--delete button button--secondary button--delete" onClick={() => handleOpenDeleteChapterModal(chapter.name, chapter.id)}></button>
                              <ul className="chapter__task-list task-list">
                                {projectState.projectInfo?.tasks && projectState.projectInfo.tasks
                                 .filter((task) => task.qualifier === 'CHAPTER' && task.qualifierId === chapter.id)
                                 .map((task, taskIndex) => {
                                  return (
                                    <>
                                      {
                                        editingMode ? (
                                          <div className="section__input-wrapper section__input-wrapper--task">
                                            <input
                                              type="datetime-local"
                                              placeholder={task.start}
                                              onChange={(e) => updateTask(task.id, { start: e.target.value })}
                                            />
                                            <input
                                              type="datetime-local"
                                              placeholder={task.end}
                                              onChange={(e) => updateTask(task.id, { end: e.target.value })}
                                            />
                                            <input
                                              type="text"
                                              placeholder={task.header}
                                              onChange={(e) => updateTask(task.id, { header: e.target.value })}
                                            />
                                            <input
                                              type="text"
                                              placeholder={task.workNumber}
                                              onChange={(e) => updateTask(task.id, { workNumber: e.target.value })}
                                            />
                                            <input
                                              type="text"
                                              placeholder={task.description}
                                              onChange={(e) => updateTask(task.id, { description: e.target.value })}
                                            />
                                            <input
                                              type="text"
                                              placeholder={task.estimatedVolume}
                                              onChange={(e) => updateTask(task.id, { estimatedVolume: e.target.value })}
                                            />
                                            <input
                                              type="text"
                                              placeholder={task.monthlyVolume}
                                              onChange={(e) => updateTask(task.id, { monthlyVolume: e.target.value })}
                                            />
                                            <input
                                              type="text"
                                              placeholder={task.volumeMetrics}
                                              onChange={(e) => updateTask(task.id, { volumeMetrics: e.target.value })}
                                            />
                                            <input
                                              type="text"
                                              placeholder={task.metricCost}
                                              onChange={(e) => updateTask(task.id, { metricCost: e.target.value })}
                                            />
                                            <input
                                              type="text"
                                              placeholder={task.totalCost}
                                              onChange={(e) => updateTask(task.id, { totalCost: e.target.value })}
                                            />
                                            <button className="button button--transparent button--close" onClick={() => handleOpenDeleteTaskModal(task.header, task.id)} style={{marginLeft: "auto"}}></button>
                                          </div>
                                        ) : (
                                            additionalInfoMode ? (
                                              <div className="task-list__item task-list__item-additional-info" key={task.id}>
                                                {
                                                  projectState.projectInfo && projectState.projectInfo.tasks.length > 0 &&
                                                  <>
                                                    {
                                                      Object.keys(additionalInfo).map((key, index) => {
                                                        console.log(key)
                                                        return (
                                                          <input
                                                            key={index}
                                                            type="text"
                                                            placeholder={'Введите данные'}
                                                            onChange={(e) => updateTask(task.id, { totalCost: e.target.value })}
                                                          />
                                                        )
                                                      })
                                                    }
                                                  </>
                                                }
                                              </div>
                                            ) : (
                                              <li className="task-list__item" key={task.id}>
                                                <p>{task.start}</p>
                                                <p>{task.end}</p>
                                                <p>{task.header}</p>
                                                <p>{task.workNumber}</p>
                                                <p>{task.description}</p>
                                                <p>{task.estimatedVolume}</p>
                                                <p>{task.monthlyVolume}</p>
                                                <p>{task.volumeMetrics}</p>
                                                <p>{task.metricCost}</p>
                                                <p>{task.totalCost}</p>
                                              </li>
                                            )
                                        )
                                      }
                                    </>
                                  )
                                 })
                                }
                              </ul>
                              {addTaskOpen?.chapterIndex === chapterIndex && addTaskOpen?.sectionIndex === null && (
                                <div className="chapter__input-wrapper chapter__input-wrapper--task">
                                  <input
                                    type="datetime-local"
                                    placeholder="Дата начала"
                                    value={newTask.start}
                                    onChange={(e) => setNewTask({ ...newTask, start: e.target.value })}
                                  />
                                  <input
                                    type="datetime-local"
                                    placeholder="Введите данные"
                                    value={newTask.end}
                                    onChange={(e) => setNewTask({ ...newTask, end: e.target.value })}
                                  />
                                  <input
                                    placeholder="Введите данные"
                                    value={newTask.header}
                                    onChange={(e) => setNewTask({ ...newTask, header: e.target.value })}
                                  />
                                  <input
                                    placeholder="Введите данные"
                                    value={newTask.workNumber}
                                    onChange={(e) => setNewTask({ ...newTask, workNumber: e.target.value })}
                                  />
                                  <input
                                    placeholder="Введите данные"
                                    value={newTask.description}
                                    onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                                  />
                                  <input
                                  // className="chapter__input--float"
                                    placeholder="Введите данные"
                                    type="number"
                                    value={newTask.estimatedVolume}
                                    onChange={(e) => setNewTask({ ...newTask, estimatedVolume: parseFloat(e.target.value) })}
                                  />
                                  <input
                                  // className="chapter__input--float"
                                    placeholder="Введите данные"
                                    type="number"
                                    value={newTask.monthlyVolume}
                                    onChange={(e) => setNewTask({ ...newTask, monthlyVolume: parseFloat(e.target.value) })}
                                  />
                                  <input
                                    placeholder="Введите данные"
                                    value={newTask.volumeMetrics}
                                    onChange={(e) => setNewTask({ ...newTask, volumeMetrics: e.target.value })}
                                  />
                                  <input
                                  // className="chapter__input--float"
                                    placeholder="Введите данные"
                                    type="number"
                                    value={newTask.metricCost}
                                    onChange={(e) => setNewTask({ ...newTask, metricCost: parseFloat(e.target.value) })}
                                  />
                                  <input
                                  // className="chapter__input--float"
                                    placeholder="Введите данные"
                                    type="number"
                                    value={newTask.totalCost}
                                    onChange={(e) => setNewTask({ ...newTask, totalCost: parseFloat(e.target.value) })}
                                  />
                                  <button className="chapter__button button button--secondary button--add" onClick={() => handleAddTaskToChapter(chapter.id)}></button>
                                  <button className="chapter__button button button--transparent button--close" onClick={() => setAddTaskOpen(null)}></button>
                                </div>
                              )}
                              <div className="documentation__section section">
                              {
                                projectState.projectInfo?.sections && (
                                  <ul className="section__list">
                                    {projectState.projectInfo.sections
                                    .filter(section => section.chapterId === chapter.id)
                                    .map((section, sectionIndex) => (
                                      <>
                                        <li className="section__item" key={sectionIndex}>
                                          <div className="section__header">
                                          {
                                            editingMode ? (
                                              <div className="section__input-wrapper-editing">
                                                    <input
                                                      className="section__input-name"
                                                      type="text"
                                                      placeholder={section.name}
                                                      onChange={(e) =>
                                                        updateSection(
                                                          section.id,
                                                          {
                                                            name: e.target.value
                                                          }
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                ) : (
                                                  <h3>{section.name}</h3>
                                                )}
                                                {editingMode && (
                                                  <button
                                                    className="button button--secondary button--delete"
                                                    onClick={() =>
                                                      handleOpenDeleteSectionModal(
                                                        section.name,
                                                        section.id
                                                      )
                                                    }
                                                    style={{
                                                      marginLeft: "auto"
                                                    }}
                                                  ></button>
                                                )}
                                          </div>
                                          <div className="section__button-wrapper">
                                            <button className="chapter__button button button--secondary button--add" onClick={() => setAddTaskOpen({chapterIndex, sectionIndex })}></button>
                                            <p>Добавить работу в раздел</p>
                                          </div>
                                          <ul className="section__task-list task-list">
                                            {projectState.projectInfo.tasks && projectState.projectInfo.tasks
                                            .filter((task) => task.qualifier === 'SECTION' && task.qualifierId === section.id)
                                            .map((task, taskIndex) => (
                                              <>
                                                {
                                                  editingMode ? (
                                                    <div className="section__input-wrapper section__input-wrapper--task">
                                                      <input
                                                        type="datetime-local"
                                                        placeholder={task.start}
                                                        onChange={(e) => updateTask(task.id, { start: e.target.value })}
                                                      />
                                                      <input
                                                        type="datetime-local"
                                                        placeholder={task.end}
                                                        onChange={(e) => updateTask(task.id, { end: e.target.value })}
                                                      />
                                                      <input
                                                        type="text"
                                                        placeholder={task.header}
                                                        onChange={(e) => updateTask(task.id, { header: e.target.value })}
                                                      />
                                                      <input
                                                        type="text"
                                                        placeholder={task.workNumber}
                                                        onChange={(e) => updateTask(task.id, { workNumber: e.target.value })}
                                                      />
                                                      <input
                                                        type="text"
                                                        placeholder={task.description}
                                                        onChange={(e) => updateTask(task.id, { description: e.target.value })}
                                                      />
                                                      <input
                                                        type="text"
                                                        placeholder={task.estimatedVolume}
                                                        onChange={(e) => updateTask(task.id, { estimatedVolume: e.target.value })}
                                                      />
                                                      <input
                                                        type="text"
                                                        placeholder={task.monthlyVolume}
                                                        onChange={(e) => updateTask(task.id, { monthlyVolume: e.target.value })}
                                                      />
                                                      <input
                                                        type="text"
                                                        placeholder={task.volumeMetrics}
                                                        onChange={(e) => updateTask(task.id, { volumeMetrics: e.target.value })}
                                                      />
                                                      <input
                                                        type="text"
                                                        placeholder={task.metricCost}
                                                        onChange={(e) => updateTask(task.id, { metricCost: e.target.value })}
                                                      />
                                                      <input
                                                        type="text"
                                                        placeholder={task.totalCost}
                                                        onChange={(e) => updateTask(task.id, { totalCost: e.target.value })}
                                                      />
                                                      <button className="button button--transparent button--close" onClick={() => handleOpenDeleteTaskModal(task.header, task.id)} style={{marginLeft: "auto"}}></button>
                                                    </div>
                                                  ) : (
                                                    additionalInfoMode ? (
                                                      <div className="task-list__item task-list__item-additional-info" key={task.id}>
                                                         {
                                                            projectState.projectInfo && projectState.projectInfo.tasks.length > 0 &&
                                                            <>
                                                              {
                                                                Object.keys(additionalInfo).map((key, index) => {
                                                                  return (
                                                                    <input
                                                                      key={index}
                                                                      type="text"
                                                                      placeholder={'Введите данные'}
                                                                      onChange={(e) => updateTask(task.id, { totalCost: e.target.value })}
                                                                    />
                                                                  )
                                                                })
                                                              }
                                                            </>
                                                          }
                                                      </div>
                                                    ) : (
                                                      <li className="task-list__item" key={task.id}>
                                                        <p>{task.start}</p>
                                                        <p>{task.end}</p>
                                                        <p>{task.header}</p>
                                                        <p>{task.workNumber}</p>
                                                        <p>{task.description}</p>
                                                        <p>{task.estimatedVolume}</p>
                                                        <p>{task.monthlyVolume}</p>
                                                        <p>{task.volumeMetrics}</p>
                                                        <p>{task.metricCost}</p>
                                                        <p>{task.totalCost}</p>
                                                      </li>
                                                    )
                                                  )
                                                }
                                              </>
                                            ))}
                                          </ul>
                                          {addTaskOpen?.chapterIndex === chapterIndex && addTaskOpen?.sectionIndex === sectionIndex && (
                                            <div className="section__input-wrapper section__input-wrapper--task">
                                              <input
                                                type="datetime-local"
                                                placeholder="Дата начала"
                                                value={newTask.start}
                                                onChange={(e) => setNewTask({ ...newTask, start: e.target.value })}
                                              />
                                              <input
                                                type="datetime-local"
                                                placeholder="Дата конца"
                                                value={newTask.end}
                                                onChange={(e) => setNewTask({ ...newTask, end: e.target.value })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                value={newTask.header}
                                                onChange={(e) => setNewTask({ ...newTask, header: e.target.value })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                value={newTask.workNumber}
                                                onChange={(e) => setNewTask({ ...newTask, workNumber: e.target.value })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                value={newTask.description}
                                                onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                type="number"
                                                value={newTask.estimatedVolume}
                                                onChange={(e) => setNewTask({ ...newTask, estimatedVolume: parseFloat(e.target.value) })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                type="number"
                                                value={newTask.monthlyVolume}
                                                onChange={(e) => setNewTask({ ...newTask, monthlyVolume: parseFloat(e.target.value) })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                value={newTask.volumeMetrics}
                                                onChange={(e) => setNewTask({ ...newTask, volumeMetrics: e.target.value })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                type="number"
                                                value={newTask.metricCost}
                                                onChange={(e) => setNewTask({ ...newTask, metricCost: parseFloat(e.target.value) })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                type="number"
                                                value={newTask.totalCost}
                                                onChange={(e) => setNewTask({ ...newTask, totalCost: parseFloat(e.target.value) })}
                                              />
                                              <button className="chapter__button button button--secondary button--add" onClick={() => handleAddTaskToSection(chapter.id, section.id)}></button>
                                              <button className="chapter__button button button--transparent button--close" onClick={() => setAddTaskOpen(null)}></button>
                                            </div>
                                          )}
                                        </li>
                                      </>
                                    ))}
                                  </ul>
                                )
                              }
                              {
                                addSectionInputOpen === chapterIndex && (
                                  <div className="documentation__section section section--add">
                                    <div className="section__input-wrapper">
                                    <input
                                      className="section__input-name"
                                      placeholder="Введите название раздела"
                                      value={newSectionName}
                                      onChange={(e) => setNewSectionName(e.target.value)}
                                    />
                                    <button className="section__button button button--secondary button--add" onClick={() => handleAddSection(chapter.id, newSectionName)}></button>
                                    <button className="section__button button button--transparent button--close" onClick={() => setAddSectionInputOpen(null)}></button>
                                  </div>
                                </div>
                              )}
                              <div className="chapter__button-wrapper">
                                <button
                                  className="chapter__button button button--secondary button--add"
                                  onClick={() =>
                                    setAddTaskOpen({
                                      chapterIndex,
                                      sectionIndex: null
                                    })
                                  }
                                ></button>
                                <p>Добавить работу в главу</p>
                              </div>
                              <div className="chapter__button-wrapper">
                                <button
                                  className="chapter__button button button--secondary button--add-transparent"
                                  onClick={() =>
                                    setAddSectionInputOpen(chapterIndex)
                                  }
                                ></button>
                                <p>ДОБАВИТЬ РАЗДЕЛ</p>
                              </div>
                            </div>
                          </li>
                            )
                          )}
                        </ul>
                        {addChapterInputOpen && (
                          <>
                            <div className="documentation__chapter chapter--add">
                              <div className="chapter__input-wrapper">
                                <input
                                  className="chapter__input-name"
                                  placeholder="Введите название главы"
                                  value={newChapterName}
                                  onChange={(e) =>
                                    setNewChapterName(e.target.value)
                                  }
                                />
                                <button
                                  className="chapter__button button button--secondary button--add"
                                  onClick={() =>
                                    handleAddChapter(newChapterName)
                                  }
                                ></button>
                                <button
                                  className="chapter__button button button--transparent button--close"
                                  onClick={() => setAddChapterInputOpen(false)}
                                ></button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {editingMode ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <button
                    className="documentation__button documentation__button--add-chapter button button--primary"
                    onClick={onAddChapterClick}
                  >
                    Добавить главу
                  </button>
                  <button
                    className="documentation__button documentation__button--add-chapter button button--primary"
                    onClick={handleSaveChanges}
                  >
                    Сохранить
                  </button>
                </div>
              ) : (
                <button
                  className="documentation__button documentation__button--add-chapter button button--primary"
                  onClick={onAddChapterClick}
                >
                  Добавить главу
                </button>
              )}
            </div>
          </main>
        </div>
      </div>
      </>
    )
  }
export default DocumentationPage
