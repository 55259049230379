import { useState, useCallback, useEffect } from "react"
import { NavigationMenu } from "@features/navigation-menu"
import TrackerHeader from "./MainPage/Header.jsx"
import { TasksContainer } from "./MainPage/TasksContainer.jsx"
import PeriodSwitcher from "./MainPage/PeriodSwitcher.jsx"
import ModalDelete from "../../Components/ModalDelete"
import { statuses } from "@entities/task/index.js"
import { getProject } from "@entities/project/index.js"
import { useSearchParams } from "react-router-dom"

const Tracker = ({ accessToken }) => {
  // const { qualifier, qualifierId, start, end, status } = useTaskFields()
  const [searchParams] = useSearchParams()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000)
  const [projectId, setProjectId] = useState("")
  const [projectName, setProjectName] = useState("")
  const [projectInfo, setProjectInfo] = useState({})
  // const [qualifierId, setQualifierId] = useState('')
  // const [qualifier, setQualifier] = useState('')
  // const [qualifierName, setQualifierName] = useState('')

  const fetchProject = useCallback(async (id) => {
    try {
      const projectInfo = await getProject(id)
      console.log(projectInfo)
      if (projectInfo) {
        setProjectId(id)
        setProjectName(projectInfo.project.name)
        setProjectInfo(projectInfo)

        setStartDate(projectInfo.start)
        setEndDate(projectInfo.end)

        // setQualifier("PROJECT")
        // setQualifierId(id)
        // setQualifierName(projectInfo.name)
      }
    } catch (error) {
      console.error("Ошибка при получении проектов:", error)
      setProjectInfo({})
    }
  }, [])

  useEffect(() => {
    const id = searchParams.get("projId")
    // console.log('как дела? ', id)
    // const name = searchParams.get("projName")
    if (id && id !== null && id !== undefined) {
      fetchProject(id)
    }

    // if (!name && name !== null && name !== undefined) {
    //   setProjectName(name)
    // }

    // console.log(id)
    // if (id) {
    // const projectId = localStorage.getItem("projectId") || ''
    // }
    // fetchProject()
  }, [fetchProject, searchParams])

  // console.log(qualifier, qualifierId, qualifierName)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const sortTasksByStatuses = (status) => {
    let tasks = []
    if (Object.values(projectInfo).length > 0) {
      projectInfo.tasks.forEach((task) => {
        if (task.status === status) {
          tasks.push(task)
        }
      })
    }
    return tasks
  }

  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleChangeDate = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  console.log(projectName)

  return (
    <>
      <div className="body-wrapper tracker-body-wrapper">
        <div
          className={
            isMobile
              ? "page-flex-wrapper page-flex-wrapper-mobile"
              : "page-flex-wrapper"
          }
        >
          <NavigationMenu
            currentPage="/task-tracker"
            accessToken={accessToken}
          />
          <main className="tracker-main__wrapper">
            <TrackerHeader
              projectId={projectId}
              projectName={projectName}
              startDate={startDate}
              endDate={endDate}
            />
            <PeriodSwitcher
              accessToken={accessToken}
              projectId={projectId}
              projectName={projectName}
              // projectInfo={projectInfo}
              // qualifier = {qualifier}
              // qualifierId = {qualifierId}
              // qualifierName={qualifierName}
              onChangeDates={handleChangeDate}
            />
            <div className="tracker-main-tasks-cards__container">
              {Object.values(statuses).map((item, index) => (
                <TasksContainer
                  key={index}
                  projectInfo={projectInfo}
                  typeOfTasks={item}
                  projectId={projectId}
                  // qualifier = {qualifier}
                  // qualifierId = {qualifierId}
                  // qualifierName={qualifierName}
                  // onOpen={handleOpenModal}
                  // startDate={startDate}
                  // endDate={startDate}
                  accessToken={accessToken}
                  tasks={sortTasksByStatuses(item)}
                />
              ))}
            </div>
          </main>
          {isModalOpen && (
            <ModalDelete
              message="Удалить направление?"
              action="Удалить"
              onClose={handleCloseModal}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default Tracker
