import { useState } from "react"

export const useProjectFields = (
  initId = "",
  initName = "",
  initStart = "",
  initEnd = "",
  initDescription = "",
  initDirection = "",
  initStatus = "",
  initPosition = {}
) => {
  const [id, setId] = useState(initId)
  const [name, setName] = useState(initName)
  const [start, setStart] = useState(initStart)
  const [end, setEnd] = useState(initEnd)
  const [description, setDescription] = useState(initDescription)
  const [direction, setDirection] = useState({
    name: initDirection,
    type: "direction"
  })
  const [status, setStatus] = useState(initStatus)
  const [position, setPosition] = useState(initPosition)

  return {
    id: { value: id, set: setId },
    name: { value: name, set: setName },
    start: { value: start, set: setStart },
    end: { value: end, set: setEnd },
    description: { value: description, set: setDescription },
    position: { value: position, set: setPosition },
    direction: { value: direction, set: setDirection },
    status: { value: status, set: setStatus }
  }
}
