import { useState, useEffect } from "react"
import { CloseBtn } from "@widgets/buttons/index"
import { checkEpmtyValues, checkEmptyValue } from "@features/forms/index"

import { postNewProject } from "../api/post-new-project"
import { useProjectFields } from "../index"
import { formatToLocalDateTime } from "@features/formatters/index"
import { DistrictsList } from "@entities/districts/index"
import { handleEscape } from "@shared"

export const NewProjectForm = (props) => {
  const { onClose, refreshData, setProjectCreationStatus, accessToken } = props
  const { name, start, end, description, direction } = useProjectFields()

  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const checkIfEmpty = (value, field) => {
    let checkResult
    if (typeof value !== "object" && !Array.isArray(value)) {
      checkResult = checkEmptyValue(value)
      setErrors((prev) => {
        return {
          ...prev,
          [field]: { text: "Поле не может быть пустым.", state: checkResult }
        }
      })
      return
    }

    checkResult = checkEpmtyValues(value)

    if (Object.keys(value).length > 1) {
      setErrors((prev) => {
        let tmp = { ...prev }

        let newErrorsObj = checkEpmtyValues(Object.keys(tmp))

        for (let result in checkResult) {
          newErrorsObj[result] = checkResult[result]
          if (prev[result] !== result[result]) {
            newErrorsObj[result] = prev[result]
          }
        }
        return newErrorsObj
      })
    }

    if (checkResult.length === 1 && checkResult.state) {
      setErrors((p) => {
        return { ...p, [field]: checkResult }
      })
    }
  }

  const onChangeFieldValue = (value, setter, field = null) => {
    setter(value)
    if (field !== null && field !== undefined) {
      checkIfEmpty(value, field)
    }
  }

  const onSubmit = async () => {
    setIsSubmitting(true)
    setTimeout(
      checkIfEmpty({
        name: name.value,
        start: start.value,
        end: end.value,
        direction: direction.value.name
      }),
      0
    )
  }

  useEffect(() => {
    handleEscape(onClose)
  }, [onClose])

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length > 0) {
        setIsSubmitting(false)
        return
      }

      const formattedStartDate = formatToLocalDateTime(start.value)
      const formattedEndDate = formatToLocalDateTime(end.value)

      const result = postNewProject({
        accessToken,
        name: name.value,
        start: formattedStartDate,
        end: formattedEndDate,
        description: description.value,
        direction: direction.value.name
      })

      if (result.ok) {
        onClose()
        refreshData()
        setProjectCreationStatus({
          message: `Проект ${name.value} успешно создан`,
          hasError: false
        })
        // setIsModalOpened(true);
      } else {
        setProjectCreationStatus({
          message: `Произошла ошибка с кодом ${
            result.status
          } про попытке создания проекта${
            result.statusText && ` :${result.statusText}`
          }`,
          hasError: true
        })
        // setIsModalOpened(true);
      }
    }
  }, [errors])

  useEffect(() => {
    if (direction.value.name) {
      checkIfEmpty(direction.value.name, "direction")
    }
  }, [direction.value])

  return (
    <>
      <div className="new-project-form modal-container">
        <form
          action="POST"
          className="new-project-form-form modal-container__wrapper"
        >
          <CloseBtn
            blackOrWhite="white"
            style={{ position: "absolute", top: "2%", right: "2%" }}
            onClick={onClose}
          />

          <div className="new-project-form-field">
            <label htmlFor="name" className="new-project-form-field__label">
              Название
            </label>
            <input
              className="new-project-form-field__input"
              id="name"
              placeholder="Введите название объекта"
              value={name.value}
              onChange={(e) =>
                onChangeFieldValue(e.target.value, name.set, "name")
              }
            />
            {errors.name && errors.name.state && (
              <span className="form__error-message">{errors.name.text}</span>
            )}
          </div>

          <div className="new-project-form-field">
            <label htmlFor="name" className="new-project-form-field__label">
              Направление
            </label>
            <DistrictsList
              accessToken={accessToken}
              district={direction.value}
              setDistrict={direction.set}
              arePlacesRequired={false}
            />
            {errors.direction && errors.direction.state && (
              <span className="form__error-message">
                {errors.direction.text}
              </span>
            )}
          </div>

          <div className="new-project-form-field new-project-form-dates">
            <label className="new-project-form-field__label">
              Период выполнения
            </label>

            <div className="new-task-form-row-container new-project-form-field_date-wrapper">
              <input
                type="date"
                className="new-project-form-field__input"
                id="date"
                placeholder="Дата начала"
                value={start.value}
                onChange={(e) =>
                  onChangeFieldValue(e.target.value, start.set, "start")
                }
              />
              <input
                type="date"
                className="new-project-form-field__input"
                id="date"
                placeholder="Дата окончания"
                value={end.value}
                onChange={(e) =>
                  onChangeFieldValue(e.target.value, end.set, "end")
                }
              />
            </div>

            <div className="new-task-form-row-container">
              {errors.start && errors.start.state && (
                <span className="form__error-message">{errors.start.text}</span>
              )}
              {errors.end && errors.end.state && (
                <span className="form__error-message">{errors.end.text}</span>
              )}
            </div>
          </div>

          <div className="new-project-form-field new-project-form-extra-info">
            <label className="new-project-form-field__label">
              Дополнительная информация
            </label>
            <textarea
              className="new-project-form-field__textarea new-project-form-extra-info__textarea"
              value={description.value}
              onChange={(e) =>
                onChangeFieldValue(
                  e.target.value,
                  description.value,
                  description.set
                )
              }
              placeholder="Добавьте описание объекта"
            ></textarea>
          </div>

          <div className="new-project-form__button-wrapper">
            <button
              type="submit"
              className="new-project-form__create-project-button"
              onClick={(e) => {
                e.preventDefault()
                onSubmit()
              }}
            >
              Новый объект
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
