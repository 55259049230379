import './shiftInfoModal.css';
import './block.css';
import apiConfig from '../../apiConfig.json'
import { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, Rectangle} from "react-leaflet";
import L from "leaflet";
import stopPointIconUrl from '../../Images/route-stop.svg';
// import flag from '../../Images/flag.png';

const ShiftInfoModal = (props) => {
  const {show, onClose, shift, mainRoute, route, accessToken, isLocalTime = true} = props;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [blockCount, setBlockCount] = useState(0);
  const [showPolygons, setShowPolygons] = useState(false);
  const [showMarkers, setShowMarkers] = useState(true);
  const [showPolylines, setShowPolylines] = useState(true);
  const [mapOpened, setMapOpened] = useState(false);
  const [stopsState, setStopsState] = useState({
    loading: false,
    stopsInfo: null,
  })
  const [stopsSquaresState, setStopsSquaresState] = useState({
    loading: false,
    stopsSquaresInfo: null,
  })
  console.log(shift)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    if (!show) {
      setMapOpened(false);
      setBlockCount(0);
      setShowMarkers(true);
      setShowPolygons(false);
      setShowPolylines(true);
    }
  }, [show]);

  useEffect(() => {
    const route = shift.route;
    if(show && route !== undefined && route !== null && Object.keys(shift.route).length > 2) {
        const stopsUrl = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_STOPS_INFORMATION}`;
        fetch(stopsUrl, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            route
        })
        })
          .then((response) => response.json())
          .then((data) => {
            setStopsState({
              loading: true,
              stopsInfo: data,
            });
          })
          .catch((error) => {
            console.error('Error fetching user data:', error);
            setStopsState({
              loading: false,
              stopsInfo: null,
            });
          });
}},[accessToken, show])

  console.log(stopsState.stopsInfo)


useEffect(() => {
  const route = shift.route;
  if(show) {
  const stopsUrl = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_STOPS_REPORT}`;
  fetch(stopsUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then((data) => {
    setStopsSquaresState({
      loading: true,
      stopsSquaresInfo: data,
    });
  })
  .catch((error) => {
    console.error('Error fetching user data:', error);
    setStopsSquaresState({
      loading: false,
      stopsSquaresInfo: null,
    });
  });
}},[accessToken, show])

  // console.log(stopsState)
  // console.log(mainRoute, route)

  const getImageForEquipmentType = (equipmentType) => {
    switch (equipmentType) {
      case 'EXCAVATOR':
        return require('../../Images/Equipment/excavator_32.png');
      case 'BULLDOZER':
        return require('../../Images/Equipment/bulldozer_32.png');
      case 'CRANE':
        return require('../../Images/Equipment/crane_32.png');
      case 'GRADER':
        return require('../../Images/Equipment/grader_32.png');
      case 'RINC':
        return require('../../Images/Equipment/rinc_32.png');
      case 'RINK':
        return require('../../Images/Equipment/rink_32.png');
      case 'TRUCK':
        return require('../../Images/Equipment/truck_32.png');
      case 'POOKER':
        return require('../../Images/Equipment/camera_10.png');
      case 'AUTO':
        return require('../../Images/Equipment/auto_32.png');
      case 'REFUELER':
        return require('../../Images/Equipment/refueler_512.png');
      default:
        return require('../../Images/Equipment/excavator_32.png');
    }
  };

  const formatTripsLabel = (tripsCount) => {
    const lastDigit = tripsCount % 10;
    const lastTwoDigits = tripsCount % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return `${tripsCount} Рейсов`;
    } else if (lastDigit === 1) {
      return `${tripsCount} Рейс`;
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return `${tripsCount} Рейса`;
    } else {
      return `${tripsCount} Рейсов`;
    }
  }


  const formatDate = (dateString) => {
    console.log('типа правильная дата: ', dateString)
    let date = new Date(dateString);
    console.log ('изначальная дата и точно правильная: ', date)
    console.log(isLocalTime)
    if (isLocalTime) {
      date = new Date(dateString)
      // console.log('зашли в локал тайм y эквипа, вот сброшенный date: ', date)
      // const localTime = new Date();
      date.setHours(date.getHours() + 7)
      // console.log(localTime.getHours(), date.getHours(), 'а вот difference: ', difference)
      // date.setHours(date.getHours() + difference)
      console.log('вот date с новым временем ', date)
      // date = new Date(date + 'Z')
      console.log('вот date типа правильный (и новый): ', date)
    }
    const options = { day: 'numeric', month: 'long', year: 'numeric'};

    let formattedDate = new Intl.DateTimeFormat('ru-RU', options).format(date);
    console.log('formattedDate: ', formattedDate)
    const monthMap = {
        'января': 'Янв',
        'февраля': 'Фев',
        'марта': 'Мар',
        'апреля': 'Апр',
        'мая': 'Май',
        'июня': 'Июн',
        'июля': 'Июл',
        'августа': 'Авг',
        'сентября': 'Сен',
        'октября': 'Окт',
        'ноября': 'Ноя',
        'декабря': 'Дек',
    };

    for (const [full, short] of Object.entries(monthMap)) {
        formattedDate = formattedDate.replace(full, short);
    }

    return formattedDate;
};

// console.log(formatDate('2025-02-05 01:54:07'))

const formatTime = (dateString) => {
    const date = new Date(dateString);
    if (isLocalTime) {
      date.setHours(date.getHours() + 7)
    }
    console.log('date в formatTime и до options: ', date)
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false};
    console.log(Intl.DateTimeFormat('ru-RU', options).format(date))
    return new Intl.DateTimeFormat('ru-RU', options).format(date);
};

const calculateShiftDuration = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const durationInMilliseconds = endDate - startDate;

  const seconds = Math.floor((durationInMilliseconds / 1000) % 60);
  const minutes = Math.floor((durationInMilliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((durationInMilliseconds / (1000 * 60 * 60)));

  return `${hours} ч. ${minutes} мин. ${seconds} сек.`;
};

const handleBlockCountDown = () => {
  if (blockCount >= 0) {
    setBlockCount(blockCount - 1);
  }
}

const handleBlockCountUp = () => {
  if (blockCount <= 2) {
    setBlockCount(blockCount + 1);
    }
}

const cleanWaypoints = (routes) => {
  const uniqueWaypoints = routes.filter((point, index) => {
    return (
      index ===
      routes.findIndex((p) => p[0] === point[0] && p[1] === point[1])
    );
  });
  return uniqueWaypoints.filter(point => {
    return point.length === 2 && !isNaN(point[0]) && !isNaN(point[1]);
  });
};

const processRouteObject = (routeObj) => {
  const waypoints = [];
  for (const key in routeObj) {
    if (routeObj.hasOwnProperty(key)) {
      const value = routeObj[key];
      if (typeof value === "string" && value.includes(",")) {
        const [lat, lng] = value.split(",").map((coordinates) => parseFloat(coordinates.trim()));
        waypoints.push([lat, lng]);
      }
    }
  }
  return cleanWaypoints(waypoints);
};

let routes = [];

if (Array.isArray(route)) {
  routes = route.map((routeObj) => ({
    waypoints: processRouteObject(routeObj),
  }));
} else if (typeof route === "object" && route !== null) {
  routes = [{ waypoints: processRouteObject(route) }];
}

const convertDurationToSeconds = (duration) => {
  const parts = duration.split(':');
  return parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
};

const formatDuration = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

const colors = ["#E5A162", "#664778", "#3D2655", "#568FA2", "#4C5F84"];
const mainRouteWaypoints = processRouteObject(mainRoute);

const calculateBounds = ([lat, lng, rating]) => {
  const squareSize = 0.001;
  const halfSizeLat = squareSize / 2.8600;

  const lngCorrection = Math.cos(lat * Math.PI /  180);
  const halfSizeLng = (squareSize / 1.1215) * lngCorrection;

  return [
    [lat - halfSizeLat, lng - halfSizeLng],
    [lat + halfSizeLat, lng + halfSizeLng],
  ];
};

const getColorByRating = (rating) => {
  if (rating <= 1) return '#F0D07E33';
  if (1 > rating && rating <= 2) return '#F0D07E66';
  if (2 > rating && rating <= 3) return '#F0D07E99';
  if (3 > rating && rating <= 4) return '#F0D07ECC';
  if (4 > rating && rating <= 5) return '#F0D07E';
  return '#F0D07E33';
};

const handleOpenMap = () => {
  setMapOpened(true);
}

const handleCloseMap = () => {
  setMapOpened(false);
}

  const stopPointIcon = new L.icon({
    iconUrl: require('../../Images/marker.png'),
    iconSize: [10, 15],
    iconAnchor: [5, 15],
  });

  const startIcon = new L.icon({
    iconUrl: require('../../shared/assets/geo/start-icon.png'),
    iconSize: [15, 20],
    iconAnchor: [1, 20],
  });

  const finishIcon = new L.icon({
    iconUrl: require('../../shared/assets/geo/finish-icon.png'),
    iconSize: [15, 20],
    iconAnchor: [1, 20],
  });

  if (!show) return null;

  return(
    <>
      <div className="info-modal-container">
        <button className="info-modal-container__button--close" onClick={onClose}></button>
        <div className='info-modal-container__button-wrapper'>
          <button className="info-modal-container__button--prev" onClick={handleBlockCountDown} disabled={blockCount === 0}></button>
          <button className="info-modal-container__button--next" onClick={handleBlockCountUp} disabled={blockCount === 2}></button>
        </div>
        <div className='info-modal-container__blocks-wrapper'>
          {
            mapOpened ? (
              <div className={isMobile ? blockCount === 2 ? 'info-modal-wrapper__block info-modal-wrapper__block-map block' : 'inactive' : 'info-modal-wrapper__block info-modal-wrapper__block-map block'}>
                  <div className="block__controls" style={{ position: 'absolute', bottom: '20px', left: '20px', zIndex: 1000 }}>
                    <label>
                      <input
                        className='visually-hidden control-input'
                        type="checkbox"
                        checked={showPolygons}
                        onChange={() => setShowPolygons(!showPolygons)}
                      />
                      <span className="control-mark"></span>
                      <span className="control-text">Частота остановок</span>
                    </label>
                    <label>
                      <input
                        className='visually-hidden control-input'
                        type="checkbox"
                        checked={showMarkers}
                        onChange={() => setShowMarkers(!showMarkers)}
                      />
                      <span className="control-mark"></span>
                      <span className="control-text">Точки остановок</span>
                    </label>
                    <label>
                      <input
                        className='visually-hidden control-input'
                        type="checkbox"
                        checked={showPolylines}
                        onChange={() => setShowPolylines(!showPolylines)}
                      />
                      <span className="control-mark"></span>
                      <span className="control-text">Рейсы</span>
                    </label>
                  </div>
                  <button
                    className='block__button-map-close'
                    style={{ position: 'absolute', top: '20px', right: '20px', zIndex: 1000 }}
                    onClick={handleCloseMap}></button>
                  <MapContainer center={mainRouteWaypoints[0]} zoom={12} className='shift-leaflet-container'>
                    <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    zoomControl="false"/>
                    {/* <Marker position={[59.888877, 30.482488]} icon={routePointIcon} /> */}
                    {mainRouteWaypoints.length > 0 && (
                    <>
                      {
                        showPolylines && <Polyline key="mainRoute" positions={mainRouteWaypoints} color="grey" weight={1} />
                      }
                      {
                        showPolylines &&
                        <>
                        <Marker position={mainRouteWaypoints[0]} icon={startIcon} />
                        <Marker position={mainRouteWaypoints[mainRouteWaypoints.length - 1]} icon={finishIcon} />
                        </>
                      }
                    </>
                    )}

                    {showPolylines && routes.flatMap((route, index) => {
                      const waypoints = route.waypoints;
                      if(waypoints.length > 0) {
                        return [
                          <Marker key={`${index}-start`} position={waypoints[0]}></Marker>,
                          <Marker key={`${index}-end`} position={waypoints[waypoints.length - 1]}></Marker>
                        ];
                      }
                      return[];
                    })}
                    {
                      showPolylines && <Polyline key={mainRoute} positions={mainRouteWaypoints} color="grey" weight={1}/>
                    }

                    {
                        showMarkers && stopsState.stopsInfo && stopsState.stopsInfo.stops.map((stop, index) => (
                        <Marker key={index} position={[stop.latitude, stop.longitude]} icon={stopPointIcon} />
                      ))
                    }

                    {showPolylines && routes.map((route, index) => (
                      <Polyline key={index} positions={route.waypoints} color={colors[index % colors.length]} weight={1} />
                    ))}

                    {/* <button className="information-container__button-open-map" type="button" onClick={() =>toggleMap([59.5597850, 30.0849539])}></button> */}

                    {
                      stopsSquaresState.loading === true && showPolygons &&
                      stopsSquaresState.stopsSquaresInfo.stop_squares.map((square, index) => {
                      const bounds = calculateBounds(square);
                      const color = getColorByRating(square[2]);
                      return (
                        <Rectangle
                          key={index}
                          bounds={bounds}
                          pathOptions={{ color: 'transparent', fillColor: color, fillOpacity: 0.8 }}
                        />
                    );
                  })}
                  </MapContainer>
                </div>
            ) : (
              <div className='info-modal-container__blocks-inner-wrapper'>
                <div className={isMobile ? blockCount === 0 ? 'info-modal-wrapper__block info-modal-wrapper__block-info-shift block' : 'inactive' : 'info-modal-wrapper__block info-modal-wrapper__block-info-shift block'}>
                  <p className='block__title'>{shift.user.fullName}</p>
                  <ul className='block__info-list list'>
                    <li className='block__info-item'>
                      {
                        shift.trips[0]?.equipment === null ? (
                          <img src={getImageForEquipmentType('EXCAVATOR')} alt='equip' width={45} height={45}/>
                        ) : (
                          <img src={getImageForEquipmentType(shift.trips[0]?.equipment.type.type)} alt='equip' width={45} height={45}/>
                        )
                      }
                    </li>
                    <li className='block__info-item'>
                      <p className='block__info-item-title'>Гараж.<br></br>номер</p>
                      {
                        shift.trips[0]?.equipment === null ? (
                          <p className='block__info-item-value'>000</p>
                        ) : (
                          <p className='block__info-item-value'>{shift.trips[0]?.equipment.warrantyNumber}</p>
                        )
                      }
                    </li>
                    <li className='block__info-item'>
                      <p className='block__info-item-title'>Макс.<br></br>скорость</p>
                      {
                         stopsState.stopsInfo !== null ? (
                          <p className='block__info-item-value'>{stopsState.stopsInfo.max_speed}</p>
                         ) : (
                          <p className='block__info-item-value'>-</p>
                         )
                      }
                    </li>
                    <li className='block__info-item'>
                      <p className='block__info-item-title'>Средняя<br></br>скорость</p>
                      {
                         stopsState.stopsInfo !== null ? (
                          <p className='block__info-item-value'>{stopsState.stopsInfo.avg_speed}</p>
                         ) : (
                          <p className='block__info-item-value'>-</p>
                         )
                      }
                    </li>
                    <li className='block__info-item'>
                      <p className='block__info-item-title'>Дистанция<br></br>(километраж)</p>
                      {
                         stopsState.stopsInfo !== null ? (
                          <p className='block__info-item-value'>{stopsState.stopsInfo.mileage}</p>
                         ) : (
                          <p className='block__info-item-value'>-</p>
                         )
                      }
                    </li>
                  </ul>
                  <div className='block__shift-place-info'>
                    <p>{shift.shiftPlace.name}</p>
                  </div>
                  <div className='block__shift-time-info'>
                    <div className='block__shift-time'>
                      <p className='block__info-item-title'>Начало смены</p>
                      <p>{formatDate(shift.start)}</p>
                      <p>{formatTime(shift.start)}</p>
                    </div>
                    <div className='block__shift-duration-info'>
                      <p className='block__info-item-title'>{calculateShiftDuration(shift.start, shift.end)}</p>
                    </div>
                    <div className='block__shift-time block__shift-time--right'>
                      <p className='block__info-item-title'>Конец смены</p>
                      <p>{formatDate(shift.end)}</p>
                      <p>{formatTime(shift.end)}</p>
                    </div>
                  </div>
                  <div className='block__trips-info'>
                    <p>{formatTripsLabel(shift.trips.length)}</p>
                    <ul className='block__trips-title-list'>
                      <li className='block__trips-title-item block__info-item-title'>
                        <p>Рейс</p>
                      </li>
                      <li className='block__trips-title-item block__info-item-title'>
                        <p>Начало рейса</p>
                      </li>
                      <li className='block__trips-title-item block__info-item-title'>
                        <p>Конец рейса</p>
                      </li>
                      <li className='block__trips-title-item block__info-item-title'>
                        <p>Продолжит. рейса</p>
                      </li>
                    </ul>
                    <div className='block__trips-info-list-wrapper'>
                      {
                        shift.trips.map((trip, index) => {
                          return (
                            <ul key={index} className='block__trips-info-list'>
                              <li className='block__trips-info-item block__info-item-value'>
                                {index + 1}
                              </li>
                              <li className='block__trips-info-item block__info-item-value'>
                                <p>{formatDate(trip.start)}</p>
                                <p>{formatTime(trip.start)}</p>
                              </li>
                              <li className='block__trips-info-item block__info-item-value'>
                                <p>{formatDate(trip.end)}</p>
                                <p>{formatTime(trip.end)}</p>
                              </li>
                              <li className='block__trips-info-item block__info-item-value'>
                                <p>{calculateShiftDuration(trip.start, trip.end)}</p>
                              </li>
                            </ul>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className={isMobile ? blockCount === 1 ? 'info-modal-wrapper__block info-modal-wrapper__block-info-stops block' : 'inactive' : 'info-modal-wrapper__block info-modal-wrapper__block-info-stops block'}>
                  <p className='block__title'>Информация об остановках</p>
                  <ul className='block__stops-title-list'>
                      <li className='block__stops-title-item block__info-item-title'>
                        <p>Дата <br></br> остановки</p>
                      </li>
                      <li className='block__stops-title-item block__info-item-title'>
                        <p>Время <br></br> остановки</p>
                      </li>
                      <li className='block__stops-title-item block__info-item-title'>
                        <p>Продолжит. <br></br> остановки</p>
                      </li>
                      <li className='block__stops-title-item block__info-item-title'>
                        <p>Широта места <br></br> остановки</p>
                      </li>
                      <li className='block__stops-title-item block__info-item-title'>
                        <p>Долгота места <br></br> остановки</p>
                      </li>
                    </ul>
                    <div className='block__stops-info-list-wrapper'>
                      {
                        (stopsState.loading === false && shift.route && Object.keys(shift.route).length > 2) ? (
                          <p className='block__stops-loading'>Данные по остановкам загружаются</p>
                        ) : (shift.route === null || shift.route === undefined || Object.keys(shift.route).length <= 2) ? (
                          <p className='block__stops-not-existing'>Информация об остановках отсутствует</p>
                        ) : (stopsState.loading === true) ? (
                          <>
                            {stopsState.stopsInfo.stops.map((stop, index) => {
                              return (
                                <ul key={index} className='block__stops-info-list'>
                                  <li className='block__stops-info-item block__info-item-value'>
                                    <p>{formatDate(stop.start)}</p>
                                  </li>
                                  <li className='block__stops-info-item block__info-item-value'>
                                    <p>{formatTime(stop.start)}</p>
                                  </li>
                                  <li className='block__stops-info-item block__info-item-value'>
                                    <p>{stop.duration}</p>
                                  </li>
                                  <li className='block__stops-info-item block__info-item-value'>
                                    <p>{stop.latitude}</p>
                                  </li>
                                  <li className='block__stops-info-item block__info-item-value'>
                                    <p>{stop.longitude}</p>
                                  </li>
                                </ul>
                              )
                            })}

                          </>
                        ) : null
                      }
                    </div>
                    {
                    (stopsState.loading === false && shift.route && Object.keys(shift.route).length > 2) ? (
                      <p></p>
                    ) : (shift.route === null || shift.route === undefined || Object.keys(shift.route).length <= 2) ? (
                      <p></p>
                    ) : (stopsState.loading === true) ? (
                      <>
                       <p className='block__stops-duration'>
                         Общее время продолжительности остановок: {formatDuration(stopsState.stopsInfo.stops.reduce((total, stop) => total + convertDurationToSeconds(stop.duration), 0))}
                       </p>

                      </>
                    ) : null
                    }
                </div>
                <div className={isMobile ? blockCount === 2 ? 'info-modal-wrapper__block info-modal-wrapper__block-map block' : 'inactive' : 'info-modal-wrapper__block info-modal-wrapper__block-map block'}>
                  <div className="block__controls" style={{ position: 'absolute', bottom: '20px', left: '20px', zIndex: 1000 }}>
                    <label>
                      <input
                        className='visually-hidden control-input'
                        type="checkbox"
                        checked={showPolygons}
                        onChange={() => setShowPolygons(!showPolygons)}
                      />
                      <span className="control-mark"></span>
                      <span className="control-text">Частота остановок</span>
                    </label>
                    <label>
                      <input
                        className='visually-hidden control-input'
                        type="checkbox"
                        checked={showMarkers}
                        onChange={() => setShowMarkers(!showMarkers)}
                      />
                      <span className="control-mark"></span>
                      <span className="control-text">Точки остановок</span>
                    </label>
                    <label>
                      <input
                        className='visually-hidden control-input'
                        type="checkbox"
                        checked={showPolylines}
                        onChange={() => setShowPolylines(!showPolylines)}
                      />
                      <span className="control-mark"></span>
                      <span className="control-text">Рейсы</span>
                    </label>
                  </div>
                  <button
                    className='block__button-map-open'
                    style={{ position: 'absolute', top: '20px', right: '20px', zIndex: 1000 }}
                    onClick={handleOpenMap}></button>
                  <MapContainer center={mainRouteWaypoints[0]} zoom={12} className='shift-leaflet-container'>
                    <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    zoomControl="false"/>
                    {/* <Marker position={[59.888877, 30.482488]} icon={routePointIcon} /> */}
                    {mainRouteWaypoints.length > 0 && (
                    <>
                      {
                        showPolylines && <Polyline key="mainRoute" positions={mainRouteWaypoints} color="grey" weight={1} />
                      }
                      {
                        showPolylines &&
                        <>
                        <Marker position={mainRouteWaypoints[0]} icon={startIcon} />
                        <Marker position={mainRouteWaypoints[mainRouteWaypoints.length - 1]} icon={finishIcon} />
                        </>
                      }
                    </>
                    )}

                    {showPolylines && routes.flatMap((route, index) => {
                      const waypoints = route.waypoints;
                      if(waypoints.length > 0) {
                        return [
                          <Marker key={`${index}-start`} position={waypoints[0]}></Marker>,
                          <Marker key={`${index}-end`} position={waypoints[waypoints.length - 1]}></Marker>
                        ];
                      }
                      return[];
                    })}
                    {
                      showPolylines && <Polyline key={mainRoute} positions={mainRouteWaypoints} color="grey" weight={1}/>
                    }

                    {
                        showMarkers && stopsState.stopsInfo && stopsState.stopsInfo.stops.map((stop, index) => (
                        <Marker key={index} position={[stop.latitude, stop.longitude]} icon={stopPointIcon} />
                      ))
                    }

                    {showPolylines && routes.map((route, index) => (
                      <Polyline key={index} positions={route.waypoints} color={colors[index % colors.length]} weight={1} />
                    ))}

                    {/* <button className="information-container__button-open-map" type="button" onClick={() =>toggleMap([59.5597850, 30.0849539])}></button> */}

                    {
                      stopsSquaresState.loading === true && showPolygons &&
                      stopsSquaresState.stopsSquaresInfo.stop_squares.map((square, index) => {
                      const bounds = calculateBounds(square);
                      const color = getColorByRating(square[2]);
                      return (
                        <Rectangle
                          key={index}
                          bounds={bounds}
                          pathOptions={{ color: 'transparent', fillColor: color, fillOpacity: 0.8 }}
                        />
                    );
                  })}
                  </MapContainer>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

let DefaultIcon = L.icon({
  iconUrl: stopPointIconUrl,
  iconSize: [10, 15],
  iconAnchor: [5, 15],
  popupAnchor: [1, -12],
});
L.Marker.prototype.options.icon = DefaultIcon;

export default ShiftInfoModal;
