import { Link, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import logo from "../../../Images/Menu/logo.svg"
import logoExpanded from "../../../Images/Menu/logo-expanded.svg"
import LoadApkFile from "../../../Services/LoadApkFile"
import { useDispatch } from "react-redux"
import { logout } from "../../../store/slices/authSlice"
import { MENU_PAGES, COLORS, MENU_LINKS } from "../model/menu-pages"
import { getMenuIconImage } from "../config/get-page-image"
import "../../../Components/navigation.css"

export const NavigationMenu = (props) => {
  const { currentPage = "/objects", accessToken } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)

  const handleLogout = () => {
    localStorage.removeItem("accessToken")
    dispatch(logout())
    navigate("/")
    window.location.reload()
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const getHeading = (heading) => {
    if (isMenuExpanded) {
      return <h3 className="navigation-menu__heading">{heading}</h3>
    }
  }

  const getSubitem = (page, link) => {
    return (
      <li
        className={`navigation-menu__subitem ${currentPage === link && "active-subitem"}`}
      >
        <Link
          className={`navigation-menu__link ${currentPage === link && "active-link"}`}
          to={link}
        >
          {getHeading(page)}
        </Link>
      </li>
    )
  }

  const onHoverItem = (e, page) => {
    const childIcon = e.currentTarget.querySelector(".navigation-menu-icon")
    const hoveredList = e.currentTarget
      .querySelector(".navigation-menu-icon")
      ?.closest(".navigation-menu__item-container")
      ?.querySelector(".navigation-menu__hovered-list")

    if (!e.currentTarget.classList.contains("active-item")) {
      if (isMenuExpanded) {
        e.currentTarget.classList.add("navigation-menu__item--hovered")
      } else {
        if (hoveredList) {
          hoveredList.style.display = "flex"
        }
        e.currentTarget.style.backgroundColor = "#d9d9d9"
      }
      if (childIcon) {
        childIcon.src = getMenuIconImage(page, COLORS.black)
      }
    } else {
      if (!isMenuExpanded) {
        if (hoveredList) {
          hoveredList.style.display = "flex"
        }
      }
    }
  }

  const onLeaveItem = (e, page) => {
    const hoveredList = e.currentTarget
      .closest(".navigation-menu__list")
      ?.querySelector(".navigation-menu__hovered-list")

    if (!e.currentTarget.classList.contains("active-item")) {
      const childIcon = e.currentTarget.querySelector(".navigation-menu-icon")

      if (isMenuExpanded) {
        e.currentTarget.classList.remove("navigation-menu__item--hovered")

        if (childIcon) {
          childIcon.src = getMenuIconImage(page, COLORS.white)
        }
      } else {
        if (hoveredList && hoveredList.style.display !== "flex") {
          e.currentTarget.classList.remove("navigation-menu__item--hovered")
          e.currentTarget.style.backgroundColor = ""
          if (childIcon) {
            childIcon.src = getMenuIconImage(page, COLORS.white)
          }
        }
      }
    } else {
      if (!isMenuExpanded) {
        if (hoveredList && hoveredList.style.display !== "flex") {
          e.currentTarget.classList.remove("navigation-menu__item--hovered")
        }
      }
    }
  }

  const onLeaveHoveredList = (e, page) => {
    const hoveredList = e.currentTarget
    const parentListItem = hoveredList
      .closest(".navigation-menu__item-container")
      .querySelector(".navigation-menu__item")
    const childIcon = parentListItem.querySelector(".navigation-menu-icon")

    hoveredList.style.display = "none"
    parentListItem.style.backgroundColor = ""
    parentListItem.classList.remove("navigation-menu__item--hovered")

    if (childIcon && !parentListItem.classList.contains("active-item")) {
      childIcon.src = getMenuIconImage(page, COLORS.white)
    }
  }

  return (
    <>
      <div
        className={`navigation-container container-flex--column-centering ${isMobile && "navigation-container-mobile"}
        ${isMenuExpanded && "expanded"}
        `}
      >
        <nav className="navigation-menu">
          <Link to="/" className="logo-monstr container-flex--centering">
            {isMenuExpanded ? (
              <img
                src={logoExpanded}
                alt="логотип"
                width={isMobile ? 20 : 175}
                height={isMobile ? 60 : 120}
              />
            ) : (
              <img
                src={logo}
                alt="логотип"
                width={isMobile ? 20 : 40}
                height={isMobile ? 60 : 90}
              />
            )}
          </Link>

          <button
            className={`navigation-menu__toggle-button ${isMenuExpanded && "rotated"}`}
            onClick={() => setIsMenuExpanded((p) => !p)}
          />

          <ul
            className={`navigation-menu__list ${isMenuExpanded && "expanded"}`}
          >
            <div className="navigation-menu__item-container">
              <li
                className={`navigation-menu__item ${(currentPage === MENU_LINKS.objects || currentPage === MENU_LINKS.tracker || currentPage === MENU_LINKS.documentation) && "active-item"}
              ${isMenuExpanded && "expanded-item"}`}
                onMouseEnter={(e) =>
                  onHoverItem(e, MENU_PAGES.objectsManagement)
                }
                onMouseLeave={(e) =>
                  onLeaveItem(e, MENU_PAGES.objectsManagement)
                }
              >
                <img
                  src={
                    currentPage === MENU_LINKS.objects ||
                    currentPage === MENU_LINKS.tracker ||
                    currentPage === MENU_LINKS.documentation
                      ? getMenuIconImage(
                          MENU_PAGES.objectsManagement,
                          COLORS.black
                        )
                      : getMenuIconImage(
                          MENU_PAGES.objectsManagement,
                          COLORS.white
                        )
                  }
                  alt=""
                  className="navigation-menu-icon"
                  width={33}
                  height={33}
                />
                {getHeading(MENU_PAGES.objectsManagement)}
              </li>

              <ul
                className="navigation-menu__hovered-list"
                onMouseLeave={(e) =>
                  onLeaveHoveredList(e, MENU_PAGES.objectsManagement)
                }
              >
                <li className="navigation-menu__hovered-list__item">
                  <Link
                    className="navigation-menu__link"
                    to={MENU_LINKS.objects}
                  >
                    {MENU_PAGES.objects}
                  </Link>
                </li>
                <li className="navigation-menu__hovered-list__item">
                  <Link
                    className="navigation-menu__link"
                    to={MENU_LINKS.tracker}
                  >
                    {MENU_PAGES.tracker}
                  </Link>
                </li>
                <li className="navigation-menu__hovered-list__item">
                  <Link
                    className="navigation-menu__link"
                    to={MENU_LINKS.documentation}
                  >
                    {MENU_PAGES.documentation}
                  </Link>
                </li>
              </ul>
            </div>

            {isMenuExpanded && (
              <li className={`navigation-menu__item-list`}>
                <ul className="navigation-menu__sublist">
                  {getSubitem(MENU_PAGES.objects, MENU_LINKS.objects)}
                  {getSubitem(MENU_PAGES.tracker, MENU_LINKS.tracker)}
                  {getSubitem(
                    MENU_PAGES.documentation,
                    MENU_LINKS.documentation
                  )}
                </ul>
              </li>
            )}

            <div className="navigation-menu__item-container">
              <li
                className={`navigation-menu__item ${(currentPage === MENU_LINKS.shifts || currentPage === MENU_LINKS.staffAndEquipments) && "active-item"}
              ${isMenuExpanded && "expanded-item"}`}
                onMouseEnter={(e) =>
                  onHoverItem(e, MENU_PAGES.resourceManagement)
                }
                onMouseLeave={(e) =>
                  onLeaveItem(e, MENU_PAGES.resourceManagement)
                }
              >
                <img
                  src={
                    currentPage === MENU_LINKS.shifts ||
                    currentPage === MENU_LINKS.staffAndEquipments
                      ? getMenuIconImage(
                          MENU_PAGES.resourceManagement,
                          COLORS.black
                        )
                      : getMenuIconImage(
                          MENU_PAGES.resourceManagement,
                          COLORS.white
                        )
                  }
                  alt=""
                  width={40}
                  height={40}
                  className="navigation-menu-icon"
                />
                {getHeading(MENU_PAGES.resourceManagement)}
              </li>

              <ul
                className="navigation-menu__hovered-list"
                onMouseLeave={(e) =>
                  onLeaveHoveredList(e, MENU_PAGES.resourceManagement)
                }
              >
                <li className="navigation-menu__hovered-list__item">
                  <Link
                    className="navigation-menu__link"
                    to={MENU_LINKS.shifts}
                  >
                    {MENU_PAGES.shifts}
                  </Link>
                </li>
                <li className="navigation-menu__hovered-list__item">
                  <Link
                    className="navigation-menu__link"
                    to={MENU_LINKS.shifts}
                  >
                    {MENU_PAGES.staffAndEquipments}
                  </Link>
                </li>
              </ul>
            </div>

            {isMenuExpanded && (
              <li className="navigatuin-menu__item-list">
                <ul className="navigation-menu__sublist">
                  {getSubitem(MENU_PAGES.shifts, MENU_LINKS.shifts)}
                  {getSubitem(
                    MENU_PAGES.staffAndEquipments,
                    MENU_LINKS.staffAndEquipments
                  )}
                </ul>
              </li>
            )}

            <li
              className={`navigation-menu__item ${isMenuExpanded && "expanded-item"}`}
              onMouseEnter={(e) => onHoverItem(e, MENU_PAGES.downloadAPK)}
              onMouseLeave={(e) => onLeaveItem(e, MENU_PAGES.downloadAPK)}
            >
              <img
                src={getMenuIconImage(MENU_PAGES.downloadAPK, COLORS.white)}
                alt=""
                className="navigation-menu-icon"
                width={30}
                height={30}
              />
              {getHeading(MENU_PAGES.downloadAPK)}
            </li>

            <li
              className={`navigation-menu__item navigation-menu__item-logout ${isMenuExpanded && "expanded-item"}`}
              onMouseEnter={(e) => onHoverItem(e, MENU_PAGES.logout)}
              onMouseLeave={(e) => onLeaveItem(e, MENU_PAGES.logout)}
              onClick={handleLogout}
            >
              <img
                src={getMenuIconImage(MENU_PAGES.logout, COLORS.white)}
                alt=""
                className="navigation-menu-icon"
                width={30}
                height={30}
              />
              {getHeading(MENU_PAGES.logout)}
              {/* <button className="button-logout" onClick={handleLogout} />
              {getHeading(MENU_PAGES.logout)} */}
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}
