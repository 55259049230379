import apiConfig from "../../../apiConfig.json";

export const getProject = async (id) => {
  console.log(id)
  console.log(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_PROJECT}?id=${id}`)
  try {
    const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_PROJECT}?id=${id}`, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${apiConfig.DEFAULT_TOKEN}`,
        'Content-Type': 'application/json',
      }
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
}