import { useState } from "react"
import { statuses } from "../config/statuses"
import { statusesColors } from "../config/statusesColors"
import { ExpandBtn } from "@widgets/buttons/index"

export const StatusesList = (props) => {
  const {
    status = "PLANNED",
    setStatus,
    isBlackAndWhite = false,
    style
  } = props
  const [isListExpanded, setIsListExpanded] = useState(false)

  const onChangeStatus = (newStatus) => {
    setStatus(newStatus)
    setIsListExpanded(false)
  }

  return (
    <div style={style} className="statuses-list">
      <div
        className={`statuses-list__field
        ${isBlackAndWhite && "statuses-list--dark-mode__field"}
        ${
          isBlackAndWhite &&
          isListExpanded &&
          "statuses-list--dark-mode__field_expanded"
        }
        `}
        style={{ backgroundColor: !isBlackAndWhite && statusesColors(status) }}
      >
        <div
          className={`status-icon status-icon_${
            isBlackAndWhite ? "white" : "black"
          }__${status.toLowerCase()}`}
        ></div>
        {/* <img src={getImageForTaskType(status)} alt="" className="detailed-task-header-status__img" /> */}
        <span className="statuses-list__text">{statuses[status]}</span>
        <ExpandBtn
          blackOrWhite={`${isBlackAndWhite ? "white" : "black"}`}
          onClick={() => setIsListExpanded((p) => !p)}
          style={{ width: "2rem" }}
        />
        {/* <button className="expand-button"></button> */}
      </div>

      {isListExpanded && (
        // <>
        <ul
          className={`statuses-list__list ${
            isBlackAndWhite && "statuses-list--dark-mode"
          }`}
        >
          {Object.keys(statuses).map((item) => (
            <div key={item}>
              {status !== item && (
                <li
                  key={item}
                  className="statuses-list__item"
                  onClick={() => onChangeStatus(item)}
                >
                  <div
                    className={`status-icon status-icon_${
                      isBlackAndWhite ? "white" : "black"
                    }__${item.toLowerCase()}`}
                  ></div>
                  <span className="statuses-list__text">{statuses[item]}</span>
                </li>
              )}
            </div>
          ))}
        </ul>
      )}
    </div>
  )
}
