/* eslint-disable react/jsx-pascal-case */
import { useState, useEffect, useCallback, useRef } from "react"
import { checkEpmtyValues, checkEmptyValue } from "@features/forms/index"
import { AddStaffList_New } from "@entities/staff/index"
import { EquipmentList_New } from "../../equipments/ui/EquipmentList_New"
import { useTaskFields } from "../index"
import { CloseBtn } from "@widgets/buttons/index"
import { postTask } from "../index"
import { DropdownList } from "@widgets/dropdown-list"
import { getAllProjects, getProject } from "../../project"
// import { getSection } from "../../section"
import { getChapter } from "../../chapter"
import { formatToLocalDateTime } from "@features/formatters/index"
import { handleEscape } from "@shared"

export const NewTaskForm = (props) => {
  const { accessToken, initialProjectId = "", onClose } = props
  // console.log(initialProjectId.length);
  const {
    name,
    district,
    start,
    end,
    evaluators,
    performers,
    facility,
    // type,
    description,
    equipments,
    volumeMetrics,
    metricCost,
    totalCost,
    workNumber,
    extraInfo,
    qualifier,
    qualifierId,
    planInNumericalTerms,
    planInMonetaryTerms,
    estimatedVolume
  } = useTaskFields()

  const TYPES = {
    object: "PROJECT",
    section: "SECTION",
    chapter: "CHAPTER"
  }

  const [errors, setErrors] = useState({})
  // const [showErrors, setShowErrors] = useState(false);
  const [projectsList, setProjectsList] = useState([])
  const [chaptersList, setChaptersList] = useState([])
  const [sectionsList, setSectionsList] = useState([])

  // const [project, setProject] = useState({})
  const [projectInfo, setProjectInfo] = useState({
    id: initialProjectId,
    name: "",
    data: {}
  })
  const [chapterInfo, setChapterInfo] = useState({
    id: "",
    name: "",
    data: {}
  })
  const [sectionInfo, setSectionInfo] = useState({
    id: "",
    name: "",
    data: {}
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  // console.log(qualifier, qualifierId, qualifierName)
  const prevProjectId = useRef(projectInfo.id)
  const prevChapterId = useRef(chapterInfo.id)
  const prevSectionId = useRef(sectionInfo.id)

  const checkIfEmpty = (value, field) => {
    let checkResult
    if (typeof value !== "object" && !Array.isArray(value)) {
      checkResult = checkEmptyValue(value)
      setErrors((prev) => {
        return {
          ...prev,
          [field]: { text: "Поле не может быть пустым.", state: checkResult }
        }
      })
      return
    }

    checkResult = checkEpmtyValues(value)

    if (Object.keys(value).length > 1) {
      setErrors((prev) => {
        let tmp = { ...prev }

        let newErrorsObj = checkEpmtyValues(Object.keys(tmp))

        for (let result in checkResult) {
          newErrorsObj[result] = checkResult[result]
          if (prev[result] !== result[result]) {
            newErrorsObj[result] = prev[result]
          }
        }
        return newErrorsObj
      })
    }

    if (checkResult.length === 1 && checkResult.state) {
      setErrors((p) => {
        return { ...p, [field]: checkResult }
      })
    }
  }

  const fetchProjects = useCallback(async () => {
    try {
      const allProjects = await getAllProjects(accessToken)
      // console.log(allProjects)
      // console.log(initialProjectId.length > 0)
      setProjectsList(allProjects)
    } catch (error) {
      console.error("Ошибка при получении проектов: ", error)
      setProjectsList([])
    }
  }, [accessToken])

  const fetchProject = useCallback(
    async (id) => {
      // console.log("id: ", id, "уээээ: ", projectInfo);
      // console.log("sectionInfo: ", sectionInfo);
      // console.log("chaptersInfo: ", chapterInfo);
      try {
        const project = await getProject(id, accessToken)
        // console.log(project);
        setProjectInfo({
          id: id,
          name: project.project.name,
          data: project
        })
        // setProjectInfo(p => ({...p, name: project.project.name}))

        setChaptersList(project.chapters)
        setSectionsList(project.sections)
      } catch (error) {
        console.error("Ошибка при получении проектов:", error)
        // setProjectInfo({})
      }
    },
    [accessToken]
  )

  const fetchChapter = useCallback(
    async (id) => {
      // console.log("initialProjectId: ", initialProjectId, "уээээ: ", projectInfo);
      // console.log("sectionInfo: ", sectionInfo);
      // console.log("chaptersInfo: ", chapterInfo);
      try {
        const chapter = await getChapter(id, accessToken)
        // console.log(chapter);
        setProjectInfo({
          id: id,
          name: chapter.project.name,
          data: chapter
        })
        // setProjectInfo(p => ({...p, name: project.project.name}))

        setSectionsList(chapter.sections)
      } catch (error) {
        console.error("Ошибка при получении проектов:", error)
        // setProjectInfo({})
      }
    },
    [accessToken, chapterInfo.id]
  )

  const defineQualifier = (type, id) => {
    // console.log("эх эх эх: ", type, id, projectInfo);
    if (type === TYPES.object) {
      qualifier.set(TYPES.object)
      qualifierId.set(id)
      fetchProject(id)
    } else if (type === TYPES.chapter) {
      qualifier.set(TYPES.chapter)
      qualifierId.set(id)
      fetchChapter(id)
    } else if (type === TYPES.section) {
      qualifier.set(TYPES.section)
      qualifierId.set(id)
    }
    // console.log('qualifierId: ', qualifierId, 'qualifier: ', qualifier)
    // console.log("projectInfo: ", projectInfo);
  }

  const convertToNumber = (number) => {
    if (!isNaN(Number(number))) {
      return Number(number)
    } else {
      return number
    }
  }

  const calculateMonthDifference = (startDate, endDate) => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const yearsDifference = end.getFullYear() - start.getFullYear()
    const monthsDifference = end.getMonth() - start.getMonth()

    let totalMonths = yearsDifference * 12 + monthsDifference

    if (end.getDate() < start.getDate()) {
      totalMonths -= 1 // Уменьшаем на один месяц, если "конец" раньше "начала" в пределах месяца
    }
    if (totalMonths < 1) {
      totalMonths = 1
    }
    // console.log(totalMonths)
    return totalMonths
  }

  const formattingAndErrorsChecking = () => {
    checkIfEmpty({
      name: name.value,
      start: start.value,
      end: end.value,
      volumeMetrics: volumeMetrics.value,
      estimatedVolume: estimatedVolume.value
    })
    // console.log(errors);
  }

  const onSubmit = () => {
    setIsSubmitting(true)
    formattingAndErrorsChecking()
  }

  const onChangeFieldValue = (value, setter, field = null) => {
    setter(value)
    if (field !== null && field !== undefined) {
      checkIfEmpty(value, field)
    }
  }

  useEffect(() => {
    handleEscape(onClose)
  }, [onClose])

  useEffect(() => {
    fetchProjects()
  }, [fetchProjects])

  useEffect(() => {
    fetchChapter()
  }, [fetchChapter])

  useEffect(() => {
    if (initialProjectId) {
      fetchProject()
    }
  }, [fetchProject, initialProjectId])

  useEffect(() => {
    console.log("ну давай смотреть: ", prevProjectId.current, projectInfo.id)
    if (prevProjectId.current !== projectInfo.id) {
      prevProjectId.current = projectInfo.id
      defineQualifier(TYPES.object, projectInfo.id)
    }
    if (prevChapterId.current !== chapterInfo.id) {
      prevChapterId.current = chapterInfo.id
      defineQualifier(TYPES.chapter, chapterInfo.id)
    }
    if (prevSectionId.current !== sectionInfo.id) {
      prevSectionId.current = sectionInfo.id
      defineQualifier(TYPES.section, sectionInfo.id)
    }
  }, [projectInfo, chapterInfo, sectionInfo])

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length > 0) {
        setIsSubmitting(false)
      } else {
        // setIsSubmitting(true);
        const startDateFormatted = formatToLocalDateTime(start.value)
        const endDateFormatted = formatToLocalDateTime(end.value)
        const estimatedVolumeFormatted = convertToNumber(estimatedVolume.value)
        const metricCostFormatted = convertToNumber(metricCost.value)
        const totalCostFormatted = convertToNumber(totalCost.value)
        const monthlyVolume =
          totalCostFormatted / calculateMonthDifference(start.value, end.value)

        postTask({
          accessToken: accessToken,
          start: startDateFormatted,
          end: endDateFormatted,
          qualifier: qualifier,
          qualifierId: qualifierId,
          header: name.value,
          work_number: workNumber.value,
          description: description.value,
          estimated_volume: estimatedVolumeFormatted,
          monthly_volume: monthlyVolume,
          volume_metrics: volumeMetrics.value,
          metric_cost: metricCostFormatted,
          total_cost: totalCostFormatted,
          equipments: equipments.value,
          performers: performers.value,
          evaluators: evaluators.value,
          additional_info: { additionalProp1: extraInfo.value }
          // facility: facility.value,
          // type: type.value,
          // planInNumericalTerms: planInNumericalTerms.value,
          // planInMonetaryTerms: planInMonetaryTerms.value,
        })
      }
    }
  }, [errors])

  // console.log(projectInfo);
  return (
    <div className="new-task-form modal-container">
      {/* <div className="modal-container__wrapper"> */}
      <form
        className="new-task-form-form modal-container__wrapper"
        method="post"
      >
        <CloseBtn
          style={{ position: "absolute", top: "2rem", right: "2rem" }}
          blackOrWhite="white"
          onClick={onClose}
        />

        {/* <div className='new-task-form-field'>
        <label className='new-task-form-field__label'>Направление или участок</label>
        <DistrictsList
          accessToken={accessToken}
          district={district.value}
          setDistrict={district.set}
        />
      </div> */}

        <div className="new-task-form-field">
          <label htmlFor="name" className="new-task-form-field__label">
            Наименование задачи
          </label>
          <input
            className="new-task-form-field__input"
            id="name"
            placeholder="Введите наименование задачи"
            value={name.value}
            onChange={(e) =>
              onChangeFieldValue(e.target.value, name.set, "name")
            }
          />
          {errors.name && errors.name.state && (
            <span className="form__error-message">{errors.name.text}</span>
          )}
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Глава</label>
          <DropdownList
            style={{ zIndex: "400" }}
            heading={
              sectionInfo.name.length > 0
                ? `Глава: ${sectionInfo.name}`
                : "Главы"
            }
            content={chaptersList}
            contentName="name"
            idName="id"
            selectedItem={chapterInfo}
            setSelectedItem={setChapterInfo}
            // action={() => defineQualifier(TYPES.chapter)}
            ifContentEpmtyMessage="Список глав пуст"
            // isDropdownAllowed={receivedQalifier === "CHAPTER" ? false : true}
            // heading="Глава"
            // content={chaptersList}
            // contentName="name"
            // selectedItem={chapterName}
            // setSelectedItem={setChapterName}
            // ifContentEpmtyMessage="Список глав пуст"
          />
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">
            Период выполнения
          </label>

          <div className="new-task-form-row-container new-project-form-field_date-wrapper">
            <input
              className="new-task-form-field__input"
              type="date"
              id="date"
              placeholder="Дата начала"
              value={start.value}
              onChange={(e) =>
                onChangeFieldValue(e.target.value, start.set, "start")
              }
            />
            <input
              className="new-task-form-field__input"
              type="date"
              id="date"
              placeholder="Дата окончания"
              value={end.value}
              onChange={(e) =>
                onChangeFieldValue(e.target.value, end.set, "end")
              }
            />
          </div>

          <div className="new-task-form-row-container">
            <span className="form__error-message">
              {errors.start && errors.start.state && <>{errors.start.text}</>}
            </span>
            <span className="form__error-message">
              {errors.end && errors.end.state && <>{errors.end.text}</>}
            </span>
          </div>
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Раздел</label>
          <DropdownList
            // heading={
            //   receivedQalifier === "SECTION"
            //     ? `Раздел: ${receivedQualifierName}`
            //     : "Разделы"
            // }
            // content={receivedQalifier !== "SECTION" ? sectionsList : ""}
            // contentName="name"
            // idName="id"
            // selectedItem={sectionInfo}
            // setSelectedItem={setSectionInfo}
            // ifContentEpmtyMessage={
            //   receivedQalifier === "SECTION" ? "" : "Список разделов пуст"
            // }
            // isDropdownAllowed={receivedQalifier === "SECTION" ? false : true}
            style={{ zIndex: "350" }}
            heading={
              sectionInfo.name.length > 0
                ? `Раздел: ${sectionInfo.name}`
                : "Разделы"
            }
            content={sectionsList}
            contentName="name"
            selectedItem={sectionInfo}
            setSelectedItem={setSectionInfo}
            // action={() => defineQualifier(TYPES.section)}
            ifContentEpmtyMessage="Список разделов пуст"
          />
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Исполнители</label>
          <AddStaffList_New
            style={{ zIndex: "300" }}
            arePerformers={true}
            selectedStaffArray={performers.value}
            setSelectedStaffArray={performers.set}
          />
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Объём задачи</label>
          <div className="new-task-form-row-container">
            <input
              className="new-task-form-field__input"
              id="volume"
              placeholder="Числовое значение"
              value={estimatedVolume.value}
              onChange={(e) =>
                onChangeFieldValue(
                  e.target.value,
                  estimatedVolume.set,
                  "estimatedVolume"
                )
              }
            />
            <input
              className="new-task-form-field__input"
              id="type"
              placeholder="Единицы измерения"
              value={volumeMetrics.value}
              onChange={(e) =>
                onChangeFieldValue(
                  e.target.value,
                  volumeMetrics.set,
                  "volumeMetrics"
                )
              }
            />
          </div>

          <div className="new-task-form-row-container">
            <span className="form__error-message">
              {errors.estimatedVolume && errors.estimatedVolume.state && (
                <>{errors.estimatedVolume.text}</>
              )}
            </span>

            <span className="form__error-message ">
              {errors.volumeMetrics && errors.volumeMetrics.state && (
                <>{errors.volumeMetrics.text}</>
              )}
            </span>
          </div>
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Проверяющие лица</label>
          <AddStaffList_New
            accessToken={accessToken}
            style={{ zIndex: "250" }}
            areEvaluators={true}
            selectedStaffArray={evaluators.value}
            setSelectedStaffArray={evaluators.set}
          />
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Стоимость задачи</label>
          <div className="new-task-form-row-container">
            <input
              className="new-task-form-field__input"
              id="metricCost"
              placeholder="Стоимость единицы"
              value={metricCost.value}
              onChange={(e) =>
                onChangeFieldValue(e.target.value, metricCost.set)
              }
            />
            <input
              className="new-task-form-field__input"
              id="totalCost"
              placeholder="Стоимость всего"
              value={totalCost.value}
              onChange={(e) =>
                onChangeFieldValue(e.target.value, totalCost.set)
              }
            />
          </div>
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Объект</label>
          <DropdownList
            style={{ zIndex: "200" }}
            heading={
              projectInfo.name.length > 0
                ? `Объект: ${projectInfo.name}`
                : "Объекты:"
            }
            content={initialProjectId.length > 0 ? "" : projectsList}
            contentName="name"
            idName="id"
            selectedItem={projectInfo}
            setSelectedItem={setProjectInfo}
            // action={() => defineQualifier(TYPES.object, projectInfo.id)}
            ifContentEpmtyMessage="Список объектов пуст"
            isDropdownAllowed={initialProjectId.length > 0 ? false : true}
          />
        </div>

        {/* <div className="new-task-form-field">
          <label className="new-task-form-field__label">
            План в числ. и денеж. выражении
          </label>
          <div className="new-task-form-row-container">
            <input
              className="new-task-form-field__input"
              id="planInNumericalTerms"
              placeholder="числовое значение"
              value={planInNumericalTerms.value}
              onChange={(e) =>
                onChangeFieldValue(
                  e.target.value,
                  planInNumericalTerms.value,
                  planInNumericalTerms.set
                )
              }
            />
            <input
              className="new-task-form-field__input"
              id="planInMonetaryTerms"
              placeholder="Денежное значение"
              value={planInMonetaryTerms.value}
              onChange={(e) =>
                onChangeFieldValue(
                  e.target.value,
                  planInMonetaryTerms.value,
                  planInMonetaryTerms.set
                )
              }
            />
          </div>
        </div> */}

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">
            Техническое обеспечение
          </label>
          <EquipmentList_New
            style={{ zIndex: "150" }}
            selectedEquipArray={equipments.value}
            setSelectedEquipArray={equipments.set}
          />
          {/* <DropdownList style={{zIndex: "150"}} heading='Оборудование' content={equipmentsList} contentName="name" selectedItem={projectInfo} setSelectedItem={setProjectInfo} ifContentEpmtyMessage={qualifier === "PROJECT" ? "" : 'Список проектов пуст'} isDropdownAllowed={qualifier === "PROJECT" ? false : true} /> */}
          {/* <EquipmentsList 
          style={{zIndex: "150"}}
          equipments={equipments.value}
          setEquipment={equipments.set}
        /> */}
        </div>

        <div className="new-task-form-field new-task-form-extra-info">
          <label className="new-task-form-field__label">
            Дополнительная информация
          </label>
          <textarea
            className="new-task-form-field__textarea new-task-form-extra-info__textarea"
            value={extraInfo.value}
            onChange={(e) => onChangeFieldValue(e.target.value, extraInfo.set)}
            placeholder="Введите доп. информацию, если потребуется"
          ></textarea>
        </div>

        <div className="new-task-form-field new-task-form-description">
          <label className="new-task-form-field__label">Описание задачи</label>
          <textarea
            className="new-task-form-field__textarea new-task-form-description__textarea"
            value={description.value}
            onChange={(e) =>
              onChangeFieldValue(e.target.value, description.set)
            }
            placeholder="Опишите задачу"
          ></textarea>
        </div>

        <div className="new-task-form-field">
          <label htmlFor="facility" className="new-task-form-field__label">
            Номер работ из реестра
          </label>
          <input
            className="new-task-form-field__input"
            id="work-number"
            placeholder="Введите номер работ из реестра"
            value={workNumber.value}
            onChange={(e) =>
              onChangeFieldValue(e.target.value, workNumber.set, "workNumber")
            }
          />
          {errors.workNumber && errors.workNumber.state && (
            <span className="form__error-message">
              {errors.workNumber.text}
            </span>
          )}
        </div>

        <div className="new-task-form-row-container new-task-form-action-buttons">
          {/* <div className='input-field-wrapper'> */}
          <button
            className="new-task-form-field__button new-task-form-submit-button"
            type="button"
            onClick={(e) => {
              onSubmit()
              e.preventDefault()
            }}
          >
            + Новая задача
          </button>
          <button className="new-task-form-field__button new-task-form-button new-task-form-open-tasks-button" />
        </div>
      </form>
    </div>
  )
}
