// import { elements } from "chart.js";
import { useState, useEffect } from "react"

export const SearchField = (props) => {
  const { initialArray, setInitialArray = null } = props

  const [searchInput, setSearchInput] = useState("")
  // const [filter, setFilter] = useState("")

  const filterArray = (searchInput, initialArray) => {
    if (!searchInput) {
      return initialArray
    }
    return initialArray.filter((element) => {
      const stringElement = element.toString().toLowerCase()
      const stringSearchInput = searchInput.toString().toLowerCase()
      return stringElement.includes(stringSearchInput)
    })
  }

  useEffect(() => {
    const Debounce = setTimeout(() => {
      if (setInitialArray) {
        const filteredArray = filterArray(searchInput, initialArray)
        setInitialArray(filteredArray)
      }
    }, 300)
    return () => clearTimeout(Debounce)
  }, [searchInput, setInitialArray, initialArray])

  return (
    <div className="search-field">
      <div className="search-field__colored-wrapper">
        <input
          type="text"
          className="search-field__input"
          onChange={(e) => setSearchInput(e.target.value)}
          value={searchInput}
        />
      </div>
    </div>
  )
}
